import { Container, Typography } from '@material-ui/core'
import React from 'react'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import TitleBar from '../../../components/Titlebar'
import Navigation from '../../../components/layout/navigation/Navigation'
import ClassForm from '../components/forms/classForm'
import { useDocumentData, useCollectionData  } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import ClassDetailSkeleton from '../components/skeletonLoaders/ClassDetailSkeleton'
import moment from 'moment'

function CreateClass({ history }) {
  const transformTaxonomy = (data) => {
    const class_equipment = []
    const topics = []
    const trainers = []

    if (data.class_equipment) {
      Object.keys(data.class_equipment).forEach((key, index) => {
        class_equipment.push({
          label: key.split('_').join(' '),
        })
      })
    }

    if (data.trainers) {
        Object.keys(data.trainers).forEach((key, index) => {
            trainers.push({
            label: key.split('_').join(' '),
          })
        })
      }

    if (data.topics) {
      Object.keys(data.topics).forEach((key, index) => {
        topics.push({
          label: key.split('_').join(' '),
        })
      })
    }
    return { ...data, originalCategories: data, class_equipment: class_equipment, topics: topics, trainers: trainers }
  }
  const [taxonomy, taxonomyLoading, taxonomyError] = useDocumentData(
    db.collection('taxonomy').doc('video'),
    {
      transform: transformTaxonomy,
    }
  )

   // Get plans from firebase
   const transformPlans = (data) => {
    let label
    if (!data.start_date) {
      label = data.title
    } else {
      label = data.title + ' ' + moment(data.start_date.toDate()).format('MMMM YYYY')
    }
    return { title: data.title.toLowerCase(), label: label.toLowerCase(), firebase_id: data.firebase_id }
  }
  const [plans, plansLoading, plansError] = useCollectionData(db.collection('plans').orderBy('title', 'asc'), {
    idField: 'firebase_id',
    transform: transformPlans,
  })

  return (
    <Navigation>
      <TitleBar
        icon={<DirectionsRunIcon fontSize="large" />}
        title="Class"
        caption="Manage your class, you can add edit delete, don’t forget to add a topic…"
        onClick={() => history.push('/class/create-class/')}
        buttonTitle="Add New Class"
      />
      <Container maxWidth="xl">
        {taxonomyError || plansError ? <Typography variant="h1">No Firebase Connection </Typography> : null}
        {taxonomyLoading || plansLoading ? <ClassDetailSkeleton /> : taxonomy && plans ? (<ClassForm taxonomy={taxonomy} plansOptions={plans} />) : null }
      </Container>
    </Navigation>
  )
}

export default CreateClass
