import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import bgImage from '../../assets/images/ShredBG.jpg'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Copyright from '../../components/Copyright'
import { firebaseResetPasswordEmail } from '../../api/FirebaseService'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    backgroundColor: '#000',
    opacity: '0.65',
  },
  formContainer: {
    maxWidth: '90%',
    zIndex: '99',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ResetPassword() {
  const classes = useStyles()

  const Schema = yup.object().shape({
    email: yup.string().email('Invalid email format.').required(),
  })

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(Schema),
  })

  const handleForgotPassword = async (value, e) => {
    try {
      await firebaseResetPasswordEmail(value, e)
      toast.success('Password reset email sent, Please check your inbox.')
      e.target.reset()
    } catch (error) {
      toast.error('Invalid email address, check your email and try again.')
    }
  }

  return (
    <Box component="main" className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
        sm={12}
        md={12}
        className={classes.image}
      >
        <div className={classes.overlay} />
        <Paper elevation={6} className={classes.formContainer}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(handleForgotPassword)}>
              <TextField
                inputRef={register}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={errors.email ? true : false}
                helperText={errors.email ? errors.email.message : null}
              />
              <Button variant="contained" type="submit" fullWidth className={classes.submit}>
                Reset Password
              </Button>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Paper>
      </Grid>
    </Box>
  )
}
