import { Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 20,
    flex: '1 1 auto',
    overflowX: 'hidden',
    border: `1px solid ${theme.palette.borders.main}`,
    marginBottom: theme.spacing(7),
  },
  contents: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(5),
  },
  title: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

function StyledGrid({ children, title }) {
  const classes = useStyles()

  return (
    <Grid component={Paper} className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <Divider style={{marginBottom: '8px'}} />
      <Grid className={classes.contents}>{children}</Grid>
    </Grid>
  )
}

export default StyledGrid
