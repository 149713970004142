import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { useDispatch } from 'react-redux'
import { closeAlert } from './AlertReducer'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogWrapper({ children, fullScreen, bgColor }) {
  const dispatch = useDispatch()

  return (
    <div>
      <Dialog
        open={true}
        PaperProps={{
            style: {
              backgroundColor: bgColor,
            },
          }}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => dispatch(closeAlert())}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {children}
      </Dialog>
    </div>
  )
}
