import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiInput } from '../../../../components/form/MuiInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AlertDialogWrapper from '../../../../components/alert/AlertDialogWrapper'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { closeAlert } from '../../../../components/alert/AlertReducer'
import { updateTaxonomyInFirestore } from '../../../../api/FirestoreServices'
import { toast } from 'react-toastify'

export const Schema = yup.object().shape({
  name: yup.string().min(3).required(),
})

function WorkoutTagsAlert(props) {
  const dispatch = useDispatch()
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  async function onSubmit(newCategory) {

    const currentTags = props.workoutTagData.taxonomy.originalTaxonomies.workout_tags
    const newCat = {}
    newCat[newCategory.name.split(' ').join('_')] = true

    const merged = { ...currentTags, ...newCat }

    try {
      await updateTaxonomyInFirestore('workout',{ workout_tags: merged }).then((docRef) => {
        dispatch(closeAlert())
      })
      toast.success("Congratulations! you've added a new tag.")
    } catch (error) {
      toast.error("Sorry we're unable to add that tag, please try again.")
    }
  }

  return (
    <AlertDialogWrapper>
      <DialogTitle id="alert-dialog-slide-title">{props.workoutTagData.title}</DialogTitle>
      <MuiForm onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{props.workoutTagData.description}</DialogContentText>
          <MuiInput
            ref={register}
            id="name"
            label="Tag Title"
            name="name"
            error={!!errors.label}
            helperText={errors?.label?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => dispatch(closeAlert())}>
            Cancel
          </Button>
          <Button type="submit" variant="text">
            Add Tag
          </Button>
        </DialogActions>
      </MuiForm>
    </AlertDialogWrapper>
  )
}

export default WorkoutTagsAlert
