import * as yup from 'yup'

export const Schema = yup.object().shape({
  //Always Required
  status: yup.string().required('Workout status is a required field'),
  title: yup.string().required('Workout title is a required field'),
  //Always Optional  
  description: yup.string(),
  alternative_workout: yup.array(),
  //Required when plan status is optional
  subtitle: yup.string().when('status', (value) => {
    if (value === 'Published') {
      return yup.string()
        .required('Subtitle title is a required field')
    }
  }),
  duration: yup.string().when('status', (value) => {
    if (value === 'Published') {
      return yup.number().typeError('Workout duration is required').required('A workout duration is required')
    }
  }),
  laps: yup.string().when('status', (value) => {
    if (value === 'Published') {
      return yup.number().typeError('Workout lap is required').required('Number of laps is required')
    }
  }),
  vimeo_video_id: yup.string(),
  workout_type: yup.string().when('status', (value) => {
    if (value === 'Published') {
      return yup.string().typeError('Workout type is required').required()
    }
  }),
  workout_method: yup.array().when('status', (value) => {
    if (value === 'Published') {
      return yup.array().min(1, 'Select at least 1 method').required()
    }
  }),
  plans: yup.array().when('status', (value) => {
    if (value === 'Published') {
      return yup.array().min(1, "Select at least 1 plan").required()
    }
  }),
})
