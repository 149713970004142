import React from 'react'
import { Grid, Hidden, IconButton, makeStyles, TextField, Typography } from '@material-ui/core'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

const useStyles = makeStyles((theme) => ({
pageDetail: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]:{
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    }
  },
  searchIcon: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
    },
  },
  NumberField: {
    width: '150px',
    marginLeft: '20px',
    marginRight: '15px',
    '& label.Mui-focused': {
      //paddingTop: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        //border: 'none',
      },
    },
  },
  ButtonIcon: {
    color: '#000',
    padding: '9px',
    overflow: 'visible',
    fontSize: '1.4rem',
    backgroundColor: '#ffffff',
    margin: '5px',
    borderRadius: 0,
    border: 'solid 1px',
    '&:hover': {
      backgroundColor: '#89c400',
      color: 'white',
      border: '1px solid #89c400',
    },
  }
}))

export const TablePagination = ({
  previous,
  previousDisabled,
  first,
  last,
  next,
  nextDisabled,
  pageNumber,
  onChange,
  totalPages,
  currentPage,
}) => {
  const classes = useStyles()
  return (
    <Grid style={{ marginTop: '15px' }} container justify="center" alignItems="center">
      <Grid item>
        <IconButton className={classes.ButtonIcon}aria-label="first" size="medium" onClick={first} disabled={previousDisabled}>
          <SkipPreviousIcon fontSize="inherit" />
        </IconButton>
        <IconButton className={classes.ButtonIcon} aria-label="last" size="medium" onClick={previous} disabled={previousDisabled}>
          <NavigateBeforeIcon fontSize="inherit" />
        </IconButton>
      </Grid>
        <Typography className={classes.pageDetail} variant="body2">
          Page {currentPage} of {totalPages}
        </Typography>
        <Hidden smDown>
          <TextField
            className={classes.NumberField}
            label="go to page"
            variant="outlined"
            id="standard-number"
            type="number"
            value={pageNumber || ''}
            onChange={onChange}
          />
        </Hidden>
      
      <Grid item>
        <IconButton className={classes.ButtonIcon} aria-label="first" size="medium" onClick={next} disabled={nextDisabled}>
          <NavigateNextIcon fontSize="inherit" />
        </IconButton>
        <IconButton className={classes.ButtonIcon} aria-label="first" size="medium" onClick={last} disabled={nextDisabled}>
          <SkipNextIcon fontSize="inherit" />
        </IconButton>
      </Grid>
    </Grid>
  )
}
