import { Container, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Navigation from '../../../components/layout/navigation/Navigation'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import { findSelected } from '../../../util/util'
import ExerciseDetailSkeleton from '../components/skeletonLoaders/ExerciseDetailSkeleton'
import ExerciseForm from '../components/forms/ExerciseForm'

function ExerciseDetail({ match, history }) {
  const [isLoading, setIsLoading] = useState(true)

  const transformTaxonomy = (data) => {
    const muscleGroups = []
    const equipment = []
    if (data.muscle_groups) {
      Object.keys(data.muscle_groups).forEach((key, index) => {
        muscleGroups.push({
          label: key.split('_').join(' '),
        })
      })
    }

    if (data.equipment) {
      Object.keys(data.equipment).forEach((key, index) => {
        equipment.push({
          label: key.split('_').join(' '),
        })
      })
    }
    return { ...data, originalCategories: data, muscle_groups: muscleGroups, equipment: equipment }
  }
  const [taxonomy, taxonomyLoading, taxonomyError] = useDocumentData(db.collection('taxonomy').doc('exercise'), {
    transform: transformTaxonomy,
  })

  // Format meal data
  const transformExercise = (data) => {
    const alternative_exercise = []

    if (data?.alternative_exercise) {
      Object.keys(data?.alternative_exercise).forEach((key, index) => {
        alternative_exercise.push({
          label: key,
          ...data?.alternative_exercise,
        })
      })
    }

    return {
      ...data,
      alternative_exercise: alternative_exercise,
    }
  }

  //Get the meal from firebase
  const [exercise, exerciseLoading, exerciseError] = useDocumentData(db.collection('exercises').doc(match.params.id), {
    transform: transformExercise,
    idField: 'firebase_id',
  })

  const transformExercises = (data) => {
    return { ...data, label: data.title.toLowerCase() }
  }
  const [exercises, exercisesLoading, exercisesError] = useCollectionData(
    db.collection('exercises').orderBy('title', 'asc'),
    {
      idField: 'firebase_id',
      transform: transformExercises,
    }
  )

  useEffect(() => {
    if (!exerciseLoading && !exercisesLoading && !taxonomyLoading) {
      setIsLoading(false)
    }
  }, [taxonomyLoading, exerciseLoading, exercisesLoading])

  useEffect(() => {
    if (!exerciseLoading && exercise === undefined) {
      history.push('/404')
    }
  }, [exercise, exerciseLoading, history])

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Exercise"
        caption="Manage your Exercise, you can edit delete the exercise, don’t forget to add equipment and muscle groups…"
        buttonTitle="Add New Exercise"
        onClick={() => history.push('/exercises/create-exercise')}
      />
      <Container maxWidth="xl">
        {taxonomyError || exerciseError || exercisesError ? (
          <Typography variant="h1">No Firebase Connection </Typography>
        ) : null}
        {isLoading && <ExerciseDetailSkeleton />}
        {!isLoading && exercise && taxonomy && exercises && (
          <ExerciseForm
            data={exercise}
            taxonomy={taxonomy}
            alternativeExerciseOptions={exercises.filter((workOut) => workOut.firebase_id !== exercise.firebase_id)}
            alternativeExerciseSelected={
              exercises && exercise.alternative_exercise_id
                ? findSelected(exercises, exercise.alternative_exercise_id, 'title')
                : []
            }
          />
        )}
      </Container>
    </Navigation>
  )
}

export default ExerciseDetail
