import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import React from 'react'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiInput } from '../../../../components/form/MuiInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AlertDialogWrapper from '../../../../components/alert/AlertDialogWrapper'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { closeAlert } from '../../../../components/alert/AlertReducer'
import { MuiAutoComplete } from '../../../../components/form/MuiAutoComplete'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db } from '../../../../api/firebase'
import update from 'immutability-helper'

export const Schema = yup.object().shape({
  ingredient: yup.object().required('Choose an ingredient.'),
  min_amount: yup.number().typeError('Enter a min value.').required(),
  max_amount: yup.number().min(yup.ref("min_amount"),'Max must be greater than or equal to Min.').typeError('Enter a max value.').required(),
})

function MainIngredientAlert({ addIngredientData, upDateIngredient, row }) {
  const dispatch = useDispatch()

  const [values, loading, error] = useCollectionData(db.collection('ingredients').orderBy('title', 'asc'), {
    idField: 'firebase_id',
  })

  const initialValues = {
    ingredient: row ? { ...row?.original, label: row?.original?.title + ' - ' + row?.original?.unit } : null,
    min_amount: row?.original?.min_amount,
    max_amount: row?.original?.max_amount,
  }

  const { handleSubmit, errors, register, reset, control } = useForm({
    resolver: yupResolver(Schema),
    mode: 'submit',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  const onSubmit = (newIngredient) => {

    // we have an ingredient we need to man handle this is what we do to build the ingredient data
    const min_amount = +newIngredient.min_amount / +newIngredient.ingredient.base_amount
    const max_amount = +newIngredient.max_amount / +newIngredient.ingredient.base_amount 

    // build the data for the minimum thresholds
    let min_proteins = Number(newIngredient.ingredient.proteins) * min_amount * 4
    let min_carbs = Number(newIngredient.ingredient.carbs) * min_amount * 4
    let min_fats = Number(newIngredient.ingredient.fats) * min_amount * 9
    newIngredient.ingredient.min_calories = min_proteins + min_carbs + min_fats
    newIngredient.ingredient.min_proteins = min_proteins
    newIngredient.ingredient.min_carbs = min_carbs
    newIngredient.ingredient.min_fats = min_fats

    // build the data for the maximum thresholds
    let max_proteins = Number(newIngredient.ingredient.proteins) * max_amount * 4
    let max_carbs = Number(newIngredient.ingredient.carbs) * max_amount * 4
    let max_fats = Number(newIngredient.ingredient.fats) * max_amount * 9
    newIngredient.ingredient.max_calories = max_proteins + max_carbs + max_fats
    newIngredient.ingredient.max_proteins = max_proteins
    newIngredient.ingredient.max_carbs = max_carbs
    newIngredient.ingredient.max_fats = max_fats
    // end ingredient details calculation

    newIngredient.ingredient.min_amount = newIngredient.min_amount
    newIngredient.ingredient.max_amount = newIngredient.max_amount
    delete newIngredient.min_amount
    delete newIngredient.max_amount

    if (upDateIngredient) {
      upDateIngredient.setIngredients(
        update(upDateIngredient.ingredients, {
          [row.index]: {
            $set: {
              ...newIngredient.ingredient
            },
          },
        })
      )
      dispatch(closeAlert())
    } else {
      const newArray = [...addIngredientData.ingredients]
      newArray.push({
        ...newIngredient.ingredient,
        order: newArray.length + 1,
        min_amount: newIngredient.ingredient.min_amount,
        max_amount: newIngredient.ingredient.max_amount,
        id: newIngredient.ingredient.firebase_id
      })
      addIngredientData.setIngredients(newArray)
      reset()
      dispatch(closeAlert())
    }
  }
  const newOptions = []

  if (!loading) {
    values.map((ing) => newOptions.push({ ...ing, label: ing.title + ' - ' + ing.unit }))
  }

  return (
    <AlertDialogWrapper>
      <DialogTitle id="alert-dialog-slide-title">
        {upDateIngredient ? upDateIngredient?.title : addIngredientData?.title}
      </DialogTitle>
      <MuiForm onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {error
              ? 'Unable to connect to firebase'
              : upDateIngredient
              ? upDateIngredient?.title
              : addIngredientData?.description}
          </DialogContentText>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <MuiAutoComplete
                multiple={false}
                id="ingredient"
                label="Ingredient Title"
                inputLabel="Ingredient"
                name="ingredient"
                error={!!errors.ingredient}
                helperText={errors?.ingredient?.message}
                options={newOptions}
                control={control}
                disabled={upDateIngredient ? true : false}
              />
            </Grid>
            <Grid item xs={3}>
              <MuiInput
                ref={register}
                id="min_amount"
                type="number"
                label="Min"
                name="min_amount"
                error={!!errors.min_amount}
                helperText={errors?.min_amount?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <MuiInput
                ref={register}
                type="number"
                id="max_amount"
                label="Max"
                name="max_amount"
                error={!!errors.max_amount}
                helperText={errors?.max_amount?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => dispatch(closeAlert())}>
            Cancel
          </Button>
          <Button type="submit" variant="text">
            {upDateIngredient ? 'Update Ingredient' : 'Add Ingredient'}
          </Button>
        </DialogActions>
      </MuiForm>
    </AlertDialogWrapper>
  )
}

export default MainIngredientAlert
