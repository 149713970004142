import { FormHelperText, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import Status from '../../../../components/status/Status'
import StyledGrid from '../../../../components/layout/Grid'
import { MuiInput } from '../../../../components/form/MuiInput'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiSelect } from '../../../../components/form/MuiSelect'
import { addPlanToFirestore, deletePlanInFireStore, updatePlanInFirestore } from '../../../../api/FirestoreServices'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import firebase from 'firebase/app'
import { useHistory } from 'react-router'
import { closeAlert, openAlert } from '../../../../components/alert/AlertReducer'
import ImageUploadWidget from '../../../../components/Image/ImageUploadWidget'
import { MuiAutoComplete } from '../../../../components/form/MuiAutoComplete'
import Category from '../../../../components/category/category'
import { Schema } from '../../schema/planSchema'
import VimeoVideo from '../../../../components/VimeoVideo'
import Weeks from '../repeaters/weeks'
import { MuiDatePicker } from '../../../../components/form/MuiDatePicker'
import { removeEmptyFields } from '../../../../util/util'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  statusComponent: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
  formComponent: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
}))

function PlanForm({ data, weekData }) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [files, setFiles] = useState([])
  const [image, setImage] = useState(data?.image)
  const [mealFiles, setMealFiles] = useState([])
  const [mealImage, setMealImage] = useState(data?.meal_image)
  const [weeks, setWeeks] = useState(data?.weeks || [])

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const history = useHistory()

  const initialValues = useMemo(() => {
    return {
      title: data?.title || '',
      subtitle: data?.subtitle || '',
      goal: data?.goal || '',
      plan_type: data?.plan_type || '',
      long: data?.description?.long || '',
      short: data?.description?.short || '',
      training_level: data?.training_level || '',
      gender: data?.gender || [],
      vimeo_video_id: data?.vimeo_video_id || '',
      status: data?.status || '',
      plan_id: data?.plan_id || null,
      start_date: data?.start_date ? data?.start_date.toDate() : new Date(),
      end_date: data?.end_date ? data?.end_date.toDate() : new Date(),
      breakfast_percent: data?.meal_Percentages?.breakfast_percent || '',
      mid_morning_snack_percent: data?.meal_Percentages?.mid_morning_snack_percent || '',
      lunch_percent: data?.meal_Percentages?.lunch_percent || '',
      afternoon_snack_percent: data?.meal_Percentages?.afternoon_snack_percent || '',
      dinner_percent: data?.meal_Percentages?.dinner_percent || '',
      before_bed_percent: data?.meal_Percentages?.before_bed_percent || '',
    }
  }, [data])

  const additionalFormData = {
    publish_date: firebase.firestore.Timestamp.now(),
    image: image ? image : undefined,
    meal_image: mealImage ? mealImage : undefined,
    weeks: weeks,
  }

  const firebase_id = data?.firebase_id

  const methods = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  async function deletePlanFromFirebase() {
    try {
      await deletePlanInFireStore(data?.firebase_id)
      dispatch(closeAlert())
      toast.success('Plan successfully deleted.')
      history.push(`/plans`)
    } catch (error) {
      toast.error("Sorry we're unable to delete the plan, please try again.")
      dispatch(closeAlert())
    }
  }

  const deleteData = {
    title: 'Are you sure?',
    description:
      'Are you sure you want to delete this plan? this is a destructive action and once delete this plan cannot be recovered.',
    onClick: () => {
      deletePlanFromFirebase()
    },
  }

  async function submit(plan) {
   

    const formattedData = {
      gender: plan.gender.reduce((obj, cur) => ({ ...obj, [cur.label]: true }), {}),
      description: plan.long || plan.long ? { long: plan.long, short: plan.short } : undefined,
      meal_Percentages: {
        breakfast_percent: plan.breakfast_percent,
        mid_morning_snack_percent: plan.mid_morning_snack_percent,
        lunch_percent: plan.lunch_percent,
        afternoon_snack_percent: plan.afternoon_snack_percent,
        dinner_percent: plan.dinner_percent,
        before_bed_percent: plan.before_bed_percent,
      },
    }

    delete plan.long
    delete plan.short
    delete plan.breakfast_percent
    delete plan.mid_morning_snack_percent
    delete plan.lunch_percent
    delete plan.afternoon_snack_percent
    delete plan.dinner_percent
    delete plan.before_bed_percent

    const merged = { ...data, ...plan, ...additionalFormData, ...formattedData }
   
    removeEmptyFields(merged)

    if (data) {
      try {
        await updatePlanInFirestore(firebase_id, merged)
        toast.success("Congratulations! we've successfully updated your plan.")
      } catch (error) {
        console.log(error)
        toast.error("Sorry we're unable to save your plan, please try again.")
      }
    } else {
      try {
        await addPlanToFirestore(merged).then((docRef) => {
          history.push(`/plans/${docRef.id}`)
        })
        toast.success("Congratulations! you've added a new plan.")
      } catch (error) {
        console.log(error)
        toast.error("Sorry we're unable to save your plan, please try again.")
      }
    }
  }

  const clearImage = () => {
    setImage(null)
    setFiles([])
  }

  const clearMealImage = () => {
    setMealImage(null)
    setMealFiles([])
  }

  const statusOptions = ['Published', 'Pending', 'Draft']
  const trainingLevel = ['All Levels', 'Beginner', 'Intermediate', 'Advanced']
  const planType = ['Workout Plan', 'Nutrition Plan']
  const genderOptions = [{ label: 'Male' }, { label: 'Female' }]

  const watchVideo = methods.watch('vimeo_video_id', data?.vimeo_video_id)

  const watchPlanType = methods.watch('plan_type')

  return (
    <>
      <FormProvider {...methods}>
        <MuiForm className={classes.form} onSubmit={methods.handleSubmit(submit)}>
          <Grid flexdirection="row" container justify="space-between" spacing={isSmall ? 0 : 5}>
            <Grid item sm={12} md={8} xl={9} className={classes.formComponent}>
              <StyledGrid title="Plan Details">
                <Grid container>
                  <Grid item xs={12}>
                    <MuiInput
                      ref={methods.register}
                      id="title"
                      label="Plan Title"
                      name="title"
                      error={!!methods.errors.title}
                      helperText={methods.errors?.title?.message}
                      placeholder="Enter a title eg Ultimate Female..."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiInput
                      ref={methods.register}
                      id="subtitle"
                      label="Plan Subtitle"
                      name="subtitle"
                      error={!!methods.errors.subtitle}
                      helperText={methods.errors?.subtitle?.message}
                      placeholder="Enter a subtitle eg Ultimate Female..."
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MuiSelect
                        space={16}
                        control={methods.control}
                        name="plan_type"
                        error={!!methods.errors.plan_type}
                        options={planType}
                        label="Plan Type"
                      />
                      {methods.errors?.plan_type && (
                        <FormHelperText error>{methods.errors?.plan_type?.message}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <MuiInput
                        ref={methods.register}
                        type="number"
                        id="plan_id"
                        label="WordPress Membership Plan ID"
                        name="plan_id"
                        error={!!methods.errors.plan_id}
                        helperText={methods.errors?.plan_id?.message}
                        placeholder="Enter wordpress membership plan Id..."
                      />
                    </Grid>
                  </Grid>
                  {watchPlanType === 'Workout Plan' ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <MuiInput
                            ref={methods.register}
                            id="goal"
                            label="Goal"
                            name="goal"
                            error={!!methods.errors.goal}
                            helperText={methods.errors?.goal?.message}
                            placeholder="Enter a Goal eg Gain Strength..."
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item sm={12} md={6} xl={6}>
                          <MuiDatePicker
                            id="start_date"
                            name="start_date"
                            inputLabel="Start Date"
                            control={methods.control}
                          />
                        </Grid>
                        <Grid item sm={12} md={6} xl={6}>
                          <MuiDatePicker
                            id="end_date"
                            name="end_date"
                            inputLabel="End Date"
                            control={methods.control}
                          />
                        </Grid>

                        <MuiInput
                          ref={methods.register}
                          name="long"
                          label="Full Description"
                          id="long"
                          multiline
                          rows={5}
                          error={!!methods.errors?.long}
                          helperText={methods.errors?.long?.message}
                        />
                        <MuiInput
                          ref={methods.register}
                          name="short"
                          label="Short Description"
                          id="short"
                          multiline
                          rows={3}
                          error={!!methods.errors?.short}
                          helperText={methods.errors?.short?.message}
                        />
                      </Grid>
                    </>
                  ) : null}
                  {watchPlanType === 'Nutrition Plan' ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <MuiInput
                            ref={methods.register}
                            type="number"
                            id="breakfast_percent"
                            label="Breakfast Calorie Percentage"
                            name="breakfast_percent"
                            error={!!methods.errors?.breakfast_percent || !!methods.errors?.checkPercentages}
                            helperText={methods.errors?.breakfast_percent?.message}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MuiInput
                            ref={methods.register}
                            type="number"
                            id="mid_morning_snack_percent"
                            label="Morning Snack Calorie Percentage"
                            name="mid_morning_snack_percent"
                            error={!!methods.errors?.mid_morning_snack_percent || !!methods.errors?.checkPercentages}
                            helperText={methods.errors?.mid_morning_snack_percent?.message}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <MuiInput
                            ref={methods.register}
                            type="number"
                            id="lunch_percent"
                            label="Lunch Calorie Percentage"
                            name="lunch_percent"
                            error={!!methods.errors?.lunch_percent || !!methods.errors?.checkPercentages}
                            helperText={methods.errors?.lunch_percent?.message}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MuiInput
                            ref={methods.register}
                            type="number"
                            id="afternoon_snack_percent"
                            label="Afternoon Snack Calorie Percentage"
                            name="afternoon_snack_percent"
                            error={!!methods.errors?.afternoon_snack_percent || !!methods.errors?.checkPercentages}
                            helperText={methods.errors?.afternoon_snack_percent?.message}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <MuiInput
                            ref={methods.register}
                            type="number"
                            id="dinner_percent"
                            label="Dinner Calorie Percentage"
                            name="dinner_percent"
                            error={!!methods.errors?.dinner_percent || !!methods.errors?.checkPercentages}
                            helperText={methods.errors?.dinner_percent?.message}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MuiInput
                            ref={methods.register}
                            type="number"
                            id="before_bed_percent"
                            label="Before Bed Calorie Percentage"
                            name="before_bed_percent"
                            error={!!methods.errors?.before_bed_percent || !!methods.errors?.checkPercentages}
                            helperText={methods.errors?.before_bed_percent?.message}
                          />
                        </Grid>
                        {methods.errors.checkPercentages ? (
                          <FormHelperText error>{methods.errors?.checkPercentages.message}</FormHelperText>
                        ) : null}
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </StyledGrid>

              {watchPlanType === 'Workout Plan' ? (
                <Weeks weeks={weekData} setWeeks={setWeeks} firebase_id={data?.firebase_id} wpPlanID={data?.plan_id} />
              ) : null}
            </Grid>
            <Grid item xs={12} md={4} xl={3} className={classes.statusComponent}>
              <Status
                status={data?.status}
                date={data?.publish_date}
                onDelete={() => dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))}
              >
                <MuiSelect
                  control={methods.control}
                  name="status"
                  error={!!methods.errors.status}
                  options={statusOptions}
                  label="Status"
                />
                {methods.errors?.status && <FormHelperText error>{methods.errors?.status?.message}</FormHelperText>}
              </Status>
              <Category title="Training Level">
                <MuiSelect
                  control={methods.control}
                  name="training_level"
                  error={!!methods.errors.training_level}
                  options={trainingLevel}
                  label="Training Level"
                />
                {methods.errors?.training_level && (
                  <FormHelperText error>{methods.errors?.training_level?.message}</FormHelperText>
                )}
              </Category>
              <Category title="Gender">
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Gender"
                  name="gender"
                  options={genderOptions}
                  error={!!methods.errors?.gender}
                  helperText={methods.errors?.gender?.message}
                />
              </Category>
              <Category
                title="Video Details"
                buttonText="Manage videos on Vimeo"
                onClick={() => window.open('https://vimeo.com')}
              >
                <MuiInput
                  ref={methods.register}
                  name="vimeo_video_id"
                  label="Vimeo Video Id"
                  id="vimeo_video_id"
                  placeholder="Enter an ID eg 492658650..."
                  error={!!methods.errors.vimeo_video_id}
                  helperText={methods.errors?.vimeo_video_id?.message}
                />
                {watchVideo && <VimeoVideo videoId={watchVideo} videoTitle={data?.workout_name} />}
              </Category>
              <ImageUploadWidget
                containerTitle="Upload Plan Image"
                setFiles={setFiles}
                files={files}
                image={image}
                clearImage={clearImage}
                setImage={setImage}
              />
              {watchPlanType === 'Nutrition Plan' ? (
                <ImageUploadWidget
                  containerTitle="Upload Meal Image"
                  setFiles={setMealFiles}
                  files={mealFiles}
                  image={mealImage}
                  clearImage={clearMealImage}
                  setImage={setMealImage}
                />
              ) : null}
            </Grid>
          </Grid>
        </MuiForm>
      </FormProvider>
    </>
  )
}

export default PlanForm
