import { createMuiTheme } from '@material-ui/core/styles'

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#5f8900',
      main: '#89C400',
      dark: '#a0cf33',
      contrastText: '#fff',
    },
    secondary: {
      light: '#2a2c2d',
      main: '#3C4041',
      dark: '#636667',
    },
    background: {
      default: '#F5F5F6',
      paper: '#fff',
    },
    borders: {
      main: '#BFBFBF',
    },
    published: {
      main: '#89C400',
    },
    draft: {
      main: '#4191FF',
    },
    pending: {
      main: '#F4772E',
    },
    error: {
      main: '#FB0207',
    },
  },
  typography: {
    fontFamily: `"Poppins", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    body2: {
      fontWeight: '600',
    },
    caption: {
      fontSize: '1rem',
    },
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.3rem',
      fontWeight: '600',
    },
    h3: {
      fontSize: '1.1rem',
    },
    h4: {
      fontSize: '1 rem',
    },
    h5: {
      fontSize: '0.9rem',
    },
    h6: {
      fontSize: '0.8rem',
      fontWeight: '600',
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        textTransform: 'none',
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: '#DFE0E0',
        },
        '&[aria-selected="true"]': {
          backgroundColor: '#F5F5F6',
        },
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: '#94A5A6',
      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        borderRadius: 3,
        color: '#3C4041',
        height: 48,
        padding: '20px',
        '&.MuiButton-text:hover ': {
          background: '#eee',
        },
        '&:disabled': {
          backgroundColor: '#ccc',
        },
      },

      textPrimary: {
        // Some CSS
        borderRadius: 3,
        color: '#3C4041',
        height: 48,
        padding: '20px',
        '&.MuiButton-text:hover ': {
          background: '#FDECEA',
        },
        '&:disabled': {
          backgroundColor: '#ccc',
        },
      },

      textSecondary: {
        // Some CSS
        borderRadius: 3,
        color: '#FB0207',
        padding: '20px',
        '&.MuiButton-text:hover ': {
          background: '#FDECEA',
        },
        '&.MuiTouchRipple-rippleVisible': {
          color: '#005d83 !important',
        },
        '&:disabled': {
          backgroundColor: '#ccc',
        },
      },

      contained: {
        // Some CSS
        backgroundColor: '#89C400',
        borderRadius: 3,
        color: 'white',
        height: 48,
        padding: '20px',
        boxShadow: 'none',
        '&.MuiButton-contained:hover ': {
          background: '#3C4041',
          color: 'white',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: '#ccc',
        },
      },

      containedPrimary: {
        backgroundColor: '#3C4041',
        borderRadius: 3,
        color: 'white',
        height: 48,
        padding: '20px',
        boxShadow: 'none',
        '&.MuiButton-contained:hover ': {
          background: '#636667',
          color: 'white',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: '#ccc',
        },
      },

      containedSecondary: {
        backgroundColor: '#FDECEA',
        borderRadius: 3,
        border: 0,
        color: '#FB0207',
        height: 48,
        padding: '0 20px',
        '&:hover': {
          background: '#FB0207!important',
          color: 'white',
        },
        '&:disabled': {
          backgroundColor: '#ccc',
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#2a2c2d',
          color: '#fff',
        },
        '&$selected, &$selected:hover, &$selected:focus': {
          backgroundColor: '#89c400',
          color: '#fff',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: '1.05rem',
      },
    },
    MuiLinearProgress: {
      root: {
        height: '7px',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: '500',
      },
    },
    // MuiDivider: {
    //   root: {
    //     backgroundColor: '#fff',
    //     marginBottom: '20px',
    //   },
    //},
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#2a2c2d',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#2a2c2d',
          borderWidth: 2,
        },
        '&$error $notchedOutline': {
          borderColor: 'red',
        },
      },
    },
    MuiToolbar: {
      root: {
        //justifyContent: 'space-between'
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        color: '#000',
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#3C4041',
        color: '#fff',
      },
    },
    MuiContainer: {
      maxWidthXl: {
        width: '95%',
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: '#89c400',
      },
      colorSecondary: {
        color: '#3C4041',
      },
    },
  },
})
