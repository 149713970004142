import {auth, storage} from './firebase'

export function signInWithEmail(creds) {
  return auth.signInWithEmailAndPassword(creds.email, creds.password)
}

export function checkUserCustomClaims(){
    return auth.currentUser.getIdTokenResult()
}

export function signOutFirebase() {
  return auth.signOut()
}

export function firebaseResetPasswordEmail(value) {
  return auth.sendPasswordResetEmail(value.email)
}

export function uploadToFirebaseStorage(file, filename) {
    const storageRef = storage.ref()
    return storageRef.child(`/images/${filename}`).put(file)
}

// export async function registerInFirebase(creds) {
//     try{
//         const results = await firebase.auth().createUserWithEmailAndPassword(creds.email, creds.password)
//         await results.user.updateProfile({ displayName })
//     } catch (error) {
//         throw error
//     }
// }
