import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import Login from '../features/Auth/Login'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../api/firebase'
import LoadingBackdrop from '../components/LoadingBackdrop'
import { toast } from 'react-toastify'

export default function PrivateRoute({ component: Component, prevLocation, ...rest }) {
  //const { authenticated } = useSelector((state) => state.FP_AUTH)
  const [user, loading, error] = useAuthState(auth)

  if (loading) {
    return <LoadingBackdrop open={true} />
  }

  if (error) {
    toast.error(error.message)
  }

  return <Route {...rest} render={(props) => (user ? <Component {...props} /> : <Login {...props} />)} />
}
