import {
  AppBar,
  Button,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiInput } from '../../../../components/form/MuiInput'
import { yupResolver } from '@hookform/resolvers/yup'
import AlertDialogWrapper from '../../../../components/alert/AlertDialogWrapper'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { closeAlert } from '../../../../components/alert/AlertReducer'
import { MuiAutoComplete } from '../../../../components/form/MuiAutoComplete'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db } from '../../../../api/firebase'
import CloseIcon from '@material-ui/icons/Close'
import StyledGrid from '../../../../components/layout/Grid'
import { MuiDatePicker } from '../../../../components/form/MuiDatePicker'
import { MuiSelect } from '../../../../components/form/MuiSelect'
import ImageUploadWidget from '../../../../components/Image/ImageUploadWidget'
import Category from '../../../../components/category/category'
import VimeoVideo from '../../../../components/VimeoVideo'
import { Schema } from '../../schema/weekSchema'
import { addPlanWeekToFirestore, updatePlanWeekInFirestore } from '../../../../api/FirestoreServices'
import { toast } from 'react-toastify'
import { removeEmptyFields } from '../../../../util/util'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'sticky',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  containerStyle: {
    marginTop: theme.spacing(8),
  },
  saveButton: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    fontSize: '1.2em',
    '&:hover': {
      backgroundColor: `${theme.palette.common.black} !important`,
      color: theme.palette.common.white,
    },
  },
}))

function PlanWeekAlert({ WeekData, row }) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [files, setFiles] = useState([])
  const [image, setImage] = useState(row?.original?.image)
  const [drinksFiles, setDrinksFiles] = useState([])
  const [drinksImage, setDrinksImage] = useState(row?.original?.drinks_image)
  const [classesFiles, setClassesFiles] = useState([])
  const [classesImage, setClassesImage] = useState(row?.original?.classes_image)

  const clearImage = () => {
    setImage(null)
    setFiles([])
  }
  const clearDrinksImage = () => {
    setDrinksImage(null)
    setDrinksFiles([])
  }
  const clearClassesImage = () => {
    setClassesImage(null)
    setClassesFiles([])
  }

  const transformMeals = (data) => {
    const tags = []

    if (data.meal_tags) {
      Object.keys(data.meal_tags).forEach((meal_tag) => {
        const tagLabel = meal_tag.split('_').join(' ')
        tags.push(tagLabel)
      })
    }

    let label = data.title
    if (tags.length > 0) {
      label = data.title + ' - ' + tags.join(', ')
    }

    return { ...data, label: label }
  }
  const [meals, mealsLoading, mealsError] = useCollectionData(
    db.collection('meals').where(`plans_id.${WeekData.firebase_id}`, '==', true).where('status', '==', 'Published'),
    {
      idField: 'firebase_id',
      transform: transformMeals,
    }
  )

  const transformWorkouts = (data) => {
    const workoutTags = []

    if (data.workout_tags) {
      Object.keys(data.workout_tags).forEach((workout_tags) => {
        const workoutTagLabel = workout_tags.split('_').join(' ')
        workoutTags.push(workoutTagLabel)
      })
    }

    let label = data.title
    if (workoutTags.length > 0) {
      label = data.title + ' - ' + workoutTags.join(', ')
    }
    return { ...data, label: label }

  }
  const [workouts, workoutsLoading, workoutsError] = useCollectionData(
    db
      .collection('weeks_workouts')
      .where('status', '==', 'Published')
      .where(`plans_id.${WeekData.firebase_id}`, '==', true),
    {
      idField: 'firebase_id',
      transform: transformWorkouts,
    }
  )

  const transformVideos = (data) => {
    return { ...data, label: data.subtitle ? data.title + ' ' + data.subtitle : data.title }
  }
  const [videos, videosLoading, videosError] = useCollectionData(
    db.collection('videos').where('status', '==', 'Published').where(`plans_id.${WeekData.firebase_id}`, '==', true),
    {
      idField: 'firebase_id',
      transform: transformVideos,
    }
  )

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      title: row?.original?.title || '',
      week_number: row?.original?.week_number || '',
      week_start_date: !row?.original?.week_start_date
        ? new Date()
        : row?.original?.week_start_date instanceof Date
        ? row?.original?.week_start_date
        : row?.original?.week_start_date.toDate(),
      welcome_text: row?.original?.welcome_text || '',
      goal: row?.original?.goal || '',
      description: row?.original?.description || '',
      breakfast: row?.original?.meal_categories?.breakfast?.meals || [],
      breakfast_percent: row?.original?.meal_categories?.breakfast?.calories_percent || '',
      mid_morning_snack: row?.original?.meal_categories?.mid_morning_snack?.meals || [],
      morning_snack_percent: row?.original?.meal_categories?.mid_morning_snack?.calories_percent || '',
      lunch: row?.original?.meal_categories?.lunch?.meals || [],
      lunch_calorie_percent: row?.original?.meal_categories?.lunch?.calories_percent || '',
      afternoon_snack: row?.original?.meal_categories?.afternoon_snack?.meals || [],
      afternoon_snack_percent: row?.original?.meal_categories?.afternoon_snack?.calories_percent || '',
      dinner: row?.original?.meal_categories?.dinner?.meals || [],
      dinner_calorie_percent: row?.original?.meal_categories?.dinner?.calories_percent || '',
      before_bed: row?.original?.meal_categories?.before_bed?.meals || [],
      before_bed_percent: row?.original?.meal_categories?.before_bed?.calories_percent || '',
      week_decrease_percentage: row?.original?.week_decrease_percentage || '',
      enable_macros: row?.original?.enable_macros || 'No',
      home_workouts: row?.original?.home_workouts || [],
      gym_workouts: row?.original?.gym_workouts || [],
      weeks_classes: row?.original?.weeks_classes || [],
      nutrition_vimeo_video_id: row?.original?.nutrition_vimeo_video_id || '',
      workout_vimeo_video_id: row?.original?.workout_vimeo_video_id || '',
    },
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  const watchFields = methods.watch(['nutrition_vimeo_video_id', 'workout_vimeo_video_id'])

  const macroOptions = ['Yes', 'No']

  async function onSubmit(weekData) {
    const mealCategories = {
      breakfast: { meals: weekData.breakfast, calories_percent: weekData.breakfast_percent },
      mid_morning_snack: { meals: weekData.mid_morning_snack, calories_percent: weekData.morning_snack_percent },
      lunch: { meals: weekData.lunch, calories_percent: weekData.lunch_calorie_percent },
      afternoon_snack: { meals: weekData.afternoon_snack, calories_percent: weekData.afternoon_snack_percent },
      dinner: { meals: weekData.dinner, calories_percent: weekData.dinner_calorie_percent },
      before_bed: { meals: weekData.before_bed, calories_percent: weekData.before_bed_percent },
    }

    delete weekData.breakfast
    delete weekData.mid_morning_snack
    delete weekData.lunch
    delete weekData.afternoon_snack
    delete weekData.dinner
    delete weekData.before_bed

    delete weekData.breakfast_percent
    delete weekData.morning_snack_percent
    delete weekData.lunch_calorie_percent
    delete weekData.afternoon_snack_percent
    delete weekData.dinner_calorie_percent
    delete weekData.before_bed_percent

    weekData.meal_categories = mealCategories

    const media = {
      image: image ? image : undefined,
      drinks_image: drinksImage ? drinksImage : undefined,
      classes_image: classesImage ? classesImage : undefined,
    }

    if (row) {
      const order = row.original.order
      const planId = row.original.planId
      const merged = { ...weekData, ...media, order, planId }
      removeEmptyFields(merged)

      try {
        await updatePlanWeekInFirestore(row.original.id, merged).then(
          WeekData.setWeeks((previousWeeks) => {
            return [...previousWeeks]
          })
        )
        toast.success("Congratulations! we've successfully updated the week.")
      } catch (error) {
        console.log(error)
        toast.error("Sorry we're unable to update this week, please try again.")
      }
    } else {
      try {
        const planId = WeekData.firebase_id
        const order = WeekData.weeks.length + 1
        const merged = { ...weekData, ...media, order, planId }
        removeEmptyFields(merged)
        await addPlanWeekToFirestore(merged).then((docRef) => {
          WeekData.setWeeks((previousWeeks) => {
            return [...previousWeeks, docRef.id]
          })
          //history.push(`/plans/${docRef.id}`)
        })
        toast.success("Congratulations! you've added a new week.")
      } catch (error) {
        console.log(error)
        toast.error("Sorry we're unable to save your week, please try again.")
      }
    }
    dispatch(closeAlert())
  }

  if (mealsLoading || workoutsLoading || videosLoading) {
    return <p>Loading</p>
  }

  return (
    <AlertDialogWrapper fullScreen={true} bgColor={theme.palette.background.default}>
      <FormProvider {...methods}>
        <MuiForm onSubmit={methods.handleSubmit(onSubmit)}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => dispatch(closeAlert())} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h1" className={classes.title}>
                {row ? 'Update Plan Week' : 'Create Plan Week'}
              </Typography>
              <Button autoFocus classes={{ root: classes.saveButton }} type="submit" onClick={() => {}}>
                {row ? 'Update Week' : 'Add Week'}
              </Button>
            </Toolbar>
          </AppBar>
          <Container maxWidth="xl" className={classes.containerStyle}>
            {mealsError || workoutsError || videosError ? <Typography>No Firebase Connection</Typography> : null}
            <StyledGrid title="Week Info">
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={4}>
                  <MuiInput
                    ref={methods.register}
                    multiple={false}
                    id="title"
                    label="Week Title"
                    name="title"
                    error={!!methods.errors?.title}
                    helperText={methods.errors?.title?.message}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiDatePicker
                    id="week_start_date"
                    name="week_start_date"
                    inputLabel="Start Date"
                    control={methods.control}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiInput
                    ref={methods.register}
                    type="number"
                    id="week_number"
                    label="Week Number"
                    name="week_number"
                    error={!!methods.errors?.week_number}
                    helperText={methods.errors?.week_number?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiInput
                    ref={methods.register}
                    id="welcome_text"
                    label="Welcome Text"
                    name="welcome_text"
                    error={!!methods.errors?.welcome_text}
                    helperText={methods.errors?.welcome_text?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiInput
                    ref={methods.register}
                    id="goal"
                    label="Goal"
                    name="goal"
                    error={!!methods.errors?.goal}
                    helperText={methods.errors?.goal?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiInput
                    ref={methods.register}
                    id="description"
                    label="Week Description"
                    name="description"
                    error={!!methods.errors?.description}
                    helperText={methods.errors?.description?.message}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </StyledGrid>
            <StyledGrid title="Nutrition Details">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <MuiAutoComplete
                    multiple
                    id="breakfast"
                    inputLabel="Breakfast"
                    name="breakfast"
                    options={meals ? meals.filter((meal) => meal.meal_types.breakfast === true) : []}
                    error={!!methods.errors?.breakfast}
                    helperText={methods.errors?.breakfast?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <MuiInput
                    ref={methods.register}
                    type="number"
                    id="breakfast_percent"
                    label="Calorie Percentage"
                    name="breakfast_percent"
                    error={!!methods.errors?.breakfast_percent || !!methods.errors?.checkPercentages}
                    helperText={methods.errors?.breakfast_percent?.message}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiAutoComplete
                    multiple
                    id="mid_morning_snack"
                    inputLabel="Morning Snack"
                    name="mid_morning_snack"
                    options={meals ? meals.filter((meal) => meal.meal_types.mid_morning_snack === true) : []}
                    error={!!methods.errors?.mid_morning_snack}
                    helperText={methods.errors?.mid_morning_snack?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <MuiInput
                    ref={methods.register}
                    type="number"
                    id="morning_snack_percent"
                    label="Calorie Percentage"
                    name="morning_snack_percent"
                    error={!!methods.errors?.morning_snack_percent || !!methods.errors?.checkPercentages}
                    helperText={methods.errors?.morning_snack_percent?.message}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <MuiAutoComplete
                    multiple
                    id="lunch"
                    inputLabel="Lunch"
                    name="lunch"
                    options={meals ? meals.filter((meal) => meal.meal_types.lunch === true) : []}
                    error={!!methods.errors?.lunch}
                    helperText={methods.errors?.lunch?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <MuiInput
                    ref={methods.register}
                    id="lunch_calorie_percent"
                    type="number"
                    name="lunch_calorie_percent"
                    label="Calorie Percentage"
                    error={!!methods.errors?.lunch_calorie_percent || !!methods.errors?.checkPercentages}
                    helperText={methods.errors?.lunch_calorie_percent?.message}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiAutoComplete
                    multiple
                    id="afternoon_snack"
                    inputLabel="Afternoon Snack"
                    name="afternoon_snack"
                    options={meals ? meals.filter((meal) => meal.meal_types.afternoon_snack === true) : []}
                    error={!!methods.errors?.afternoon_snack}
                    helperText={methods.errors?.afternoon_snack?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <MuiInput
                    ref={methods.register}
                    type="number"
                    id="afternoon_snack_percent"
                    label="Calorie Percentage"
                    name="afternoon_snack_percent"
                    error={!!methods.errors?.afternoon_snack_percent || !!methods.errors?.checkPercentages}
                    helperText={methods.errors?.afternoon_snack_percent?.message}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <MuiAutoComplete
                    multiple
                    id="dinner"
                    inputLabel="Dinner"
                    name="dinner"
                    options={meals ? meals.filter((meal) => meal.meal_types.dinner === true) : []}
                    error={!!methods.errors?.dinner}
                    helperText={methods.errors?.dinner?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <MuiInput
                    ref={methods.register}
                    type="number"
                    id="dinner_calorie_percent"
                    label="Calorie Percentage"
                    name="dinner_calorie_percent"
                    error={!!methods.errors?.dinner_calorie_percent || !!methods.errors?.checkPercentages}
                    helperText={methods.errors?.dinner_calorie_percent?.message}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiAutoComplete
                    multiple
                    id="before_bed"
                    inputLabel="Before Bed"
                    name="before_bed"
                    options={meals ? meals.filter((meal) => meal.meal_types.before_bed === true) : []}
                    error={!!methods.errors?.before_bed}
                    helperText={methods.errors?.before_bed?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <MuiInput
                    ref={methods.register}
                    id="before_bed_percent"
                    type="number"
                    label="Calorie Percentage"
                    name="before_bed_percent"
                    error={!!methods.errors?.before_bed_percent || !!methods.errors?.checkPercentages}
                    helperText={methods.errors?.before_bed_percent?.message}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <MuiInput
                    ref={methods.register}
                    type="number"
                    id="week_decrease_percentage"
                    label="Week Caloric Decrease Percentage"
                    name="week_decrease_percentage"
                    error={!!methods.errors?.week_decrease_percentage}
                    helperText={methods.errors?.week_decrease_percentage?.message}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiSelect
                    control={methods.control}
                    name="enable_macros"
                    error={!!methods.errors.status}
                    options={macroOptions}
                    label="Enable Macros"
                  />
                  {methods.errors?.enable_macros && (
                    <FormHelperText error>{methods.errors?.enable_macros?.message}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              {methods.errors.checkPercentages ? (
                <FormHelperText error>{methods.errors?.checkPercentages.message}</FormHelperText>
              ) : null}
            </StyledGrid>
            <StyledGrid title="Weeks Workouts">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <MuiAutoComplete
                    multiple
                    id="home_workouts"
                    inputLabel="Home Workouts"
                    name="home_workouts"
                    options={workouts ? workouts.filter((workout) => workout.workout_type === 'Home') : []}
                    error={!!methods.errors?.home_workouts}
                    helperText={methods.errors?.home_workouts?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiAutoComplete
                    multiple
                    id="gym_workouts"
                    inputLabel="Gym Workouts"
                    name="gym_workouts"
                    options={workouts ? workouts.filter((workout) => workout.workout_type === 'Gym') : []}
                    error={!!methods.errors?.gym_workouts}
                    helperText={methods.errors?.gym_workouts?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MuiAutoComplete
                    multiple
                    id="weeks_classes"
                    inputLabel="Classes"
                    name="weeks_classes"
                    options={videos}
                    error={!!methods.errors?.weeks_classes}
                    helperText={methods.errors?.weeks_classes?.message}
                    control={methods.control}
                    multiline={true}
                    rows={3}
                  />
                </Grid>
              </Grid>
            </StyledGrid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <ImageUploadWidget
                  setFiles={setFiles}
                  files={files}
                  image={image}
                  clearImage={clearImage}
                  setImage={setImage}
                  containerTitle="Week Banner Image"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ImageUploadWidget
                  setFiles={setDrinksFiles}
                  files={drinksFiles}
                  image={drinksImage}
                  clearImage={clearDrinksImage}
                  setImage={setDrinksImage}
                  containerTitle="Drinks Banner Image"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ImageUploadWidget
                  setFiles={setClassesFiles}
                  files={classesFiles}
                  image={classesImage}
                  clearImage={clearClassesImage}
                  setImage={setClassesImage}
                  containerTitle="Classes Banner Image"
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Category
                  title="Nutrition Video Details"
                  buttonText="Manage videos on Vimeo"
                  onClick={() => window.open('https://vimeo.com')}
                >
                  <MuiInput
                    ref={methods.register}
                    id="nutrition_vimeo_video_id"
                    label="Nutrition Vimeo Video ID"
                    name="nutrition_vimeo_video_id"
                    error={!!methods.errors?.nutrition_vimeo_video_id}
                    helperText={methods.errors?.nutrition_vimeo_video_id?.message}
                  />
                  {watchFields.nutrition_vimeo_video_id ? (
                    <VimeoVideo videoId={watchFields.nutrition_vimeo_video_id} videoTitle={'Nutrition Vimeo Video'} />
                  ) : null}
                </Category>
              </Grid>
              <Grid item xs={12} md={6}>
                <Category
                  title="Workout Video Details"
                  buttonText="Manage videos on Vimeo"
                  onClick={() => window.open('https://vimeo.com')}
                >
                  <MuiInput
                    ref={methods.register}
                    id="workout_vimeo_video_id"
                    label="Workout Vimeo Video ID"
                    name="workout_vimeo_video_id"
                    error={!!methods.errors?.workout_vimeo_video_id}
                    helperText={methods.errors?.workout_vimeo_video_id?.message}
                  />
                  {watchFields.workout_vimeo_video_id ? (
                    <VimeoVideo videoId={watchFields.workout_vimeo_video_id} videoTitle={'Workout Vimeo Video'} />
                  ) : null}
                </Category>
              </Grid>
            </Grid>
          </Container>
        </MuiForm>
      </FormProvider>
    </AlertDialogWrapper>
  )
}

export default PlanWeekAlert
