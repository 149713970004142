import { FormHelperText, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import Status from '../../../../components/status/Status'
import StyledGrid from '../../../../components/layout/Grid'
import { Schema } from '../../schema/ClassSchema'
import { MuiInput } from '../../../../components/form/MuiInput'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiSelect } from '../../../../components/form/MuiSelect'
import { addVideoToFirestore, deleteVideoInFireStore, updateVideoInFirestore } from '../../../../api/FirestoreServices'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import firebase from 'firebase/app'
import { useHistory } from 'react-router'
import { closeAlert, openAlert } from '../../../../components/alert/AlertReducer'
import ImageUploadWidget from '../../../../components/Image/ImageUploadWidget'
import Category from '../../../../components/category/category'
import VimeoVideo from '../../../../components/VimeoVideo'
import { MuiAutoComplete } from '../../../../components/form/MuiAutoComplete'
import { removeEmptyFields } from '../../../../util/util'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  statusComponent: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
  formComponent: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
}))

function ClassDetailForm({ data, taxonomy, plansOptions, plansSelected }) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [files, setFiles] = useState([])
  const [image, setImage] = useState(data?.image)

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()

  const initialValues = useMemo(() => {
    return {
      title: data?.title,
      subtitle: data?.subtitle,
      duration: data?.duration,
      intensity: data?.intensity,
      trainers: data?.trainers || [],
      topics: data?.topics || [],
      class_equipment: data?.class_equipment || [],
      vimeo_video_id: data?.vimeo_video_id || '',
      status: data?.status || 'Draft',
      plans: plansSelected || [],
    }
  }, [data, plansSelected])

  const additionalFormData = {
    publish_date: firebase.firestore.Timestamp.now(),
    image: image ? image : undefined,
  }

  const firebase_id = data?.firebase_id

  const methods = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  async function deleteVideoFromFirebase() {
    try {
      await deleteVideoInFireStore(data?.firebase_id)
      dispatch(closeAlert())
      toast.success('Video successfully deleted.')
      history.push(`/classes`)
    } catch (error) {
      toast.error("Sorry we're unable to delete video, please try again.")
      dispatch(closeAlert())
    }
  }

  const deleteData = {
    title: 'Are you sure?',
    description:
      'Are you sure you want to delete this video? this is a destructive action and once delete this video cannot be recovered.',
    onClick: () => {
      deleteVideoFromFirebase()
    },
  }

  async function submit(video) {
    const plans = video.plans.reduce((obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }), {})
    const plans_id = video.plans.reduce((obj, cur) => ({ ...obj, [cur.firebase_id]: true }), {})

    const formattedData = {
      plans,
      plans_id,
    }

    const merged = { ...video, ...additionalFormData, ...formattedData }

    removeEmptyFields(merged)

    if (data) {
      try {
        await updateVideoInFirestore(firebase_id, merged)
        toast.success("Congratulations! we've successfully updated your video.")
      } catch (error) {
        console.log(error)
        toast.error("Sorry we're unable to save your video, please try again.")
      }
    } else {
      try {
        await addVideoToFirestore(merged).then((docRef) => {
          history.push(`/class/${docRef.id}`)
        })
        toast.success("Congratulations! you've added a new video.")
      } catch (error) {
        console.log(error)
        toast.error("Sorry we're unable to save your video, please try again.")
      }
    }
  }

  const clearImage = () => {
    setImage(null)
    setFiles([])
  }

  const statusOptions = ['Published', 'Pending', 'Draft']
  const intensityOptions = ['Low', 'Medium', 'High']

  const watchVideo = methods.watch('vimeo_video_id', data?.vimeo_video_id)

  const topicData = {
    title: 'Add New Topic',
    description: 'Add new topic, your topic will be available from the topic menu.',
    taxonomy: taxonomy,
  }

  const trainerData = {
    title: 'Add New Trainer',
    description: 'Add new trainer, your trainer will be available from the trainer menu.',
    taxonomy: taxonomy,
  }

  const classEquipmentData = {
    title: 'Add New Equipment',
    description: 'Add new equipment, your equipment will be available from the equipment menu.',
    taxonomy: taxonomy,
  }

  return (
    <>
      <FormProvider {...methods}>
        <MuiForm className={classes.form} onSubmit={methods.handleSubmit(submit)}>
          <Grid flexdirection="row" container justify="space-between" spacing={isSmall ? 0 : 5}>
            <Grid item sm={12} md={8} xl={9} className={classes.formComponent}>
              <StyledGrid title="On Demand Video Details">
                <Grid container>
                  <MuiInput
                    ref={methods.register}
                    id="title"
                    label="Video Title"
                    name="title"
                    error={!!methods.errors.title}
                    helperText={methods.errors?.title?.message}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <MuiInput
                        ref={methods.register}
                        id="subtitle"
                        label="Video Subtitle"
                        name="subtitle"
                        error={!!methods.errors.subtitle}
                        helperText={methods.errors?.subtitle?.message}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MuiInput
                        ref={methods.register}
                        type="number"
                        name="duration"
                        label="Duration (Mins)"
                        id="duration"
                        placeholder="Enter length of the workout in mins"
                        error={!!methods.errors?.prep_time}
                        helperText={methods.errors?.prep_time?.message}
                      />
                    </Grid>
                  </Grid>
                  <MuiSelect
                    control={methods.control}
                    name="intensity"
                    error={!!methods.errors.intensity}
                    options={intensityOptions}
                    label="Intensity"
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <MuiInput
                        ref={methods.register}
                        name="vimeo_video_id"
                        label="Vimeo Video ID"
                        id="vimeo_video_id"
                        placeholder="Enter an ID eg 492658650..."
                        error={!!methods.errors.vimeo_video_id}
                        helperText={methods.errors?.vimeo_video_id?.message}
                      />
                      <span
                        onClick={() => {
                          window.open('https://vimeo.com')
                        }}
                        style={{
                          marginTop: '10px',
                          cursor: 'pointer',
                          display: 'flex',
                        }}
                      >
                        Manage Videos on Vimeo
                      </span>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '15px',
                          backgroundColor: '#000',
                          textAlign: 'center',
                          height: 300,
                          color: 'white',
                        }}
                      >
                        {watchVideo ? (
                          <VimeoVideo videoId={watchVideo} videoTitle={data?.name ? data?.name : 'Vimeo Video'} />
                        ) : (
                          <>
                            <Typography variant="h2" style={{ marginBottom: '5px' }}>
                              No Video
                            </Typography>
                            <Typography variant="body2" style={{ width: '80%' }}>
                              Add Vimeo video ID to display video.
                            </Typography>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledGrid>
            </Grid>
            <Grid item xs={12} md={4} xl={3} className={classes.statusComponent}>
              <Status
                status={data?.status}
                date={data?.publish_date}
                onDelete={() => dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))}
              >
                <MuiSelect
                  control={methods.control}
                  name="status"
                  error={!!methods.errors.status}
                  options={statusOptions}
                  label="Status"
                />
                {methods.errors?.status && <FormHelperText error>{methods.errors?.status?.message}</FormHelperText>}
              </Status>
              <Category title="Add to plan">
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Plan"
                  name="plans"
                  options={plansOptions}
                  error={!!methods.errors?.plans}
                  helperText={methods.errors?.plans?.message}
                />
              </Category>
              <Category
                title="Trainers"
                buttonText="Add New Trainers"
                onClick={() => dispatch(openAlert({ alertType: 'TrainerAlert', alertProps: { trainerData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Trainers"
                  name="trainers"
                  options={taxonomy.trainers}
                  error={!!methods.errors?.trainers}
                  helperText={methods.errors?.trainers?.message}
                />
              </Category>
              <Category
                title="Topics"
                buttonText="Add New Topic"
                onClick={() => dispatch(openAlert({ alertType: 'TopicAlert', alertProps: { topicData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Topic"
                  name="topics"
                  options={taxonomy.topics}
                  error={!!methods.errors?.topics}
                  helperText={methods.errors?.topics?.message}
                />
              </Category>
              <Category
                title="Equipment"
                buttonText="Add New Equipment"
                onClick={() =>
                  dispatch(openAlert({ alertType: 'ClassEquipmentAlert', alertProps: { classEquipmentData } }))
                }
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Equipment"
                  name="class_equipment"
                  options={taxonomy.class_equipment}
                  error={!!methods.errors?.class_equipment}
                  helperText={methods.errors?.class_equipment?.message}
                />
              </Category>
              <ImageUploadWidget
                setFiles={setFiles}
                files={files}
                image={image}
                clearImage={clearImage}
                setImage={setImage}
              />
            </Grid>
          </Grid>
        </MuiForm>
      </FormProvider>
    </>
  )
}

export default ClassDetailForm
