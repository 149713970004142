import React from 'react'
import TitleBar from '../../components/Titlebar'
import FolderIcon from '@material-ui/icons/Folder'
import Navigation from '../../components/layout/navigation/Navigation'

function Media() {
    //const [display, setDisplay] = useState(false);
//   const fileLibraryList = [
//     {
//       _id: 1,
//       title: 'Me and my dog',
//       size: 294880,
//       fileName: 'img_3549.jpg',
//       type: 'image/jpeg',
//       createdAt: new Date('2019-10-17T03:12:29.866Z'),
//       thumbnailUrl: 'https://images.pexels.com/photos/2691594/pexels-photo-2691594.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
//     },
//     {
//       _id: 2,
//       title: '2019 Summer Vacation',
//       size: 864483,
//       fileName: '201702.jpg',
//       type: 'image/jpeg',
//       createdAt: new Date('2019-10-17T03:12:45.018Z'),
//       thumbnailUrl: 'https://images.pexels.com/photos/3015142/pexels-photo-3015142.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
//     },
//     {
//       _id: 3,
//       title: 'Our new baby',
//       size: 586458,
//       fileName: '271092.jpg',
//       type: 'image/jpeg',
//       createdAt: new Date('2019-10-17T03:19:33.498Z'),
//       thumbnailUrl: 'https://images.pexels.com/photos/3759692/pexels-photo-3759692.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
//     },
//     {
//       _id: 4,
//       title: 'My new car',
//       size: 894665,
//       fileName: 'photo-107.jpg',
//       type: 'image/jpeg',
//       createdAt: new Date('2019-10-17T03:28:39.723Z'),
//       thumbnailUrl: 'https://images.pexels.com/photos/7185854/pexels-photo-7185854.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
//     },
//   ]

  return (
    <>
      <Navigation>
        <TitleBar
          icon={<FolderIcon fontSize="large" />}
          title="Media"
          caption="Manage your exercises, you can add, edit  and delete exercises…"
        />            
      </Navigation>
    
    </>
  )
}

export default Media
