import React from 'react'
import TitleBar from '../../components/Titlebar'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import Navigation from '../../components/layout/navigation/Navigation'

function Orders() {
  return (
    <Navigation>
      <TitleBar
        icon={<ShoppingCartIcon fontSize="large" />}
        title="Orders"
        caption="Manage your exercises, you can add, edit  and delete exercises…"
      />
    </Navigation>
  )
}

export default Orders
