import React from 'react'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import Navigation from '../../../components/layout/navigation/Navigation'
import { addIngredientToFirestore, deleteIngredientInFireStore } from '../../../api/FirestoreServices'
import { Container, Typography, useTheme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import DocumentTable from '../../../components/layout/table/DocumentTable'
import { CopyButtons, EditButtons, DeleteButton } from '../../../components/layout/table/TableButtons'
import TableChip from '../../../components/layout/table/TableChip'
import { useHistory } from 'react-router'
import { SelectColumnFilter } from '../../../components/layout/table/SelectFilter'
import { db } from '../../../api/firebase'
import { toast } from 'react-toastify'
import { closeAlert, openAlert } from '../../../components/alert/AlertReducer'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app'
import Image from 'material-ui-image'
import { Link } from 'react-router-dom'

function Ingredients() {
  const theme = useTheme()

  const history = useHistory()
  const dispatch = useDispatch()

  const [values, loading, error] = useCollectionData(db.collection('ingredients').orderBy('title', 'asc'), {
    idField: 'firebase_id',
  })

  const data = values

  async function copy(ingredientToCopy) {
    const newIngredient = {
      title: ingredientToCopy.title + ' COPY',
      publish_date: firebase.firestore.Timestamp.now(),
      status: 'Draft',
      firebase_id: '',
    }
    const copyIngredient = { ...ingredientToCopy, ...newIngredient }
    try {
      await addIngredientToFirestore(copyIngredient).then((docRef) => {
        history.push(`/ingredients/${docRef.id}`)
      })
      toast.success('Ingredient successfully copied.')
    } catch (error) {
      toast.error("Sorry we're unable to copy this ingredient, please try again.")
    }
  }

  const deleteIngredient = (ingredientToDelete) => {
    const deleteData = {
      title: 'Are you sure?',
      description:
        'Are you sure you want to delete this ingredient? this is a destructive action and this ingredient cannot be recovered',
      onClick: () => {
        deleteIngredientInFireStore(ingredientToDelete.firebase_id)
        dispatch(closeAlert())
      },
    }
    dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))
  }

  const columns = [
    {
      Header: 'Image',
      accessor: (data) => (data.image ? 'Yes' : 'No'),
      id: 'image', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilter,
      Cell: ({ row }) =>
        row.original.image ? (
          <Link to={`/ingredients/${row.original.firebase_id}`}>
            <Image
              src={row.original.image}
              height="65px"
              width="125px"
              alt={row.original.name}
              style={{ objectFit: 'cover', cursor: 'pointer' }}
            />
          </Link>
        ) : null,
    },
    {
        Header: 'Actions',
        disableFilters: true,
        Filter: () => null,
        Cell: ({ row }) => (
          <div style={{ minWidth: 160 }}>
            <CopyButtons
              onClick={() => {
                copy(row.original)
              }}
            />
            <EditButtons link={`/ingredients/${row.original.firebase_id}`} />
            <DeleteButton onClick={() => deleteIngredient(row.original)} />
          </div>
        ),
      },
    {
      Header: '#ID',
      accessor: 'firebase_id', // accessor is the "key" in the data,
      //disableGlobalFilter: true,
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Ingredient Title',
      accessor: 'title', // accessor is the "key" in the data
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Unit of Measure',
      accessor: 'unit',
      Filter: SelectColumnFilter,
      filter: 'equals',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'equals',
      Cell: ({ row }) => {
        const status = row.values.status
        return status === 'Published' ? (
          <TableChip label="Published" color={theme.palette.published.main} />
        ) : status === 'Draft' ? (
          <TableChip label="Draft" color={theme.palette.draft.main} />
        ) : status === 'Pending' ? (
          <TableChip label="Pending" color={theme.palette.pending.main} />
        ) : null
      },
    },
   
  ]

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Ingredients"
        caption="Manage your exercises, you can add, edit  and delete exercises…"
        buttonTitle="Add Ingredient"
        onClick={() => history.push('/ingredients/create-ingredient')}
      />
      <Container maxWidth="xl">
        {error && <Typography variant="h1">No Firebase Connection </Typography>}
        {!error && (
          <DocumentTable data={data} columns={columns} isLoading={loading} placeHolderText="Search for ingredients" />
        )}
      </Container>
    </Navigation>
  )
}

export default Ingredients
