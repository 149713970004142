import React, { useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Route, Switch, Redirect } from 'react-router-dom'
import { auth } from '../api/firebase'
import LoadingBackdrop from '../components/LoadingBackdrop'
import Login from '../features/Auth/Login'
import ResetPassword from '../features/Auth/ResetPassword'
//import Dashboard from '../features/dashboard/Dashboard'
import Exercises from '../features/exercises/pages/Exercises'
import CreateMeal from '../features/meals/pages/CreateMeal'
import CreateIngredient from '../features/ingredients/pages/CreateIngredient'
import IngredientDetail from '../features/ingredients/pages/IngredientDetail'
import Ingredients from '../features/ingredients/pages/Ingredients'
import MealDetail from '../features/meals/pages/MealDetail'
import Meals from '../features/meals/pages/Meals'
import Media from '../features/media/Media'
import Orders from '../features/orders/Orders'
import Sandbox from '../features/sandbox/Sandbox'
import Subscriptions from '../features/subscriptions/Subscriptions'
import Users from '../features/users/Users'
import PrivateRoute from './privateRoute'
import ExerciseDetail from '../features/exercises/pages/ExerciseDetail'
import CreateExercise from '../features/exercises/pages/CreateExercise'
import NotFound from '../features/404/404'
import Workouts from '../features/workouts/pages/Workouts'
import WorkoutDetail from '../features/workouts/pages/WorkoutDetail'
import Classes from '../features/classes/pages/Classes'
import CreateWorkout from '../features/workouts/pages/CreateWorkout'
import Plans from '../features/plans/Pages/Plans'
import PlanDetail from '../features/plans/Pages/PlanDetail'
import CreateClass from '../features/classes/pages/CreateClass'
import ClassDetails from '../features/classes/pages/ClassDetails'
import CreatePlan from '../features/plans/Pages/CreatePlan'
import { checkUserCustomClaims, signOutFirebase } from '../api/FirebaseService'
import { toast } from 'react-toastify'


function Routes() {
  const [user, loading] = useAuthState(auth)

  const [admin, setAdmin] = useState(false)

  const checkAccessLevel = async () => {
      await checkUserCustomClaims().then((userIdToken) => {
        if (!userIdToken.claims.admin) {
          setAdmin(false)
          signOutFirebase()
          toast.error('Your not authorized to access this site.')
        } else {
          setAdmin(true)
        }
      })
  }

  if (user && !loading) {
  checkAccessLevel()
  }

  if (loading) {
    return <LoadingBackdrop open={true} />
  }

  return (
    <>
      <Switch>
          <Route exact path="/">
            {user && admin ? <Redirect to="/plans" /> : <Login />}
          </Route>
          <Route exact path="/reset-password" component={ResetPassword} />
          {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
          <PrivateRoute exact path="/sandbox" component={Sandbox} />
          <PrivateRoute exact path="/orders" component={Orders} />
          <PrivateRoute exact path="/subscriptions" component={Subscriptions} />
          <PrivateRoute exact path="/users" component={Users} />
          <PrivateRoute exact path="/ingredients" component={Ingredients} />
          <PrivateRoute exact path="/ingredients/create-ingredient" component={CreateIngredient} />
          <PrivateRoute exact path="/ingredients/:id" component={IngredientDetail} />
          <PrivateRoute exact path="/meals" component={Meals} />
          <PrivateRoute exact path="/meals/create-meal" component={CreateMeal} />
          <PrivateRoute exact path="/meals/:id" component={MealDetail} />
          <PrivateRoute exact path="/exercises" component={Exercises} />
          <PrivateRoute exact path="/exercises/create-exercise" component={CreateExercise} />
          <PrivateRoute exact path="/exercises/:id" component={ExerciseDetail} />
          <PrivateRoute exact path="/workouts" component={Workouts} />
          <PrivateRoute exact path="/workouts/create-workout" component={CreateWorkout} />
          <PrivateRoute exact path="/workouts/:id" component={WorkoutDetail} />
          <PrivateRoute exact path="/classes" component={Classes} />
          <PrivateRoute exact path="/class/create-class" component={CreateClass} />
          <PrivateRoute exact path="/class/:id" component={ClassDetails} />
          <PrivateRoute exact path="/plans" component={Plans} />
          <PrivateRoute exact path="/plans/create-plan" component={CreatePlan} />
          <PrivateRoute exact path="/plans/:id" component={PlanDetail} />
          <PrivateRoute exact path="/Media" component={Media} />
          <PrivateRoute component={NotFound} />
      </Switch>
    </>
  )
}

export default Routes
