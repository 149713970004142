import { Container, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Navigation from '../../../components/layout/navigation/Navigation'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import PlanForm from '../components/forms/PlanForm'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import PlanDetailSkeleton from '../components/skeletonLoaders/PlanDetailSkeleton'
//import sizeof from 'object-sizeof'

function PlanDetail({ match, history }) {
  const [loading, setLoading] = useState(true)

  const transformPlan = (data) => {
    const genders = []
    if (data.gender) {
      Object.keys(data.gender).forEach((key, index) => {
        genders.push({
          label: key,
          ...data.gender,
        })
      })
    }
    return { ...data, gender: genders }
  }

  const [plan, planLoading, planError] = useDocumentData(db.collection('plans').doc(match.params.id), {
    idField: 'firebase_id',
    transform: transformPlan,
  })

  const [planWeeks, planWeeksLoading, planWeeksError] = useCollectionData(
    db.collection('plan_weeks').where('planId', '==', match.params.id),
    {
      idField: 'id',
    }
  )

  //Redirect if error
  useEffect(() => {
    if (!planLoading && plan === undefined) {
      history.push('/404')
    }
  }, [history, plan, planLoading])

  useEffect(() => {
    if (!planLoading && !planWeeksLoading) {
      setLoading(false)
    }
  }, [planLoading, planWeeksLoading])

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Plans"
        caption="Manage your Plan, you can edit and delete the plan from here, don’t forget to add start and end dates…"
        buttonTitle="Add New Plan"
        onClick={() => history.push('/plans/create-plan/')}
      />
      <Container maxWidth="xl">
        {planError || planWeeksError ? <Typography variant="h1">No Firebase Connection </Typography> : null}
        {!planError && loading ? (
          <PlanDetailSkeleton />
        ) : (
          <PlanForm data={plan} weekData={planWeeks.sort((a, b) => a.order - b.order)} />
        )}
      </Container>
    </Navigation>
  )
}

export default PlanDetail
