import { Container, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import ListIcon from '@material-ui/icons/List'
import TitleBar from '../../../components/Titlebar'
import Navigation from '../../../components/layout/navigation/Navigation'
import ClassForm from '../components/forms/classForm'
import { db } from '../../../api/firebase'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import ClassDetailSkeleton from '../components/skeletonLoaders/ClassDetailSkeleton'
import { findSelected } from '../../../util/util'
import moment from 'moment'

function ClassDetails({ history, match }) {
  const [video, videoLoading, videoError] = useDocumentData(db.collection('videos').doc(match.params.id), {
    idField: 'firebase_id',
  })

  const transformTaxonomy = (data) => {
    const class_equipment = []
    const topics = []
    const trainers = []

    if (data.class_equipment) {
      Object.keys(data.class_equipment).forEach((key, index) => {
        class_equipment.push({
          label: key.split('_').join(' '),
        })
      })
    }

    if (data.trainers) {
        Object.keys(data.trainers).forEach((key, index) => {
            trainers.push({
            label: key.split('_').join(' '),
          })
        })
      }

    if (data.topics) {
      Object.keys(data.topics).forEach((key, index) => {
        topics.push({
          label: key.split('_').join(' '),
        })
      })
    }
    return { ...data, originalCategories: data, class_equipment: class_equipment, topics: topics, trainers: trainers }
  }
  const [taxonomy, taxonomyLoading, taxonomyError] = useDocumentData(db.collection('taxonomy').doc('video'), {
    transform: transformTaxonomy,
  })

  // Get plans from firebase
  const transformPlans = (data) => {
    let label
    if (!data.start_date) {
      label = data.title
    } else {
      label = data.title + ' ' + moment(data.start_date.toDate()).format('MMMM YYYY')
    }
    return { title: data.title.toLowerCase(), label: label.toLowerCase(), firebase_id: data.firebase_id }
  }
  const [plans, plansLoading, plansError] = useCollectionData(db.collection('plans').orderBy('title', 'asc'), {
    idField: 'firebase_id',
    transform: transformPlans,
  })
  //Redirect if error
  useEffect(() => {
    if (!videoLoading && video === undefined) {
      history.push('/404')
    }
  }, [history, video, videoLoading])

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Class"
        caption="Manage your class, you can add edit delete, don’t forget to add a topic…"
        onClick={() => history.push('/class/create-class/')}
        buttonTitle="Add New Class"
      />
      <Container maxWidth="xl">
        {taxonomyError || videoError || plansError ? (
          <Typography variant="h1">No Firebase Connection </Typography>
        ) : null}
        {videoLoading || taxonomyLoading || plansLoading ? <ClassDetailSkeleton /> : null}
        {video && taxonomy && plans && (
          <ClassForm
            data={video}
            taxonomy={taxonomy}
            plansOptions={plans}
            plansSelected={plans && video.plans_id ? findSelected(plans, video.plans_id, 'label') : []}
          />
        )}
      </Container>
    </Navigation>
  )
}

export default ClassDetails
