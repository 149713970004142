import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

export const MuiSelect = ({ control, options, name, error, label, space }) => {
  return (
    <FormControl variant="outlined" style={{ minWidth: '100%', marginTop: space ? space : '10px' }}>
      <InputLabel id="unit-select-outlined-label">{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        as={
          <Select
            labelId="status-select-outlined-label"
            label={label}
            fullWidth
            error={error}
            variant="outlined"
          >
            {options.map((option, i) => (
              <MenuItem key={option + i} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        }
      />
      {error && <FormHelperText error>{label + ' is required'}</FormHelperText>}
    </FormControl>
  )
}
