import {
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  Typography,
  withStyles,
} from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
//import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { signOutFirebase } from '../../../api/FirebaseService'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../api/firebase'

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  menu: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  StyledListItemIcon: {
    color: '#000',
    minWidth: theme.spacing(4),
    '&:hover': {
      color: '#fff',
    },
  },
}))

const StyledMuiListItem = withStyles((theme) => ({
  button: {
    '&:hover .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      color: '#fff!important',
    },
  },
}))(ListItem)

function ProfileMenu({ anchorEl, menuId, handleProfileMenuClose }) {
  const history = useHistory()

  const [user, loading, error] = useAuthState(auth)

  const LogOutUser = async () => {
    try {
      await signOutFirebase()
      history.push('/')
    } catch (error) {
      //TODO SHOW ERROR IF FAILED TO LOGOUT
      console.log(error)
    }
  }
  const classes = useStyles()
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleProfileMenuClose}
      elevation={6}
    >
      <Grid container className={classes.menu}>
        {/* <Avatar
          variant="rounded"
          className={classes.avatar}
          src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        /> */}
        <Grid>
          {!loading && !error && (
            <>
              <Typography variant="body2">{user?.displayName}</Typography>
              <Typography variant="inherit">{user?.email}</Typography>
            </>
          )}
        </Grid>
      </Grid>
      {/* <StyledMuiListItem button>
        <ListItemIcon className={classes.StyledListItemIcon}>
          <PersonOutlineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="My Profile" />
      </StyledMuiListItem> */}
      <StyledMuiListItem button onClick={LogOutUser}>
        <ListItemIcon className={classes.StyledListItemIcon}>
          <PowerSettingsNewIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Log Out" />
      </StyledMuiListItem>
    </Menu>
  )
}

export default ProfileMenu
