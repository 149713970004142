import React from 'react'

function VimeoVideo({ videoId, videoTitle }) {
  return (
    <>
        <iframe
          src={`https://player.vimeo.com/video/${videoId}`}
          allow="autoplay; fullscreen; picture-in-picture"
          style={{ width: '100%', marginTop: '20px', border: '0' }}
          title={videoTitle}
        ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  )
}

export default VimeoVideo
