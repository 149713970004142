/* eslint-disable */
import React, { useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import LanguageIcon from '@material-ui/icons/Language'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Avatar, Badge, Button, Grid, Hidden, Typography } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SidebarNavigationItems from './SidebarNavigationItems'
import ProfileMenu from './ProfileMenu'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import logo from '../../../assets/images/logo.png'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../api/firebase'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    borderRight: 'none',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  buttonSecondary: {
    background: '#EFF8E5',
    color: theme.palette.primary.main,
    height: theme.spacing(5),
    padding: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&:hover .MuiSvgIcon-root.MuiSvgIcon-colorPrimary': {
      color: '#fff!important',
    },
  },
  sectionDesktop: {
    display: 'flex',
    paddingTop: '5px',
  },
  avatar: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  notification: {
    backgroundColor: '#EFF8E5',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '4px',
    '&:hover': {
      background: '#89C400',
      color: '#fff',
    },
    '&:hover .MuiSvgIcon-root.MuiSvgIcon-colorPrimary': {
      color: '#fff',
    },
  },
  pointer: {
    cursor: 'pointer!important',
  },
  logo: {
    marginRight: theme.spacing(1.3),
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
  },
  logoText: {
    paddingTop: theme.spacing(0.4),
  },
  divider: {
    backgroundColor: '#fff',
    marginBottom: '20px',
  },
}))

export default function Navigation(props) {
  const { window } = props
  const classes = useStyles()
  const location = useLocation()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [user, loading, error] = useAuthState(auth)

  useEffect(() => {
    setMobileOpen(false)
    return () => {
      setOpen(true)
    }
  }, [location])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const toggleDesktopDrawer = () => {
    setOpen(!open)
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleProfileMenuClose = () => {
    setAnchorEl(false)
  }

  const menuId = 'primary-profile-menu'

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.root}>
      <Paper>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <Hidden xsDown implementation="css">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDesktopDrawer}
                    edge="start"
                    className={!open ? clsx(classes.menuButton) : clsx(classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    className={classes.buttonSecondary}
                    startIcon={<LanguageIcon color="primary" className={classes.MyCustomButton} />}
                    target="_blank"
                    href="https://www.ultimateshred.co.uk"
                  >
                    <Typography variant="button">Visit Website</Typography>
                  </Button>
                </Hidden>
                <Hidden smUp>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    className={clsx(classes.menuButton)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              </Grid>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {/* <Badge
                  badgeContent={17}
                  variant={'dot'}
                  color="error"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <IconButton aria-label="show 17 new notifications" color="inherit" className={classes.notification}>
                    <NotificationsIcon color="primary" />
                  </IconButton>
                </Badge> */}
                <Grid className={classes.pointer} container onClick={handleProfileMenuOpen}>
                  {/* <Avatar
                    variant="rounded"
                    className={classes.avatar}
                    src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  ></Avatar> */}
                  <Grid item>
                    <Typography variant="caption" color="textSecondary">
                      Admin
                    </Typography>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      {!loading && (
                        <Typography variant="body2">{user?.displayName ? user?.displayName : user?.email}</Typography>
                      )}
                      <ExpandMoreIcon />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Toolbar>
        </AppBar>
      </Paper>
      <ProfileMenu anchorEl={anchorEl} menuId={menuId} handleProfileMenuClose={handleProfileMenuClose} />
      <Hidden xsDown>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Avatar alt="Ultimate Shred Logo" src={logo} className={classes.logo} />
            <Grid container direction="row">
              <Typography variant="h6">Ultimate Shred</Typography>
              <WhatshotIcon htmlColor="#FFCA28" />
              <Typography variant="caption" classes={{ caption: classes.logoText }}>
                FirePress
              </Typography>
            </Grid>
            <IconButton onClick={toggleDesktopDrawer}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon htmlColor="#fff" /> : <ChevronRightIcon htmlColor="#fff" />}
            </IconButton>
          </div>
          <Divider className={classes.divider} />
          <SidebarNavigationItems />
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <Drawer
          //className={classes.drawer}
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader}>
            <Avatar alt="Ultimate Shred Logo" src={logo} className={classes.logo} />
            <Grid container direction="row">
              <Typography variant="h6">Ultimate Shred</Typography>
              <WhatshotIcon htmlColor="#FFCA28" />
              <Typography variant="caption" classes={{ caption: classes.logoText }}>
                FirePress
              </Typography>
            </Grid>
            <IconButton onClick={handleDrawerToggle}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon htmlColor="#fff" /> : <ChevronRightIcon htmlColor="#fff" />}
            </IconButton>
          </div>
          <Divider className={classes.divider} />
          <SidebarNavigationItems />
        </Drawer>
      </Hidden>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  )
}
