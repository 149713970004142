import React, { useCallback, useState } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import Image from 'material-ui-image'
import Category from '../category/category'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { uploadToFirebaseStorage } from '../../api/FirebaseService'
import { getFileExtension } from '../../util/util'
import cuid from 'cuid'
import { Skeleton } from '@material-ui/lab'
import { LinearProgressWithLabel } from '../LinearProgressWithLabel'

const useStyles = makeStyles((theme) => ({
  dropZoneStyle: {
    border: `3px dashed ${theme.palette.borders.main}`,
    backgroundColor: `${theme.palette.background.default}`,
    borderRadius: '5%',
    minHeight: '250px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '25px',
  },
  dropZoneActive: {
    border: `4px dashed ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.background.default}`,
    borderRadius: '5%',
    paddingLeft: '20px',
    paddingRight: '20px',
    minHeight: '250px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textStyle: {
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 250,
    margintBottom: theme.spacing(2),
  },
}))

function ImageUploadWidget({ files, setFiles, clearImage, image, setImage, containerTitle }) {
  const classes = useStyles()
  const [loading, setLoading] = useState()
  const [progress, setProgress] = useState(0)

  const onDrop = useCallback(
    (acceptedFiles, fileRejections, FileError) => {
        //TODO: Error handling for multiple file and max file size
        // if(fileRejections > 0){
        //     console.log(fileRejections)
        //     alert(`File Rejected, ${fileRejections[0].errors[0].message}`)
        //     return
        // } 
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
      const filename = cuid() + '.' + getFileExtension(acceptedFiles[0].name)
      const uploadTask = uploadToFirebaseStorage(acceptedFiles[0], filename)
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          setLoading(true)
          const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setProgress(uploadProgress)
          if (uploadProgress >= 100) {
            setLoading(false)
          }
        },
        (error) => {
          toast.error(error.message)
          setLoading(false)
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setImage(downloadURL)
            setLoading(false)
          })
        }
      )
    },
    [setFiles, setImage]
  )

  //const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: 'image/jpeg, image/png', maxFiles: 1, onDrop })
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <Category
        title={containerTitle ?? "Upload an image"}
        buttonText={!image && files?.length <= 0 ? null : 'Change Image'}
        onClick={clearImage}
      >
        {files?.length <= 0 && !image && (
          <div {...getRootProps()} className={isDragActive ? classes.dropZoneActive : classes.dropZoneStyle}>
            <input {...getInputProps()} />
            <>
              <Typography variant="body2" className={classes.textStyle}>
                {isDragActive ? "Let gooo. Drop that file here I've got it..." : `Drag 'n' drop some files here, or`}
              </Typography>
              {isDragActive ? null : (
                <Button variant="contained" onClick={() => {}}>
                  <Typography variant="button">select files</Typography>
                </Button>
              )}
            </>
          </div>
        )}
        {loading && (
          <>
            <Skeleton animation="wave" variant="rect" className={classes.media} />
            <LinearProgressWithLabel value={progress} />
          </>
        )}
        {files?.length > 0 && loading === false && (
          <>
            <Image
              onClick={clearImage}
              loading={false}
              imageStyle={{ height: 'auto!important', position: 'unset', cursor: 'pointer' }}
              style={{ paddingTop: '0' }}
              src={files[0].preview ? files[0].preview : ''}
            />
          </>
        )}
        {image && files.length <= 0 && (
          <Image
            onClick={clearImage}
            //loading={<Skeleton variant="rect" width={'100%'} height={200} style={{ marginBottom: '15px', position: 'relative' }} />}
            imageStyle={{ height: 'auto!important', position: 'unset', cursor: 'pointer' }}
            style={{ paddingTop: '0' }}
            src={image}
          />
        )}
      </Category>
    </>
  )
}

export default ImageUploadWidget
