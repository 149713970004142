import { FormHelperText, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import Status from '../../../../components/status/Status'
import StyledGrid from '../../../../components/layout/Grid'
import { Schema } from '../../schema/IngredientSchema'
import { MuiInput } from '../../../../components/form/MuiInput'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiSelect } from '../../../../components/form/MuiSelect'
import {
  addIngredientToFirestore,
  deleteIngredientInFireStore,
  updateIngredientToFirestore,
} from '../../../../api/FirestoreServices'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import firebase from 'firebase/app'
import { useHistory } from 'react-router'
import { closeAlert, openAlert } from '../../../../components/alert/AlertReducer'
import ImageUploadWidget from '../../../../components/Image/ImageUploadWidget'
import { removeEmptyFields } from '../../../../util/util'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  statusComponent: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
  formComponent: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
}))

function IngredientsNutritionalInfoForm({ data }) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [files, setFiles] = useState([])
  const [image, setImage] = useState(data?.image)

  const history = useHistory()

  const initialValues = useMemo(() => {
    return {
      title: data?.title,
      base_amount: data?.base_amount,
      unit: data?.unit === 'gram' || data?.unit === 'ml' ? data?.unit : 'Whole Unit',
      carbs: data?.carbs,
      fats: data?.fats,
      proteins: data?.proteins,
      display_unit: data?.display_unit || null,
      status: data?.status || '',
      allergens: data?.allergens || [],
    }
  }, [data])

  const additionalFormData = {
    publish_date: data?.publish_date ? data?.publish_date : firebase.firestore.Timestamp.now(),
    updated_date: firebase.firestore.Timestamp.now(),
    image: image ? image : undefined,
    firebase_id: data?.firebase_id,
  }

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const methods = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  const watchUnit = methods.watch('unit')

  async function deleteIngredientFromFirebase() {
    try {
      await deleteIngredientInFireStore(data?.firebase_id)
      dispatch(closeAlert())
      toast.success('Ingredient successfully deleted.')
      history.push(`/ingredients`)
    } catch (error) {
      toast.error("Sorry we're unable to delete ingredient, please try again.")
      dispatch(closeAlert())
    }
  }

  const deleteData = {
    title: 'Are you sure?',
    description:
      'Are you sure you want to delete this ingredient? this is a destructive action and once delete this ingredient cannot be recovered.',
    onClick: () => {
      deleteIngredientFromFirebase()
    },
  }

  async function submit(ingredient) {

    removeEmptyFields(ingredient)
    removeEmptyFields(additionalFormData)

    const merged = { ...ingredient, ...additionalFormData }
    
    if (data) {
      try {
        await updateIngredientToFirestore(data.firebase_id, merged)
        toast.success("Congratulations! we've successfully updated your ingredient.")
      } catch (error) {
        toast.error("Sorry we're unable to save your ingredient, please try again.")
      }
    } else {
      try {
        await addIngredientToFirestore(merged).then((docRef) => {
          history.push(`/ingredients/${docRef.id}`)
        })
        toast.success("Congratulations! you've added a new ingredient.")
      } catch (error) {
        toast.error("Sorry we're unable to save your ingredient, please try again.")
      }
    }
  }

  const clearImage = () => {
    setImage(null)
    setFiles([])
  }

  const unitsOfMeasure = ['gram', 'ml', 'Whole Unit']
  const statusOptions = ['Published', 'Pending', 'Draft']

  return (
    <>
      <FormProvider {...methods}>
        <MuiForm className={classes.form} onSubmit={methods.handleSubmit(submit)}>
          <Grid flexdirection="row" container justify="space-between" spacing={isSmall ? 0 : 5}>
            <Grid item sm={12} md={8} xl={9} className={classes.formComponent}>
              <StyledGrid title="Nutritional Info">
                <Grid container>
                  <MuiInput
                    ref={methods.register}
                    id="title"
                    label="Ingredient Title"
                    name="title"
                    error={!!methods.errors.title}
                    helperText={methods.errors?.title?.message}
                  />
                  <MuiSelect
                    control={methods.control}
                    name="unit"
                    error={!!methods.errors.unit}
                    options={unitsOfMeasure}
                    label="Unit of Measure"
                  />
                  {watchUnit === 'Whole Unit' ? (
                    <MuiInput
                      ref={methods.register}
                      id="display_unit"
                      label="Display Unit"
                      name="display_unit"
                      error={!!methods.errors.display_unit}
                      helperText={methods.errors?.display_unit?.message}
                    />
                  ) : null}
                  <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={6}>
                      <MuiInput
                        ref={methods.register}
                        type="number"
                        name="base_amount"
                        label="Amount"
                        id="base_amount"
                        error={!!methods.errors.base_amount}
                        helperText={methods.errors?.base_amount?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MuiInput
                        ref={methods.register}
                        type="number"
                        name="carbs"
                        label="Carbs"
                        id="carbs"
                        error={!!methods.errors.carbs}
                        helperText={methods.errors?.carbs?.message}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={6}>
                      <MuiInput
                        ref={methods.register}
                        type="number"
                        name="fats"
                        label="Fats"
                        id="fats"
                        error={!!methods.errors.fats}
                        helperText={methods.errors?.fats?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MuiInput
                        ref={methods.register}
                        type="number"
                        name="proteins"
                        label="Protein"
                        id="proteins"
                        error={!!methods.errors.proteins}
                        helperText={methods.errors?.proteins?.message}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </StyledGrid>
            </Grid>
            <Grid item xs={12} md={4} xl={3} className={classes.statusComponent}>
              <Status
                status={data?.status}
                date={data?.publish_date}
                onDelete={() => dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))}
              >
                <MuiSelect
                  control={methods.control}
                  name="status"
                  error={!!methods.errors.status}
                  options={statusOptions}
                  label="Status"
                />
                {methods.errors?.status && <FormHelperText error>{methods.errors?.status?.message}</FormHelperText>}
              </Status>
              <ImageUploadWidget
                setFiles={setFiles}
                files={files}
                image={image}
                clearImage={clearImage}
                setImage={setImage}
              />
            </Grid>
          </Grid>
        </MuiForm>
      </FormProvider>
    </>
  )
}

export default IngredientsNutritionalInfoForm
