import { IconButton, Tooltip, withStyles } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import React from 'react'
import { Link } from 'react-router-dom'

const EdButton = withStyles({
  root: {
    color: '#4191FF',
    padding: '9px',
    overflow: 'visible',
    fontSize: '1.4rem',
    backgroundColor: '#4191ff26',
    margin: '5px',
    borderRadius: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: '#4191FF',
      color: 'white',
      border: 'none',
    },
  },
})(IconButton)

const DelButton = withStyles({
  root: {
    color: '#FB0207',
    padding: '9px',
    overflow: 'visible',
    fontSize: '1.4rem',
    backgroundColor: '#FDECEA',
    margin: '5px',
    borderRadius: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: '#FB0207',
      color: 'white',
      border: 'none',
    },
  },
})(IconButton)

const CpButton = withStyles({
  root: {
    color: '#000',
    padding: '9px',
    overflow: 'visible',
    fontSize: '1.4rem',
    backgroundColor: '#CCCCCC',
    margin: '5px',
    borderRadius: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: '#000',
      color: 'white',
      border: 'none',
    },
  },
})(IconButton)

export function CopyButtons({ onClick }) {
  return (
    <Tooltip title="Duplicate" aria-label="Delete">
      <CpButton aria-label="first" size="medium" onClick={onClick}>
        <FileCopyIcon fontSize="inherit" />
      </CpButton>
    </Tooltip>
  )
}

export function EditButtons({ onClick, link }) {
  if (link) {
    return (
      <Link to={link}>
        <Tooltip title="Edit" aria-label="Edit">
          <EdButton aria-label="first" size="medium" onClick={onClick}>
            <BorderColorIcon fontSize="inherit" />
          </EdButton>
        </Tooltip>
      </Link>
    )
  }
  return (
    <Tooltip title="Edit" aria-label="Edit">
      <EdButton aria-label="first" size="medium" onClick={onClick}>
        <BorderColorIcon fontSize="inherit" />
      </EdButton>
    </Tooltip>
  )
}
export function DeleteButton({ onClick }) {
  return (
    <Tooltip title="Delete" aria-label="Delete">
      <DelButton aria-label="first" size="medium" onClick={onClick}>
        <DeleteIcon fontSize="inherit" />
      </DelButton>
    </Tooltip>
  )
}
