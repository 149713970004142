import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiInput } from '../../../../components/form/MuiInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AlertDialogWrapper from '../../../../components/alert/AlertDialogWrapper'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { closeAlert } from '../../../../components/alert/AlertReducer'
import { updateTaxonomyInFirestore } from '../../../../api/FirestoreServices'
import { toast } from 'react-toastify'

export const Schema = yup.object().shape({
  name: yup.string().min(3).required(),
})

function EquipmentAlert(props) {
  const dispatch = useDispatch()
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  async function onSubmit(equipment) {
    const currentEquipment = props.equipmentData.taxonomy.originalCategories.equipment

    const newEquipment = {}
    newEquipment[equipment.name.split(' ').join('_')] = true

    const merged = { ...currentEquipment, ...newEquipment }

    try {
      await updateTaxonomyInFirestore('exercise', { equipment: merged }).then((docRef) => {
        dispatch(closeAlert())
      })
      toast.success('Congratulations! equipment successfully added.')
    } catch (error) {
        console.log(error)
      toast.error("Sorry we're unable to add that equipment type, please try again.")
    }
  }

  return (
    <AlertDialogWrapper>
      <DialogTitle id="alert-dialog-slide-title">{props.equipmentData.title}</DialogTitle>
      <MuiForm onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{props.equipmentData.description}</DialogContentText>
          <MuiInput
            ref={register}
            id="name"
            label="Equipment Title"
            placeholder="e.g Dumbbell"
            name="name"
            error={!!errors.name}
            helperText={errors?.name?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => dispatch(closeAlert())}>
            Cancel
          </Button>
          <Button type="submit" variant="text">
            Add Equipment
          </Button>
        </DialogActions>
      </MuiForm>
    </AlertDialogWrapper>
  )
}

export default EquipmentAlert
