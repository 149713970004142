import { FormHelperText, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import Status from '../../../../components/status/Status'
import StyledGrid from '../../../../components/layout/Grid'
import { MuiInput } from '../../../../components/form/MuiInput'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiSelect } from '../../../../components/form/MuiSelect'
import {
  addWorkoutToFirestore,
  deleteWorkoutFromFireStore,
  updateWorkoutInFirestore,
} from '../../../../api/FirestoreServices'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import firebase from 'firebase/app'
import { useHistory } from 'react-router'
import { closeAlert, openAlert } from '../../../../components/alert/AlertReducer'
import ImageUploadWidget from '../../../../components/Image/ImageUploadWidget'
import { MuiAutoComplete } from '../../../../components/form/MuiAutoComplete'
import Category from '../../../../components/category/category'
import { Schema } from '../../schema/WorkoutSchema'
import Exercises from '../repeaters/Exercises'
import VimeoVideo from '../../../../components/VimeoVideo'
import { removeEmptyFields } from '../../../../util/util'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  statusComponent: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
  formComponent: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
}))

function WorkoutForm({ data, alternativeWorkoutOptions, taxonomy, selectedAlternativeWorkouts, plansSelected, plans }) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [files, setFiles] = useState([])
  const [image, setImage] = useState(data?.image)
  const [exercises, setExercises] = useState(data?.exercises)

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const history = useHistory()

  const initialValues = useMemo(() => {
    return {
      title: data?.title || '',
      subtitle: data?.instructions?.title || '',
      description: data?.instructions?.description ? data?.instructions?.description.replace('<br />', '\n\n') : '',
      alternative_workouts: selectedAlternativeWorkouts || [],
      duration: data?.duration || '',
      laps: data?.laps || '',
      vimeo_video_id: data?.vimeo_video_id || '',
      status: data?.status || '',
      workout_type: data?.workout_type || '',
      workout_method: data?.workout_method || [],
      plans: plansSelected || [],
      workout_tags: data?.workout_tags || [],
    }
  }, [data, selectedAlternativeWorkouts, plansSelected])

  const additionalFormData = {
    publish_date: firebase.firestore.Timestamp.now(),
    image: image ? image : undefined,
    exercises: exercises,
  }

  const methods = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  async function deleteWorkoutFromFirebase() {
    try {
      await deleteWorkoutFromFireStore(data?.firebase_id)
      dispatch(closeAlert())
      toast.success('Workout successfully deleted.')
      history.push(`/workouts`)
    } catch (error) {
      toast.error("Sorry we're unable to delete the workout, please try again.")
      dispatch(closeAlert())
    }
  }

  const deleteData = {
    title: 'Are you sure?',
    description:
      'Are you sure you want to delete this workout? this is a destructive action and once delete this workout cannot be recovered.',
    onClick: () => {
      deleteWorkoutFromFirebase()
    },
  }

  async function submit(workout) {
    //console.log('additionalFormData', additionalFormData)
    const plans = workout.plans.reduce((obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }), {})
    const plans_id = workout.plans.reduce((obj, cur) => ({ ...obj, [cur.firebase_id]: true }), {})

    const alternative_workouts = workout.alternative_workouts.reduce(
      (obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }),
      {}
    )
    const alternative_workouts_id = workout.alternative_workouts.reduce(
      (obj, cur) => ({ ...obj, [cur.firebase_id]: true }),
      {}
    )

    let tags
    if (workout.workout_tags) {
      tags = workout.workout_tags.reduce((obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }), {})
    }

    const description = Object.assign({ description: workout.description, title: workout.subtitle })

    const formattedData = {
      alternative_workouts,
      alternative_workouts_id,
      plans,
      plans_id,
      workout_title: workout.name,
      instructions: description,
      workout_tags: tags,
    }

    const merged = { ...data, ...workout, ...additionalFormData, ...formattedData }

    console.log('merged', merged)

    if(merged?.exercises?.length <= 0 && merged.status === 'Published'){
        toast.error("WORKOUT NOT SAVED: Please add exercises before publishing a workout.")
        return
    }

    // Store an array of exercise id's to use in query for cloud functions (when exercise is updated, update the workout that uses the exercise)
    if (merged.exercises) {
        merged.exercises_id = []
        merged.exercises.forEach((excercise) => {
        merged.exercises_id.push(excercise.firebase_id)
      })
    }

    console.log('merged', merged)
    removeEmptyFields(merged)

    if (data) {
      try {
        await updateWorkoutInFirestore(data?.firebase_id, merged)
        toast.success("Congratulations! we've successfully updated your workout.")
      } catch (error) {
        console.error(error, merged)
        toast.error("Sorry we're unable to save your workout, please try again.")
      }
    } else {
      try {
        await addWorkoutToFirestore(merged).then((docRef) => {
          history.push(`/workouts/${docRef.id}`)
        })
        toast.success("Congratulations! you've added a new workout.")
      } catch (error) {
        console.error(error, merged)
        toast.error("Sorry we're unable to save your workout, please try again.")
      }
    }
  }

  const clearImage = () => {
    setImage(null)
    setFiles([])
  }

  const statusOptions = ['Published', 'Pending', 'Draft']
  const workoutType = ['Home', 'Gym']

  const methodData = {
    title: 'Add New Method',
    description: 'Add new method, your method will be available from the method menu.',
    taxonomy: taxonomy,
  }

  const workoutTagData = {
    title: 'Add New Workout Tag',
    description: 'Add new workout tag, your workout tag will be available from the workout tag menu.',
    taxonomy: taxonomy,
  }

  const watchVideo = methods.watch('vimeo_video_id', data?.vimeo_video_id)

  return (
    <>
      <FormProvider {...methods}>
        <MuiForm className={classes.form} onSubmit={methods.handleSubmit(submit)}>
          <Grid container justify="space-between" spacing={isSmall ? 0 : 5}>
            <Grid item sm={12} md={8} xl={9} className={classes.formComponent}>
              <StyledGrid title="Workout Details">
                <Grid container>
                  <MuiInput
                    ref={methods.register}
                    id="title"
                    label="Workout Title"
                    name="title"
                    error={!!methods.errors.title}
                    helperText={methods.errors?.title?.message}
                    placeholder="Enter a title eg Bench Press..."
                  />
                  <MuiInput
                    ref={methods.register}
                    id="subtitle"
                    label="Workout Subtitle"
                    name="subtitle"
                    error={!!methods.errors.subtitle}
                    helperText={methods.errors?.subtitle?.message}
                    placeholder="Enter a subtitle eg Circuit Based Workout..."
                  />
                  <MuiInput
                    ref={methods.register}
                    name="description"
                    label="Description"
                    id="description"
                    multiline
                    rows={5}
                    error={!!methods.errors?.description}
                    helperText={methods.errors?.description?.message}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MuiAutoComplete
                        multiple={true}
                        control={methods.control}
                        inputLabel="Alternative Workouts"
                        name="alternative_workouts"
                        options={alternativeWorkoutOptions}
                        error={!!methods.errors?.alternative_workouts}
                        helperText={methods.errors?.alternative_workouts?.message}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MuiInput
                        ref={methods.register}
                        id="duration"
                        label="Workout Duration"
                        name="duration"
                        type="number"
                        step="1"
                        error={!!methods.errors.duration}
                        helperText={methods.errors?.duration?.message}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MuiInput
                        ref={methods.register}
                        id="laps"
                        label="Number of Laps"
                        name="laps"
                        type="number"
                        step="1"
                        error={!!methods.errors.laps}
                        helperText={methods.errors?.laps?.message}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </StyledGrid>
              <Exercises exercises={exercises?.length > 0 ? exercises : []} setExercises={setExercises} />
            </Grid>
            <Grid item xs={12} md={4} xl={3} className={classes.statusComponent}>
              <Status
                status={data?.status}
                date={data?.publish_date}
                onDelete={() => dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))}
              >
                <MuiSelect
                  control={methods.control}
                  name="status"
                  error={!!methods.errors.status}
                  options={statusOptions}
                  label="Status"
                />
                {/* {methods.errors?.status && <FormHelperText error>{methods.errors?.status?.message}</FormHelperText>} */}
              </Status>
              <Category title="Type">
                <MuiSelect
                  control={methods.control}
                  name="workout_type"
                  error={!!methods.errors.workout_type}
                  options={workoutType}
                  label="Workout Type"
                />
                {/* {methods.errors?.workout_type && (
                  <FormHelperText error>{methods.errors?.workout_type?.message}</FormHelperText>
                )} */}
              </Category>
              <Category title="Add to plan">
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Plan"
                  name="plans"
                  options={plans}
                  error={!!methods.errors?.plans}
                  helperText={methods.errors?.plans?.message}
                />
              </Category>
              <Category
                title="Workout Tags"
                buttonText="Add New Meal Tag"
                onClick={() => dispatch(openAlert({ alertType: 'WorkoutTagsAlert', alertProps: { workoutTagData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Workout Tags"
                  name="workout_tags"
                  options={taxonomy.workout_tags}
                  error={!!methods.errors?.workout_tags}
                  helperText={methods.errors?.workout_tags?.message}
                />
              </Category>
              <Category
                title="Method"
                buttonText="Add New method"
                onClick={() => dispatch(openAlert({ alertType: 'MethodAlert', alertProps: { methodData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Workout Method"
                  name="workout_method"
                  options={taxonomy.workout_method}
                  error={!!methods.errors?.workout_method}
                  helperText={methods.errors?.workout_method?.message}
                />
              </Category>

              <Category
                title="Video Details"
                buttonText="Manage videos on Vimeo"
                onClick={() => window.open('https://vimeo.com')}
              >
                <MuiInput
                  ref={methods.register}
                  name="vimeo_video_id"
                  label="Vimeo Video Id"
                  id="vimeo_video_id"
                  placeholder="Enter an ID eg 492658650..."
                  error={!!methods.errors.vimeo_video_id}
                  helperText={methods.errors?.vimeo_video_id?.message}
                />
                {watchVideo && <VimeoVideo videoId={watchVideo} videoTitle={data?.workout_name} />}
              </Category>
              <ImageUploadWidget
                setFiles={setFiles}
                files={files}
                image={image}
                clearImage={clearImage}
                setImage={setImage}
              />
            </Grid>
          </Grid>
        </MuiForm>
      </FormProvider>
    </>
  )
}

export default WorkoutForm
