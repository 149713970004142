export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getFilterOptions(column, rows) {
  const options = new Set()
  rows.forEach((row) => {
    if (row.values[column] !== null && typeof row.values[column] !== 'undefined' && row.values[column] !== '') {
      options.add(row.values[column])
    }
  })
  return [...options.values()]
}

export function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
}

export function findSelected(array, value, key) {
  const findSelected = []
  array.forEach((item) => {
    const itemKey = key
    const match = { ...item, label: item[itemKey].toLowerCase() }
    if (value[item.firebase_id] === true) {
      findSelected.push(match)
    }
  })
  return findSelected
}

export function removeEmptyFields(data) {
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      removeEmptyFields(data[key])
    }
    if (data[key] === null || (data[key] !== undefined && data[key].length === 0)) {
      delete data[key]
    }
    if (data[key] !== undefined && data[key].constructor === Object) {
      // loop the object
      if (Object.keys(data[key]).length === 0) {
        delete data[key]
      } else {
        for (const [index] of Object.entries(data[key])) {
          if (data[key][index] === '' || data[key][index] === null || data[key][index] === undefined) {
            delete data[key][index]
          }
        }
      }
    } else {
      if (data[key] === '' || data[key] === null || data[key] === undefined) {
        delete data[key]
      }
    }
  })
}
