import { Container, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ListIcon from '@material-ui/icons/List'
import TitleBar from '../../../components/Titlebar'
import Navigation from '../../../components/layout/navigation/Navigation'
import ExerciseForm from '../components/forms/ExerciseForm'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import ExerciseDetailSkeleton from '../components/skeletonLoaders/ExerciseDetailSkeleton'

function CreateExercise() {
  const [isLoading, setIsLoading] = useState(true)

  const transformExercises = (data) => {
    return { ...data, label: data.title.toLowerCase() }
  }
  const [exercises, exercisesLoading, exercisesError] = useCollectionData(
    db.collection('exercises').orderBy('title', 'asc'),
    {
      idField: 'firebase_id',
      transform: transformExercises,
    }
  )

  const transformTaxonomy = (data) => {
    const muscleGroups = []
    const equipment = []
    if (data.muscle_groups) {
      Object.keys(data.muscle_groups).forEach((key, index) => {
        muscleGroups.push({
          label: key.split('_').join(' '),
        })
      })
    }

    if (data.equipment) {
      Object.keys(data.equipment).forEach((key, index) => {
        equipment.push({
          label: key.split('_').join(' '),
        })
      })
    }
    return { ...data, originalTaxonomies: data, muscle_groups: muscleGroups, equipment: equipment }
  }
  const [taxonomy, taxonomyLoading, taxonomyError] = useDocumentData(db.collection('taxonomy').doc('exercise'), {
    transform: transformTaxonomy,
  })

  useEffect(() => {
    if (!exercisesLoading && !taxonomyLoading) {
      setIsLoading(false)
    }
  }, [exercisesLoading, taxonomyLoading])

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Create Exercise"
        caption="Add a new exercise, make sure you include muscle groups and equipment…"
        buttonTitle="Add New Exercise"
      />
      <Container maxWidth="xl">
        {taxonomyError || exercisesError ? <Typography>No firebase connection</Typography> : null}
        {isLoading ? (
          <ExerciseDetailSkeleton />
        ) : (
          <ExerciseForm alternativeExerciseOptions={exercises} taxonomy={taxonomy} />
        )}
      </Container>
    </Navigation>
  )
}

export default CreateExercise
