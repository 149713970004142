import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import React from 'react'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiInput } from '../../../../components/form/MuiInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AlertDialogWrapper from '../../../../components/alert/AlertDialogWrapper'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { closeAlert } from '../../../../components/alert/AlertReducer'
import { MuiAutoComplete } from '../../../../components/form/MuiAutoComplete'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db } from '../../../../api/firebase'
import update from 'immutability-helper'

export const Schema = yup.object().shape({
  title: yup.object().typeError('Choose an Exercise.').required('Choose an Exercise.'),
  reps: yup.number().typeError('You must specify a number, please use 0 if no value.'),
  sets: yup.number().typeError('You must specify a number, please use 0 if no value.'),
  tempo: yup.string(),
  rest: yup.string(),
  duration: yup.string(),
  method: yup.string(),
})

function WorkoutExerciseAlert({ addExerciseData, upDateExercise, row }) {
  const dispatch = useDispatch()

  const transformExercise = (data) => {
    const label = data.title + ' - ' + data.type
    return { ...data, label }
  }
  const [exercises, exercisesLoading, exercisesError] = useCollectionData(
    db.collection('exercises').where('status', '==', 'Published'),
    {
      //must be id NOT firebase_id as the repeater requires an id field
      idField: 'id',
      transform: transformExercise,
    }
  )

  const initialValues = {
    title: row ? { label: row?.original?.label } : null,
    reps: row?.original?.reps,
    sets: row?.original?.sets,
    tempo: row?.original?.tempo,
    rest: row?.original?.rest,
    duration: row?.original?.duration,
    method: row?.original?.method,
  }

  const { handleSubmit, errors, register, reset, control } = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  const onSubmit = (newExercise) => {
      console.log(`update exrcise`,upDateExercise);
      console.log(`new exercise`,newExercise);
    if (upDateExercise) {
      upDateExercise.setExercises(
        update(upDateExercise.exercises, {
          [row.index]: {
            $set: {
              ...upDateExercise.exercises[row.index],
              reps: newExercise.reps,
              sets: newExercise.sets,
              tempo: newExercise.tempo,
              rest: newExercise.rest,
              duration: newExercise.duration,
              method: newExercise.method,
            },
          },
        })
      )
      dispatch(closeAlert())
    } else {
      const newArray = [...addExerciseData.exercises]

      newArray.push({
        ...newExercise.title,
        reps: newExercise.reps,
        sets: newExercise.sets,
        tempo: newExercise.tempo,
        rest: newExercise.rest,
        duration: newExercise.duration,
        method: newExercise.method,
        order: newArray.length + 1,
      })
      addExerciseData.setExercises(newArray)
      reset()
      dispatch(closeAlert())
    }
  }

  if (exercisesLoading) {
    return <p>Loading</p>
  }

  return (
    <AlertDialogWrapper>
      <DialogTitle id="alert-dialog-slide-title">
        {upDateExercise ? upDateExercise?.title : addExerciseData?.title}
      </DialogTitle>
      <MuiForm onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {exercisesError
              ? 'Unable to connect to firebase'
              : upDateExercise
              ? upDateExercise?.title
              : upDateExercise?.description}
          </DialogContentText>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <MuiAutoComplete
                multiple={false}
                id="title"
                label="Exercise Title"
                inputLabel="Exercise"
                name="title"
                error={!!errors.title}
                helperText={errors?.title?.message}
                options={exercises}
                control={control}
                disabled={upDateExercise ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiInput
                ref={register}
                id="reps"
                type="number"
                label="Reps"
                name="reps"
                error={!!errors.reps}
                helperText={errors?.reps?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiInput
                ref={register}
                id="sets"
                type="number"
                label="Sets"
                name="sets"
                error={!!errors.sets}
                helperText={errors?.sets?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiInput
                ref={register}
                id="tempo"
                label="Tempo"
                name="tempo"
                error={!!errors.tempo}
                helperText={errors?.tempo?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiInput
                ref={register}
                id="rest"
                label="Rest"
                name="rest"
                error={!!errors.rest}
                helperText={errors?.rest?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiInput
                ref={register}
                id="duration"
                label="Duration"
                name="duration"
                error={!!errors.duration}
                helperText={errors?.duration?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiInput
                ref={register}
                id="method"
                label="Method"
                name="method"
                error={!!errors.duration}
                helperText={errors?.duration?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => dispatch(closeAlert())}>
            Cancel
          </Button>
          <Button type="submit" variant="text">
            {upDateExercise ? 'Update Exercise' : 'Add Exercise'}
          </Button>
        </DialogActions>
      </MuiForm>
    </AlertDialogWrapper>
  )
}

export default WorkoutExerciseAlert
