import React from 'react'

import { useDispatch } from 'react-redux'
import RepeaterList from '../../../../components/layout/table/ReorderTable'
import StyledGrid from '../../../../components/layout/Grid'
import { openAlert } from '../../../../components/alert/AlertReducer'
import { DeleteButton, EditButtons } from '../../../../components/layout/table/TableButtons'
import TableOrder from '../../../../components/layout/table/TableOrder'

export default function ExtraIngredients({ ingredients, setIngredients }) {
  const dispatch = useDispatch()

  const columns = [
    {
      Header: 'Order',
      accessor: 'order',
      Cell: ({ row }) => <TableOrder order={row.values.order} />,
    },
    {
      Header: 'Ingredient',
      accessor: 'title',
    },
    {
      Header: 'Amount',
      accessor: 'used_amount',
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <>
          <EditButtons onClick={() => dispatch(openAlert({ alertType: 'ExtraIngredientAlert', alertProps: { upDateIngredient, row} }))} />
          <DeleteButton onClick={() => setIngredients([...ingredients.filter((ing) => ing.id !== row.original.id)] )} />
        </>
      ),
    },
  ]

  const upDateIngredient = {
    title: 'Update extra ingredient.',
    description: 'Update extra ingredient, these ingredients don\'t count towards the daily calories. E.g salt, pepper, garlic etc',
    ingredients: ingredients, 
    setIngredients: setIngredients,
  }

  const addIngredientData = {
    title: 'Add an extra ingredient.',
    description: 'Use this ingredient type to add ingredients that don\'t count towards the daily calories. E.g salt, pepper, garlic etc',
    ingredients: ingredients, 
    setIngredients: setIngredients
  }

  return (
    <StyledGrid title="Extra Ingredients">
      <RepeaterList
        columns={columns}
        records={ingredients}
        setRecords={setIngredients}
        buttonClick={() => dispatch(openAlert({ alertType: 'ExtraIngredientAlert', alertProps: { addIngredientData } }))}
        buttonTitle="Add Ingredient"
      ></RepeaterList>
    </StyledGrid>
  )
}
