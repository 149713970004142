import React from 'react'
import TitleBar from '../../components/Titlebar'
import GroupIcon from '@material-ui/icons/Group'
import Navigation from '../../components/layout/navigation/Navigation'

function Users(props) {
  return (
    <Navigation>
      <TitleBar
        icon={<GroupIcon fontSize="large" />}
        title="Users"
        caption="Manage your exercises, you can add, edit  and delete exercises…"
      />
    </Navigation>
  )
}

export default Users
