import React from 'react'
import { useSelector } from 'react-redux'
import EquipmentAlert from '../../features/exercises/components/alerts/EquipmentAlert'
import MuscleGroupAlert from '../../features/exercises/components/alerts/MuscleGrouAlert'
import AllergenAlert from '../../features/meals/components/alerts/AllergenAlert'
import ExtraIngredientAlert from '../../features/meals/components/alerts/ExtraIngredientAlert'
import MainIngredientAlert from '../../features/meals/components/alerts/MainIngredientAlert'
import WorkoutExerciseAlert from '../../features/workouts/components/alerts/WorkoutExerciseAlert'
import MealTypeAlert from '../../features/meals/components/alerts/MealTypeAlert.js'
import DeleteAlert from './DeleteAlert'
import MethodAlert from '../../features/workouts/components/alerts/MethodAlert'
import PlanWeekAlert from '../../features/plans/components/alerts/PlanWeekAlert'
import TopicAlert from '../../features/classes/components/alerts/TopicAlert'
import TrainerAlert from '../../features/classes/components/alerts/TrainerAlert'
import ClassEquipmentAlert from '../../features/classes/components/alerts/ClassEquipmentAlert'
import MealTagsAlert from '../../features/meals/components/alerts/MealTagsAlert'
import WorkoutTagsAlert from '../../features/workouts/components/alerts/WorkoutTagsAlert'

function AlertManager() {
  const alertLookup = {
    AllergenAlert,
    DeleteAlert,
    MainIngredientAlert,
    ExtraIngredientAlert ,
    MealTypeAlert,
    EquipmentAlert,
    MuscleGroupAlert,
    WorkoutExerciseAlert,
    MethodAlert,
    PlanWeekAlert,
    TopicAlert,
    TrainerAlert,
    ClassEquipmentAlert,
    MealTagsAlert,
    WorkoutTagsAlert
  }
  const currentAlert = useSelector((state) => state.FP_ALERT)
  let renderedAlert
  if (currentAlert) {
    const { alertType, alertProps } = currentAlert
    const AlertComponent = alertLookup[alertType]
    renderedAlert = <AlertComponent {...alertProps} />
  }

  return <>{renderedAlert}</>
}

export default AlertManager
