import React, { useEffect, useState, useMemo } from 'react'
import {
  Checkbox,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table'
import { TableSearch } from './TableSearch'
import { TablePagination } from './TablePagination'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 1fr)',
    borderRadius: 20,
    overflowX: 'hidden',
    border: `1px solid ${theme.palette.borders.main}`,
    marginBottom: theme.spacing(7),
    paddingBottom: theme.spacing(4),
  },
  menuContainer: {
    minWidth: '250px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  tableContainer: {
    overflowX: 'auto',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  results: {
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 1200,
    margin: '0 auto',
    borderCollapse: 'separate',
    borderSpacing: '0 15px',
    '& th': {
      border: 'none',
      textTransform: 'uppercase',
      paddingBottom: '5px',
    },
    '& td': {
      borderTop: `1px solid ${theme.palette.borders.main}`,
      borderBottom: `1px solid ${theme.palette.borders.main}`,
      minHeight: '75px',
      textTransform: 'capitalize',
    },
    '& td:first-child': {
      border: `1px solid ${theme.palette.borders.main}`,
      borderRight: 'none',
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
    },
    '& td:last-child': {
      border: `1px solid ${theme.palette.borders.main}`,
      borderLeft: 'none',
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
    },
  },
}))

function DocumentTable({ columns, data, isLoading, placeHolderText }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const tableData = useMemo(() => (isLoading ? Array(10).fill({}) : data), [isLoading, data])
  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton variant="text" />,
          }))
        : columns,
    [isLoading, columns]
  )

  const [pageNumber, setPageNumber] = useState(null)

  const classes = useStyles()

  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <Checkbox color="primary" ref={resolvedRef} {...rest} />
  })

  const {
    getTableProps,
    getTableBodyProps,
    getToggleHideAllColumnsProps,
    allColumns,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    prepareRow,
    setPageSize,
    state,
    rows,
    setFilter,
    preFilteredRows,
    setGlobalFilter,
    preGlobalFilteredRows,
    setAllFilters,
  } = useTable({ columns: tableColumns, data: tableData }, useFilters, useGlobalFilter, usePagination)

  const { globalFilter, pageSize, pageIndex } = state

  useEffect(() => {
    setPageNumber(pageIndex + 1)
  }, [pageIndex])

  return (
    <Grid component={Paper} elevation={3} className={classes.grid}>
      <div>
        <div>
          <Button
            variant="text"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            Customize Table Headers
          </Button>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <div className={classes.menuContainer}>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
            All
          </div>
          {allColumns.map((column) => (
            <div key={column.id} className={classes.menuContainer}>
              <label>
                <Checkbox color="primary" {...column.getToggleHiddenProps()} /> {column.Header}
              </label>
            </div>
          ))}
        </Menu>
      </div>
      <TableSearch
        headerGroups={headerGroups}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pageSize={pageSize}
        setPageSize={setPageSize}
        preFilteredRows={preFilteredRows}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setFilter={setFilter}
        setAllFilters={setAllFilters}
        placeHolderText={placeHolderText}
      />
      <Divider />
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <div className={classes.tableContainer}>
        <Table {...getTableProps()} className={classes.table}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <Grid container item justify="flex-end" alignItems="center" className={classes.results}>
        <Typography variant="h5">Total Results: {rows.length}</Typography>
      </Grid>
      <TablePagination
        previous={() => {
          previousPage()
          setPageNumber(pageNumber - 1)
        }}
        previousDisabled={!canPreviousPage}
        first={() => gotoPage(0)}
        last={() => gotoPage(pageCount - 1)}
        next={() => {
          setPageNumber(pageNumber + 1)
          nextPage()
        }}
        nextDisabled={!canNextPage}
        pageNumber={pageIndex + 1}
        onChange={(e) => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          gotoPage(page)
        }}
        totalPages={pageCount + ' '}
        currentPage={pageIndex + 1}
      />
    </Grid>
  )
}

export default DocumentTable
