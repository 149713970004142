import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import StyledGrid from '../layout/Grid'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(3),
    '& > span': {
      color: theme.palette.error.main,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.common.black,
      },
    },
  },
  dateLabel: {
    fontSize: theme.spacing(2),
    color: theme.palette.borders.main,
  },
  date: {
      marginTop: theme.spacing(1)
  }
}))

function Status({ status, children, date, onDelete }) {
  const classes = useStyles()

  return (
    <StyledGrid title="Status">
      {children}
      <Typography variant="h4" className={classes.date}>
        <span className={classes.dateLabel}>
          {status === 'Published'
            ? 'Published on'
            : status === 'Draft'
            ? 'Drafted on'
            : status === 'Pending'
            ? 'Pending on'
            : null}
        </span>{' '}
        {date && date.toDate().toDateString() + ', ' + date.toDate().toLocaleTimeString()}
      </Typography>
      <Grid container justify="space-between" alignItems="flex-end" className={classes.buttonContainer}>
        {status && <span onClick={onDelete}>Permanently Delete</span> }
        <Button variant="contained" type="submit">Update</Button>
      </Grid>
    </StyledGrid>
  )
}

export default Status
