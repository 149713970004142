import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Copyright from '../../components/Copyright'
import { signInWithEmail } from '../../api/FirebaseService'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Schema = yup.object().shape({
  email: yup.string().email('Invalid email format.').required(),
  password: yup.string().required('No password provided.').min(8, 'Password is too short - should be 8 chars minimum.'),
})

export default function Login(props) {
  const classes = useStyles()

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(Schema),
  })

  // const register = (e) => {
  //   e.preventDefault()
  //   console.log('email', emailRef.current.value)
  //   console.log('email', passwordRef.current.value)

  //   auth
  //     .createUserWithEmailAndPassword(emailRef.current.value, passwordRef.current.value)
  //     .then((authUser) => {
  //       console.log(authUser)
  //     })
  //     .catch((error) => {
  //       //console.log(error.message)
  //     })
  // }

  const signIn = async (values) => {
    try {
      await signInWithEmail(values)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(signIn)}>
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={errors.email ? true : false}
            helperText={errors.email ? errors.email.message : null}
          />
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={errors.password ? true : false}
            helperText={errors.password ? errors.password.message : null}
          />
          <Button variant="contained" type="submit" fullWidth className={classes.submit}>
            Sign In
          </Button>
          <Link to="/reset-password">Forgot Password ?</Link>
          {/* <Button type="submit" fullWidth className={classes.submit} onClick={register}>
            Sign up
          </Button> */}
        </form>
      </div>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Container>
  )
}
