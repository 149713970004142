import { Container, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Navigation from '../../../components/layout/navigation/Navigation'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import MealDetailForm from '../components/forms/MealDetailForm'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
//import update from 'immutability-helper'
import MealDetailSkeleton from '../components/skeletonLoaders/MealDetailSkeleton'
import { findSelected } from '../../../util/util'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

function MealDetail({ match, history }) {
  const [isLoading, setIsLoading] = useState(true)

  // Format meal data
  const transformMeal = (data) => {
    const ingredients = []
    const extraIngredients = []
    const dietary_types = []
    const meal_types = []
    const meal_tags = []
    // Get the object keys as an array to foreach
    if (data.ingredients) {
      Object.keys(data.ingredients).forEach((key, index) => {
        ingredients.push({
          id: key, // firebase_id
          order: index + 1, // default order
          ...data.ingredients[key],
          // Because we ... after, it should override order if it is set in firebase
        })
      })
    }

    if (data.extra_ingredients) {
      Object.keys(data.extra_ingredients).forEach((key, index) => {
        extraIngredients.push({
          id: uuidv4(), // firebase_id
          order: index + 1, // default order
          title: data.extra_ingredients[key].name,
          ...data.extra_ingredients[key],
        })
      })
    }
    if (data.dietary_types) {
      Object.keys(data.dietary_types).forEach((key, index) => {
        dietary_types.push({
          label: key,
          ...data.dietary_types,
        })
      })
    }

    if (data.meal_types) {
      Object.keys(data.meal_types).forEach((key, index) => {
        meal_types.push({
          label: key,
          ...data.meal_types,
        })
      })
    }

    if (data.meal_tags) {
      Object.keys(data.meal_tags).forEach((key, index) => {
        meal_tags.push({
          label: key.split('_').join(' '),
          ...data.meal_tags,
        })
      })
    }

    return {
      ...data,
      ingredients: ingredients,
      extra_ingredients: extraIngredients,
      dietary_types: dietary_types,
      meal_types: meal_types,
      meal_tags: meal_tags,
    }
  }

  //Get the meal from firebase
  const [meal, mealLoading, error] = useDocumentData(db.collection('meals').doc(match.params.id), {
    transform: transformMeal,
    idField: 'firebase_id',
  })

  // Get plans from firebase
  const transformPlans = (data) => {
    let label
    if (!data.start_date) {
      label = data.title
    } else {
      label = data.title + ' ' + moment(data.start_date.toDate()).format('MMMM YYYY')
    }

    return {
      title: data.title,
      label: label.toLowerCase(),
      firebase_id: data.firebase_id,
    }
  }
  const [plans, plansLoading] = useCollectionData(db.collection('plans').orderBy('title', 'asc'), {
    idField: 'firebase_id',
    transform: transformPlans,
  })

  //Get dietary types from firebase
  const transformDietaryTypes = (data) => {
    return { ...data, label: data.name.toLowerCase() }
  }
  const [dietaryTypes, dietaryTypesLoading] = useCollectionData(db.collection('dietary_types').orderBy('name', 'asc'), {
    idField: 'firebase_id',
    transform: transformDietaryTypes,
  })

  //Get all meal types from firebase
  const transformMealTypes = (data) => {
    return { ...data, label: data.name.toLowerCase() }
  }
  const [mealTypes, mealTypesLoading] = useCollectionData(db.collection('meal_types').orderBy('name', 'asc'), {
    idField: 'firebase_id',
    transform: transformMealTypes,
  })

  // get all meal tags from firebase
  const transformTaxonomy = (data) => {
    const tags = []
    if (data.tags) {
      Object.keys(data.tags).forEach((key, index) => {
        tags.push({
          label: key.split('_').join(' '),
        })
      })

      tags.sort((a, b) => Number(a.label.match(/(\d+)/g)[0]) - Number(b.label.match(/(\d+)/g)[0]))
    }
    return { ...data, originalTaxonomies: data, tags: tags }
  }
  const [taxonomy, taxonomyLoading] = useDocumentData(db.collection('taxonomy').doc('meal'), {
    transform: transformTaxonomy,
  })

  const transformAllergens = (data) => {
    return { ...data, label: data.name.toLowerCase() }
  }
  //Get all allergens
  const [allergens, allergenLoading] = useCollectionData(db.collection('allergens').orderBy('name', 'asc'), {
    idField: 'firebase_id',
    transform: transformAllergens,
  })

  useEffect(() => {
    if (
      !mealLoading &&
      !allergenLoading &&
      !plansLoading &&
      !mealTypesLoading &&
      !taxonomyLoading &&
      !dietaryTypesLoading
    ) {
      setIsLoading(false)
    }
  }, [allergenLoading, dietaryTypesLoading, mealLoading, mealTypesLoading, plansLoading, taxonomyLoading])

  //Redirect if error
  //TODO add error component and error route
  if (!mealLoading && meal === undefined) {
    history.push('/meals')
  }

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Meal"
        caption="Manage your Meals, you can add edit delete, don’t forget to select meal type and dietary requirement…"
        buttonTitle="Add New Meal"
        onClick={() => history.push('/meals/create-meal')}
      />
      <Container maxWidth="xl">
        {error && <Typography variant="h1">No Firebase Connection </Typography>}
        {isLoading && <MealDetailSkeleton />}
        {!isLoading && !error && meal && (
          <MealDetailForm
            data={meal}
            dietaryTypes={dietaryTypes}
            dietaryTypesSelected={
              dietaryTypes && meal.dietary_types_id ? findSelected(dietaryTypes, meal.dietary_types_id, 'name') : []
            }
            mealTypes={mealTypes}
            taxonomy={taxonomy}
            mealTypesSelected={
              mealTypes && meal.meal_types_id ? findSelected(mealTypes, meal.meal_types_id, 'name') : []
            }
            allergens={allergens}
            allergensSelected={allergens && meal.allergens_id ? findSelected(allergens, meal.allergens_id, 'name') : []}
            plans={plans}
            plansSelected={plans && meal.plans_id ? findSelected(plans, meal.plans_id, 'label') : []}
          />
        )}
      </Container>
    </Navigation>
  )
}

export default MealDetail
