import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Popover,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import React, { useState } from 'react'
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded'
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'
import { GlobalFilter } from './GlobalFilter'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  searchIcon: {
    position: 'relative',
    top: '32px',
  },
  settingsIcon: {
    position: 'relative',
    top: '3px',
    right: '25px',
    border: '1px solid',
    borderRadius: '50%',
    padding: '3px',
    height: '1.4em',
    width: '1.4em',
  },
  formControlResults: {
    padding: '25px',
  },
  formControlFilters: {
    width: '250px',
    marginTop: '20px',
    marginBottom: '10px',
  },
  filtersContainer: {
    padding: '25px',
    paddingTop: '10px',
    paddingBottom: '15px',
  },
  SearchTextField: {
    [theme.breakpoints.down(780)]: {
      width: '250px',
    },
    '& label.Mui-focused': {
      paddingTop: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },
  SettingsButton: {
    padding: '5px',
    fontSize: '1.2rem',
    marginRight: '10px',
    borderRadius: '50',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}`,
      color: 'white',
      border: `2px solid ${theme.palette.primary.main}`,
      '& .MuiSvgIcon-root.MuiSvgIcon-colorPrimary': {
        color: 'white',
      },
    },
  },
}))

export const TableSearch = ({
  headerGroups,
  globalFilter,
  setGlobalFilter,
  pageSize,
  setPageSize,
  preFilteredRows,
  preGlobalFilteredRows,
  setFilter,
  setAllFilters,
  placeHolderText,
}) => {
  const classes = useStyles()

  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const [anchorElResults, setAnchorElResults] = useState(null)

  const handleResults = (event) => {
    setAnchorElResults(event.currentTarget)
  }

  const handleFilters = (event) => {
    setAnchorElFilter(event.currentTarget)
  }

  const handleCloseFilters = () => {
    setAnchorElFilter(null)
  }

  const handleCloseResults = () => {
    setAnchorElResults(null)
  }

  const clearFilters = () => {
    setAllFilters([])
    handleCloseFilters()
  }

  const FilterOpen = Boolean(anchorElFilter)
  const FilterId = FilterOpen ? 'filter-popover' : undefined

  const Resultsopen = Boolean(anchorElResults)
  const ResultsId = Resultsopen ? 'results-popover' : undefined
  return (
    <Grid container justify="space-between" alignItems="center" className={classes.container}>
      <Grid item style={{flexGrow: 1}}>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeHolderText={placeHolderText}
        />
      </Grid>
      <Hidden xsDown>
        <Grid item>
          <IconButton className={classes.SettingsButton} aria-label="first" size="medium" onClick={handleFilters}>
            <FilterListRoundedIcon size="medium" color="primary" />
          </IconButton>
          <Popover
            id={FilterId}
            open={FilterOpen}
            anchorEl={anchorElFilter}
            onClose={handleCloseFilters}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Grid container direction="column" className={classes.filtersContainer}>
              {headerGroups.map((headerGroup) =>
                headerGroup.headers.map((column) => {
                  return (
                    <div key={column.id}>
                      {column.canFilter && !column.disablefilters ? column.render('Filter') : null}
                    </div>
                  )
                })
              )}
              <Button variant="contained" color="secondary" onClick={() => clearFilters()}>
                Clear Filters
              </Button>
            </Grid>
          </Popover>
          <IconButton className={classes.SettingsButton} aria-label="first" size="medium" onClick={handleResults}>
            <SettingsRoundedIcon size="medium" color="primary" />
          </IconButton>
          <Popover
            id={ResultsId}
            open={Resultsopen}
            anchorEl={anchorElResults}
            onClose={handleCloseResults}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <FormControl component="fieldset" error={null} className={classes.formControlResults}>
              <RadioGroup
                aria-label="results-per-page"
                name="results-per-page"
                value={pageSize.toString()}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                  handleCloseResults()
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize, i) => (
                  <FormControlLabel
                    key={i}
                    value={pageSize.toString()}
                    control={<Radio />}
                    label={`${pageSize} Results per page`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Popover>
        </Grid>
      </Hidden>
    </Grid>
  )
}
