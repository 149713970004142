import { FormHelperText, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import Status from '../../../../components/status/Status'
import StyledGrid from '../../../../components/layout/Grid'
import { MuiInput } from '../../../../components/form/MuiInput'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiSelect } from '../../../../components/form/MuiSelect'
import {
  addExerciseToFirestore,
  deleteExerciseFromFireStore,
  updateExerciseInFirestore,
} from '../../../../api/FirestoreServices'
import { asyncActionStart, asyncActionFinish } from '../../../async/asyncReducer'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import firebase from 'firebase/app'
import { useHistory } from 'react-router'
import { closeAlert, openAlert } from '../../../../components/alert/AlertReducer'
import ImageUploadWidget from '../../../../components/Image/ImageUploadWidget'
import { MuiAutoComplete } from '../../../../components/form/MuiAutoComplete'
import Category from '../../../../components/category/category'
import { Schema } from '../../schema/ExerciseSchema'
import VimeoVideo from '../../../../components/VimeoVideo'
import { removeEmptyFields } from '../../../../util/util'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  statusComponent: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
  formComponent: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
}))

function ExerciseForm({
  data,
  alternativeExerciseOptions,
  alternativeExerciseSelected,
  taxonomy,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch() 

  const [files, setFiles] = useState([])
  const [image, setImage] = useState(data?.image)

  const history = useHistory()

  const initialValues = useMemo(() => {
    return {
      title: data?.title || '',
      trainer: data?.trainer || '',
      type: data?.type || '',
      description: data?.description || '',
      vimeo_video_id: data?.vimeo_video_id || '',
      alternative_exercise: alternativeExerciseSelected || [],
      muscle_groups: data?.muscle_groups || [],
      used_equipment: data?.used_equipment || [],
      status: data?.status || '',
    }
  }, [data, alternativeExerciseSelected])

  const additionalFormData = {
    publish_date: firebase.firestore.Timestamp.now(),
    image: image ? image : undefined,
  }

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const methods = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  async function deleteExerciseFromFirebase() {
    try {
      await deleteExerciseFromFireStore(data?.firebase_id)
      dispatch(closeAlert())
      toast.success('Exercise successfully deleted.')
      history.push(`/exercises`)
    } catch (error) {
      toast.error("Sorry we're unable to delete the exercise, please try again.")
      dispatch(closeAlert())
    }
  }

  const deleteData = {
    title: 'Are you sure?',
    description:
      'Are you sure you want to delete this exercise? this is a destructive action and once delete this exercise cannot be recovered.',
    onClick: () => {
      deleteExerciseFromFirebase()
    },
  }

  async function submit(exercise) {

    const words = exercise.title.split(' ')

    const capitalized = words.map(function(word) {
        const lowercaseWord = word.toLowerCase()
        return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.substring(1, lowercaseWord.length);
    });

    exercise.title = capitalized.join(' ')

    const alternative_exercise = exercise.alternative_exercise.reduce(
      (obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }),
      {}
    )
    const alternative_exercise_id = exercise.alternative_exercise.reduce(
      (obj, cur) => ({ ...obj, [cur.firebase_id]: true }),
      {}
    )

    const formattedData = {
      alternative_exercise,
      alternative_exercise_id,
    }

    const merged = { ...data, ...exercise, ...additionalFormData, ...formattedData }

    removeEmptyFields(merged)
    
    if (data) {
      dispatch(asyncActionStart())
      try {
        await updateExerciseInFirestore(data.firebase_id, merged)
        toast.success("Congratulations! we've successfully updated your exercise.")
        setTimeout(() => {
          dispatch(asyncActionFinish())
        }, 2000)
      } catch (error) {
        toast.error("Sorry we're unable to save your exercise, please try again.")
        setTimeout(() => {
          dispatch(asyncActionFinish())
        }, 2000)
      }
    } else {
      try {
        await addExerciseToFirestore(merged).then((docRef) => {
          history.push(`/exercises/${docRef.id}`)
        })
        toast.success("Congratulations! you've added a new exercise.")
      } catch (error) {
        toast.error("Sorry we're unable to save your exercise, please try again.")
      }
    }
  }

  const clearImage = () => {
    setImage(null)
    setFiles([])
  }

  const statusOptions = ['Published', 'Pending', 'Draft']

  const equipmentData = {
    title: 'Add New Equipment',
    description: 'Add new equipment, your equipment will be available from the equipment menu.',
    taxonomy: taxonomy,
  }

  const muscleGroupData = {
    title: 'Add New Muscle Group',
    description: 'Add new muscle group, your muscle group will be available from the muscle group menu.',
    taxonomy: taxonomy,
  }

  const watchVideo = methods.watch('vimeo_video_id', data?.vimeo_video_id)

  return (
    <>
      <FormProvider {...methods}>
        <MuiForm className={classes.form} onSubmit={methods.handleSubmit(submit)}>
          <Grid flexdirection="row" container justify="space-between" spacing={isSmall ? 0 : 5}>
            <Grid item sm={12} md={8} xl={9} className={classes.formComponent}>
              <StyledGrid title="Exercise Details">
                <Grid container>
                  <MuiInput
                    ref={methods.register}
                    id="title"
                    label="Exercise Title"
                    name="title"
                    error={!!methods.errors.title}
                    helperText={methods.errors?.title?.message}
                    placeholder="Enter a title eg Bench Press..."
                  />
                  <MuiInput
                    ref={methods.register}
                    name="description"
                    label="Description"
                    id="description"
                    multiline
                    rows={5}
                    error={!!methods.errors?.description}
                    helperText={methods.errors?.description?.message}
                  />
                  <MuiSelect
                    control={methods.control}
                    name="trainer"
                    error={!!methods.errors.trainer}
                    options={['Ryan', 'Jay', 'Dominika', 'Connor', 'Mac']}
                    label="Trainer"
                  />
                   
                  <MuiAutoComplete
                    multiple={true}
                    control={methods.control}
                    inputLabel="Alternative Exercise"
                    name="alternative_exercise"
                    options={alternativeExerciseOptions}
                    error={!!methods.errors?.alternative_exercise}
                    helperText={methods.errors?.alternative_exercise?.message}
                  />
                  <MuiSelect
                    control={methods.control}
                    name="type"
                    error={!!methods.errors.type}
                    options={['Body Weight', 'Weighted']}
                    label="Exercise Type"
                  />
                </Grid>
              </StyledGrid>
            </Grid>
            <Grid item xs={12} md={4} xl={3} className={classes.statusComponent}>
              <Status
                status={data?.status}
                date={data?.publish_date}
                onDelete={() => dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))}
              >
                <MuiSelect
                  control={methods.control}
                  name="status"
                  error={!!methods.errors.status}
                  options={statusOptions}
                  label="Status"
                />
                {methods.errors?.status && <FormHelperText error>{methods.errors?.status?.message}</FormHelperText>}
              </Status>
              <Category
                title="Muscle Groups"
                buttonText="Add New Muscle Group"
                onClick={() => dispatch(openAlert({ alertType: 'MuscleGroupAlert', alertProps: { muscleGroupData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Muscle Groups"
                  name="muscle_groups"
                  options={taxonomy.muscle_groups}
                />
              </Category>
              <Category
                title="Equipment"
                buttonText="Add New equipment"
                onClick={() => dispatch(openAlert({ alertType: 'EquipmentAlert', alertProps: { equipmentData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Muscle Groups"
                  name="used_equipment"
                  options={taxonomy.equipment}
                />
              </Category>
              <Category
                title="Video Details"
                buttonText="Manage videos on Vimeo"
                onClick={() => window.open('https://vimeo.com')}
              >
                <MuiInput
                  ref={methods.register}
                  name="vimeo_video_id"
                  label="Vimeo Video Id"
                  id="vimeo_video_id"
                  placeholder="Enter an ID eg 492658650..."
                  error={!!methods.errors.vimeo_video_id}
                  helperText={methods.errors?.vimeo_video_id?.message}
                />
                {watchVideo && <VimeoVideo videoId={watchVideo} videoTitle={data?.title ? data?.title : 'Vimeo Video'} />}
              </Category>
              <ImageUploadWidget
                setFiles={setFiles}
                files={files}
                image={image}
                clearImage={clearImage}
                setImage={setImage}
              />
            </Grid>
          </Grid>
        </MuiForm>
      </FormProvider>
    </>
  )
}

export default ExerciseForm
