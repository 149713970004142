import { Grid, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import StyledGrid from '../layout/Grid'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(3),
    '& > span': {
      color: theme.palette.error.main,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.common.black,
      },
    },
  },
  dateLabel: {
    fontSize: theme.spacing(2),
    color: theme.palette.borders.main,
  },
}))

function ImageUploadSkeleton() {
  const classes = useStyles()

  return (
    <StyledGrid title="Upload Image">
      <Skeleton variant="rect" width={'100%'} height={200} style={{ marginBottom: '15px' }} />
      <Grid container justify="flex-end" alignItems="flex-end" className={classes.buttonContainer}>
        <Skeleton variant="text" width={'50%'} style={{ marginTop: '5px' }} />
      </Grid>
    </StyledGrid>
  )
}

export default ImageUploadSkeleton
