import { Skeleton } from '@material-ui/lab'
import React from 'react'
import StyledGrid from '../../../../components/layout/Grid'

function ExerciseInfoFormSkeleton() {
  return (
    <StyledGrid title="Exercise Detail">
      <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: '15px' }} />
      <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: '15px' }} />
      <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: '15px' }} />
    </StyledGrid>
  )
}

export default ExerciseInfoFormSkeleton
