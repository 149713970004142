import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import StyledGrid from '../layout/Grid'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(3),
    '& > span': {
      color: theme.palette.error.secondary,
      paddingTop: '5px',
      paddingBottom: '5px',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

function Category({ children, title, buttonText, onClick }) {
  const classes = useStyles()

  return (
    <StyledGrid title={title}>
      {children}
      <Grid container justify="flex-end" className={classes.buttonContainer}>
        <span variant="text" onClick={onClick}>
          {buttonText}
        </span>
      </Grid>
    </StyledGrid>
  )
}

export default Category
