import { db } from './firebase'

//Ingredients
export function ListenToIngredientsFromFirestore() {
  return db.collection('ingredients')
}

export function ListenToIngredientFromFirestore(ingredientId) {
  return db.collection('ingredients').doc(ingredientId)
}

export function addIngredientToFirestore(ingredient) {
  return db.collection('ingredients').add({
    ...ingredient,
  })
}

export function updateIngredientToFirestore(firebaseId, ingredient) {
  return db.collection('ingredients').doc(firebaseId).set(ingredient)
}

export function deleteIngredientInFireStore(firebaseId) {
  return db.collection('ingredients').doc(firebaseId).delete()
}

//Meals
// export function ListenToMealsFromFirestore() {
//   return db.collection('meals')
// }

// export function ListenToMealFromFirestore(mealId) {
//   return db.collection('meals').doc(mealId)
// }

export function addMealToFirestore(meal) {
  return db.collection('meals').add({
    ...meal,
  })
}

export function updateMealToFirestore(firebaseId, meal) {
  return db.collection('meals').doc(firebaseId).set(meal)
}

export function deleteMealInFireStore(mealId) {
  return db.collection('meals').doc(mealId).delete()
}

export function addAllergenToFirestore(allergen) {
  return db.collection('allergens').add({
    ...allergen,
  })
}

export function addMealTypeToFirestore(mealTypes) {
  return db.collection('meal_types').add({
    ...mealTypes,
  })
}

//Exercises
export function addExerciseToFirestore(exercise) {
  return db.collection('exercises').add({
    ...exercise,
  })
}

export function deleteExerciseFromFireStore(exerciseId) {
  return db.collection('exercises').doc(exerciseId).delete()
}

export function updateExerciseInFirestore(exerciseId, exercise) {
  return db.collection('exercises').doc(exerciseId).set(exercise)
}

export function addEquipmentToFirestore(equipment) {
  return db.collection('equipment').add({
    ...equipment,
  })
}

//Workouts

export function deleteWorkoutFromFireStore(workoutId) {
  return db.collection('weeks_workouts').doc(workoutId).delete()
}

export function addWorkoutToFirestore(exercise) {
  return db.collection('weeks_workouts').add({
    ...exercise,
  })
}

export function updateWorkoutInFirestore(WorkoutId, workout) {
  return db.collection('weeks_workouts').doc(WorkoutId).set(workout)
}

export function addMethodToFirestore(method) {
  return db.collection('workout_methods').add({
    ...method,
  })
}

//plans
export function addPlanToFirestore(plan) {
  return db.collection('plans').add({
    ...plan,
  })
}

export function deletePlanInFireStore(planId) {
  return db.collection('plans').doc(planId).delete()
}

export function updatePlanInFirestore(PlanId, plan) {
  return db.collection('plans').doc(PlanId).set(plan)
}

//Plans weeks
export function addPlanWeekToFirestore(planWeek) {
  return db.collection('plan_weeks').add({
    ...planWeek,
  })
}

export function deletePlanWeekInFireStore(planWeekId) {
  return db.collection('plan_weeks').doc(planWeekId).delete()
}

export function updatePlanWeekInFirestore(PlanWeekId, planWeek) {
  return db.collection('plan_weeks').doc(PlanWeekId).set(planWeek)
}

//Classes

export function addVideoToFirestore(video) {
  return db.collection('videos').add({
    ...video,
  })
}

export function deleteVideoInFireStore(videoId) {
  return db.collection('videos').doc(videoId).delete()
}

export function updateVideoInFirestore(videoId, video) {
  return db.collection('videos').doc(videoId).set(video)
}

// Taxonomy
export function updateTaxonomyInFirestore(document, data) {
  return db.collection('taxonomy').doc(document).update(data)
}
