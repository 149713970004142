import { Skeleton } from '@material-ui/lab'
import React from 'react'
import StyledGrid from '../../../../components/layout/Grid'

function PlanInfoFormSkeleton() {
  return (
    <>
      <StyledGrid title="Plan Detail">
        <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: '15px' }} />
        <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: '15px' }} />
        <Skeleton variant="rect" width={'100%'} height={130} style={{ marginBottom: '15px' }} />
        <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: '15px' }} />
      </StyledGrid>
      <StyledGrid title="Plan Weeks">
        <Skeleton
          variant="rect"
          width={'100%'}
          height={450}
          style={{ marginBottom: '15px', marginTop: '10px', borderRadius: '15px' }}
        />
      </StyledGrid>
    </>
  )
}

export default PlanInfoFormSkeleton
