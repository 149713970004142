import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import React from 'react'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiInput } from '../../../../components/form/MuiInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AlertDialogWrapper from '../../../../components/alert/AlertDialogWrapper'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { closeAlert } from '../../../../components/alert/AlertReducer'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'

export const Schema = yup.object().shape({
  extra_ingredients: yup.string().required('Ingredient is a required field'),
  used_amount: yup.string().required('Amount is a required field'),
})

function ExtraIngredientAlert({ addIngredientData, upDateIngredient, row }) {
  const dispatch = useDispatch()

  const initialValues = {
    extra_ingredients: row?.original?.title,
    used_amount: row?.original?.used_amount,
  }

  const { handleSubmit, errors, register, reset } = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  const onSubmit = (newIngredient) => {
    if (upDateIngredient) {
      upDateIngredient.setIngredients(
        update(upDateIngredient.ingredients, {
          [row.index]: {
            $set: {
              ...row?.original,
              title: newIngredient.extra_ingredients,
              used_amount: newIngredient.used_amount,
            },
          },
        })
      )
      dispatch(closeAlert())
    } else {
      const newArray = [...addIngredientData.ingredients]
      newArray.push({
        id: uuidv4(),
        title: newIngredient.extra_ingredients,
        order: newArray.length + 1,
        used_amount: newIngredient.used_amount,
      })
      addIngredientData.setIngredients(newArray)
      reset()
      dispatch(closeAlert())
    }
  }

  return (
    <AlertDialogWrapper>
      <DialogTitle id="alert-dialog-slide-title">
        {upDateIngredient ? upDateIngredient?.title : addIngredientData?.title}
      </DialogTitle>
      <MuiForm onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {upDateIngredient ? upDateIngredient?.description : addIngredientData?.description}
          </DialogContentText>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={7}>
              <MuiInput
                ref={register}
                id="extra_ingredients"
                label="Extra Ingredient"
                name="extra_ingredients"
                placeholder="E.g Garlic"
                error={!!errors.ingredient}
                helperText={errors?.ingredient?.message}
              />
            </Grid>
            <Grid item xs={5}>
              <MuiInput
                ref={register}
                id="min_amount"
                label="amount"
                name="used_amount"
                placeholder="2 cloves"
                error={!!errors.used_amount}
                helperText={errors?.used_amount?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => dispatch(closeAlert())}>
            Cancel
          </Button>
          <Button type="submit" variant="text">
            {upDateIngredient ? 'Update Ingredient' : 'Add Ingredient'}
          </Button>
        </DialogActions>
      </MuiForm>
    </AlertDialogWrapper>
  )
}

export default ExtraIngredientAlert
