import React from 'react'
import TableOrder from '../../../../components/layout/table/TableOrder'
import { DeleteButton, EditButtons } from '../../../../components/layout/table/TableButtons'
import StyledGrid from '../../../../components/layout/Grid'
import RepeaterList from '../../../../components/layout/table/ReorderTable'
import { openAlert } from '../../../../components/alert/AlertReducer'
import { useDispatch } from 'react-redux'

export default function Exercises({ exercises, setExercises }) {
  const dispatch = useDispatch()

  const columns = [
    {
      Header: 'Order',
      accessor: 'order',
      Cell: ({ row }) => <TableOrder order={row.values.order} />,
    },
    {
      Header: 'Exercise',
      accessor: 'label',
    },
    {
      Header: 'Reps',
      accessor: 'reps',
    },
    {
      Header: 'Sets',
      accessor: 'sets',
    },
    {
      Header: 'Tempo',
      accessor: 'tempo',
    },
    {
      Header: 'Rest',
      accessor: 'rest',
    },
    {
      Header: 'Duration',
      accessor: 'duration',
    },
    {
      Header: 'Method',
      accessor: 'method',
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <>
        <EditButtons onClick={() => dispatch(openAlert({ alertType: 'WorkoutExerciseAlert', alertProps: { upDateExercise, row} }))} />
          <DeleteButton
            onClick={() => setExercises([...exercises.filter((exercise) => exercise.id !== row.original.id)])}
          />
        </>
      ),
    },
  ]

  const addExerciseData = {
    title: 'Add an Exercise.',
    description: 'Type to search for an exercise, any fields left blank will not display.',
    exercises: exercises,
    setExercises: setExercises,
  }

  const upDateExercise = {
    title: 'Update Exercise.',
    description: 'Please update the exercise below.',
    exercises: exercises,
    setExercises: setExercises,
  }

  return (
    <StyledGrid title="Workout Exercises">
      <RepeaterList
        columns={columns}
        records={exercises}
        setRecords={setExercises}
        buttonClick={() => dispatch(openAlert({ alertType: 'WorkoutExerciseAlert', alertProps: { addExerciseData } }))}
        buttonTitle="Add Exercise"
      ></RepeaterList>
    </StyledGrid>
  )
}
