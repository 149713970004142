import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'


function TableChip({label, color}) {

    const useStyles = makeStyles(({
        root: {
            backgroundColor: `${color}`,
            padding: '3px',
            textAlign: 'center',
            color: '#fff',
            minWidth: '100px',
            fontWeight: '600',
        },
    }))

    const classes = useStyles()

    return (
        <Typography className={classes.root}>
            {label}
        </Typography>
    )
}

export default TableChip
