import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles({
  paper: {
    textTransform: 'capitalize',
  },
})

export const MuiAutoComplete = ({ control, options, name, multiple, disabled, inputLabel, error, helperText, multiline, rows }) => {
  const classes = useStyles()

  return (
    <Controller
      render={(props) => {
        return (
          <Autocomplete
            multiple={multiple}
            {...props}
            style={{ marginTop: '16px', marginBottom: '8px', textTransform: 'capitalize', width: '100%' }}
            options={options}
            classes={{ paper: classes.paper }}
            disabled={disabled ? true : false}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, selectedValues) => option.label === selectedValues.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Choose ${inputLabel}`}
                variant="outlined"
                error={error}
                helperText={helperText}
                multiline={multiline}
                rows={rows}
              />
            )}
            onChange={(_, data) => {
              props.onChange(data)
            }}
          />
        )
      }}
      name={name}
      control={control}
    />
  )
}
