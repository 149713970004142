import { Button, Grid, Hidden, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down(780)]: {
      marginBottom: theme.spacing(3),
    },
  },
  gird: {
    minHeight: '120px',
    paddingLeft: theme.spacing(4),
    padding: theme.spacing(2),
  },
  titleBarIcon: {
    height: '70px',
    width: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(4),
  },
  addButton: {
    [theme.breakpoints.down(780)]: {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    borderRadius: '0px',
  },
}))

function TitleBar({ caption, icon, title, buttonTitle, onClick }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container alignItems="center" className={classes.gird}>
          <Hidden xsDown>
            <Grid item>
              <Paper className={classes.titleBarIcon} elevation={9}>
                {icon}
              </Paper>
            </Grid>
          </Hidden>
          <Grid item xs={12} md container>
            <Grid item md container direction="column">
              <Grid item>
                <Typography variant="h1">{title}</Typography>
                <Typography variant="caption">{caption}</Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.addButton}>
              {onClick && (
                <Button variant="contained" onClick={onClick} startIcon={<AddIcon />}>
                  <Typography variant="button">{buttonTitle}</Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default TitleBar
