import { Container } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Navigation from '../../../components/layout/navigation/Navigation'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import MealDetailForm from '../components/forms/MealDetailForm'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import MealDetailSkeleton from '../components/skeletonLoaders/MealDetailSkeleton'
import moment from 'moment'

function CreateMeal({ history }) {
  const [isLoading, setIsLoading] = useState(true)

  // Get plans from firebase
  const transformPlans = (data) => {
    let label
    if (!data.start_date) {
      label = data.title
    } else {
      label = data.title + ' ' + moment(data.start_date.toDate()).format('MMMM YYYY')
    }

    return {
      title: data.title,
      label: label.toLowerCase(),
      firebase_id: data.firebase_id,
    }
  }
  const [plans, plansLoading] = useCollectionData(db.collection('plans').orderBy('title', 'asc'), {
    idField: 'firebase_id',
    transform: transformPlans,
  })


  //Get dietary types from firebase
  const transformDietaryTypes = (data) => {
    return { ...data, label: data.name.toLowerCase() }
  }
  const [dietaryTypes, dietaryTypesLoading] = useCollectionData(db.collection('dietary_types').orderBy('name', 'asc'), {
    idField: 'firebase_id',
    transform: transformDietaryTypes,
  })

  //Get all meal types from firebase
  const transformMealTypes = (data) => {
    return { ...data, label: data.name.toLowerCase() }
  }
  const [mealTypes, mealTypesLoading] = useCollectionData(db.collection('meal_types').orderBy('name', 'asc'), {
    idField: 'firebase_id',
    transform: transformMealTypes,
  })

  const transformAllergens = (data) => {
    return { ...data, label: data.name.toLowerCase() }
  }
  //Get all allergens
  const [allergens, allergenLoading] = useCollectionData(db.collection('allergens').orderBy('name', 'asc'), {
    idField: 'firebase_id',
    transform: transformAllergens,
  })

  // get all meal tags from firebase
  const transformTaxonomy = (data) => {
    const tags = []
    if (data.tags) {
      Object.keys(data.tags).forEach((key, index) => {
        tags.push({
          label: key.split('_').join(' '),
        })
      })

      tags.sort((a, b) => Number(a.label.match(/(\d+)/g)[0]) - Number(b.label.match(/(\d+)/g)[0]))
    }
    return { ...data, originalTaxonomies: data, tags: tags }
  }
  const [taxonomy, taxonomyLoading] = useDocumentData(db.collection('taxonomy').doc('meal'), {
    transform: transformTaxonomy,
  })

  useEffect(() => {
    if (!allergenLoading && !plansLoading && !mealTypesLoading && !dietaryTypesLoading) {
      setIsLoading(false)
    }
  }, [allergenLoading, dietaryTypesLoading, mealTypesLoading, plansLoading])
  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Create Meal"
        caption="Manage your meal, you can add edit delete, don’t forget to add allergen info…"
        buttonTitle="Add New Ingredient"
      />
      <Container maxWidth="xl">
        {isLoading && taxonomyLoading && <MealDetailSkeleton />}
        {!isLoading && !taxonomyLoading && (
          <MealDetailForm
            dietaryTypes={dietaryTypes}
            mealTypes={mealTypes}
            allergens={allergens}
            plans={plans}
            taxonomy={taxonomy}
          />
        )}
      </Container>
    </Navigation>
  )
}

export default CreateMeal
