import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  formControlResults: {
    padding: '25px',
  },
  formControlFilters: {
    width: '250px',
    marginTop: '20px',
    marginBottom: '10px',
  },
  filtersContainer: {
    padding: '25px',
    paddingTop: '10px',
    paddingBottom: '15px',
  },
}))

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id, render } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      if (row.values[id] instanceof Array) {
        row.values[id].forEach((value) => {
          options.add(value.label)
        })
      } else {
        if (row.values[id]) options.add(row.values[id])
      }
    })
    return [...options.values()].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  }, [id, preFilteredRows])

  const classes = useStyles()
  // Render a select box
  return (
    <>
      {options.length > 0 ? (
        <FormControl variant="outlined" className={classes.formControlFilters}>
          <InputLabel id="unit-select-outlined-label" className={classes.inputLabelFilters}>
            {render('Header')}
          </InputLabel>
          <Select
            labelId={id + '-outlined-label'}
            id={id + '-outlined'}
            value={filterValue || ''}
            label={render('Header')}
            onChange={(e) => setFilter(e.target.value || '')}
          >
            {/* <MenuItem key={id + '-all'} value="">
              All
            </MenuItem> */}
            {options.map((option, i) => (
              <MenuItem key={option + i} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </>
  )
}
