import * as yup from 'yup'

export const Schema = yup.object().shape({
  title: yup.string().required('Exercise title is a required field'),
  trainer: yup.string().required('Trainer name is required'),
  type: yup.string().required('Exercise Type name is required'),
  description: yup.string(),
  status: yup.string().required('Status is a required field'),
  vimeo_video_id: yup.string(),
  alternative_exercise: yup.array(),
  muscle_groups: yup.array(),
  used_equipment: yup.array(),
})
