import {combineReducers} from 'redux'
import alertReducer from '../app/components/alert/AlertReducer'
import asyncReducer from '../app/features/async/asyncReducer'
import testReducer from '../app/features/sandbox/sandboxReducer'

const rootReducer = combineReducers({
    FP_ASYNC: asyncReducer,
    FP_TEST: testReducer,
    FP_ALERT: alertReducer
})

export default rootReducer