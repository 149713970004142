import { Container } from '@material-ui/core'
import React from 'react'
import ListIcon from '@material-ui/icons/List'
import IngredientsNutritionalInfoForm from '../components/forms/IngredientsNutritionalInfoForm'
import TitleBar from '../../../components/Titlebar'
import Navigation from '../../../components/layout/navigation/Navigation'

function CreateIngredient({ history }) {
  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Ingredient"
        caption="Manage your Ingredients, you can add edit delete, don’t forget to add allergen info…"
        buttonTitle="Add New Ingredient"
      />
      <Container maxWidth="xl">
        <IngredientsNutritionalInfoForm />
      </Container>
    </Navigation>
  )
}

export default CreateIngredient
