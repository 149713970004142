import { makeStyles, TextField } from '@material-ui/core'
import { SearchIcon } from '@material-ui/data-grid'
import React from 'react'
import { useAsyncDebounce } from 'react-table'
const useStyles = makeStyles((theme) => ({
  searchIcon: {
    position: 'relative',
    top: '32px',
  },
  SearchTextField: {
    [theme.breakpoints.down(780)]: {
      width: '250px',
    },
    '& label.Mui-focused': {
      paddingTop: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },
}))

// Define a default UI for filtering
export function GlobalFilter({ globalFilter, setGlobalFilter, placeHolderText }) {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)
  const classes = useStyles()

  return (
    <>
      <SearchIcon className={classes.searchIcon} size="medium" />
      <TextField
        className={classes.SearchTextField}
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        variant="outlined"
        margin="normal"
        name="search"
        style={{width: '90%'}}
        label={placeHolderText}
        id="search"
      />
    </>
  )
}
