import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'

import AlertDialogWrapper from '../../components/alert/AlertDialogWrapper'
import { useDispatch } from 'react-redux'
import { closeAlert } from '../../components/alert/AlertReducer'



function DeleteAlert(props) {
  const dispatch = useDispatch()

  return (
    <AlertDialogWrapper>
      <DialogTitle disableTypography id="alert-dialog-slide-title">{props?.deleteData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{props?.deleteData.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => dispatch(closeAlert())}>
            Cancel
          </Button>
          <Button variant="text" color="secondary" onClick={props.deleteData.onClick}>
            Confirm Delete
          </Button>
        </DialogActions>
    </AlertDialogWrapper>
  )
}

export default DeleteAlert
