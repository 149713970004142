import React from 'react'
import TitleBar from '../../../components/Titlebar'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import Navigation from '../../../components/layout/navigation/Navigation'
import { addPlanToFirestore, deletePlanInFireStore } from '../../../api/FirestoreServices'
import { Container, Typography, useTheme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import DocumentTable from '../../../components/layout/table/DocumentTable'
import { CopyButtons, EditButtons, DeleteButton } from '../../../components/layout/table/TableButtons'
import TableChip from '../../../components/layout/table/TableChip'
import { useHistory } from 'react-router'
import { SelectColumnFilter } from '../../../components/layout/table/SelectFilter'
import { db } from '../../../api/firebase'
import firebase from 'firebase/app'
import { toast } from 'react-toastify'
import { closeAlert, openAlert } from '../../../components/alert/AlertReducer'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Image from 'material-ui-image'
import { SelectColumnFilterObject } from '../../../components/layout/table/SelectFilterObject'
import { Link } from 'react-router-dom'

function Plans() {
  const theme = useTheme()

  const history = useHistory()
  const dispatch = useDispatch()

  const [plans, plansLoading, plansError] = useCollectionData(db.collection('plans').orderBy('title', 'asc'), {
    idField: 'firebase_id',
  })

  async function copy(planToCopy) {
    const newIngredient = {
      title: planToCopy.title + ' COPY',
      publish_date: firebase.firestore.Timestamp.now(),
      status: 'Draft',
      firebase_id: '',
    }
    const newPlan = { ...planToCopy, ...newIngredient }
    try {
      await addPlanToFirestore(newPlan).then((docRef) => {
        history.push(`/plans/${docRef.id}`)
      })
      toast.success('Plan successfully copied.')
    } catch (error) {
      toast.error("Sorry we're unable to copy this plan, please try again.")
    }
  }

  const deletePlan = (planToDelete) => {
    const deleteData = {
      title: 'Are you sure?',
      description:
        'Are you sure you want to delete this plan? this is a destructive action and the plan cannot be recovered.',
      onClick: () => {
        deletePlanInFireStore(planToDelete.firebase_id)
        dispatch(closeAlert())
      },
    }
    dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))
  }

  const columns = [
    {
      Header: 'Image',
      accessor: (plans) => (plans.image ? 'Yes' : 'No'),
      id: 'image', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilter,
      Cell: ({ row }) =>
        row.original.image ? (
          <Link to={`/plans/${row.original.firebase_id}`}>
            <Image
              src={row.original.image}
              height="65px"
              width="125px"
              alt={row.original.name}
              style={{ objectFit: 'cover', cursor: 'pointer' }}
            />
          </Link>
        ) : null,
    },
    {
      Header: 'Actions',
      disableFilters: true,
      Filter: () => null,
      Cell: ({ row }) => (
        <div style={{ minWidth: 160 }}>
          <CopyButtons
            onClick={() => {
              copy(row.original)
            }}
          />
          <EditButtons link={`/plans/${row.original.firebase_id}`} />
          <DeleteButton onClick={() => deletePlan(row.original)} />
        </div>
      ),
    },
    {
      Header: '#ID',
      accessor: 'firebase_id', // accessor is the "key" in the data,
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Plan Title',
      accessor: 'title', // accessor is the "key" in the data
      disableFilters: true,
      Filter: () => null,
    },
    {
        Header: 'Sub Title',
        accessor: 'subtitle', // accessor is the "key" in the data
        disableFilters: true,
        Filter: () => null,
      },
    {
      Header: 'Goal',
      accessor: 'goal', // accessor is the "key" in the data
      Filter: SelectColumnFilter,
      filter: 'equals',
    },
    {
      Header: 'Gender',
      id: 'gender',
      Filter: SelectColumnFilterObject,
      accessor: (data) => {
        if (data.gender) {
          const genders = Object.keys(data.gender)
          return genders.join(', ')
        }
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'equals',
      Cell: ({ row }) => {
        const status = row.values.status
        return status === 'Published' ? (
          <TableChip label="Published" color={theme.palette.published.main} />
        ) : status === 'Draft' ? (
          <TableChip label="Draft" color={theme.palette.draft.main} />
        ) : status === 'Pending' ? (
          <TableChip label="Pending" color={theme.palette.pending.main} />
        ) : null
      },
    },
  ]

  return (
    <Navigation>
      <TitleBar
        icon={<AccessibilityIcon fontSize="large" />}
        title="Plans"
        caption="Manage your plans, you can add, edit and delete plans..."
        buttonTitle="Add Plan"
        onClick={() => history.push('/plans/create-plan/')}
      />
      <Container maxWidth="xl">
        {plansError && <Typography variant="h1">No Firebase Connection </Typography>}
        {!plansError && (
          <DocumentTable data={plans} columns={columns} isLoading={plansLoading} placeHolderText="Search for plan" />
        )}
      </Container>
    </Navigation>
  )
}

export default Plans

