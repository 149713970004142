const OPEN_ALERT = 'OPEN_ALERT'
const CLOSE_ALERT = 'CLOSE_ALERT'

export function openAlert(payload) {
  return {
    type: OPEN_ALERT,
    payload,
  }
}

export function closeAlert() {
  return {
    type: CLOSE_ALERT,
  }
}

const initialState = null

export default function alertReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_ALERT:
      const { alertType, alertProps } = payload
      return { alertType, alertProps }
    case CLOSE_ALERT:
      return null
    default:
      return state
  }
}
