import React from 'react'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import Navigation from '../../../components/layout/navigation/Navigation'
import { addExerciseToFirestore, deleteExerciseFromFireStore } from '../../../api/FirestoreServices'
import { Container, Typography, useTheme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import DocumentTable from '../../../components/layout/table/DocumentTable'
import { CopyButtons, EditButtons, DeleteButton } from '../../../components/layout/table/TableButtons'
import TableChip from '../../../components/layout/table/TableChip'
import { useHistory } from 'react-router'
import { SelectColumnFilter } from '../../../components/layout/table/SelectFilter'
import { db } from '../../../api/firebase'
import { toast } from 'react-toastify'
import { closeAlert, openAlert } from '../../../components/alert/AlertReducer'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app'
import Image from 'material-ui-image'
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo'
import { Link } from 'react-router-dom'
import { SelectColumnFilterArray } from '../../../components/layout/table/SelectFilterArray'
import map from 'lodash/map'

function Exercises() {
  const theme = useTheme()

  const history = useHistory()
  const dispatch = useDispatch()

  const [exercises, exercisesLoading, exerciseError] = useCollectionData(
    db.collection('exercises').orderBy('title', 'asc'),
    {
      idField: 'firebase_id',
    }
  )

  async function copy(exerciseToCopy) {
    const newExercise = {
      title: exerciseToCopy.title + '- COPY',
      publish_date: firebase.firestore.Timestamp.now(),
      status: 'Draft',
      firebase_id: '',
    }
    const copyExercise = { ...exerciseToCopy, ...newExercise }
    try {
      await addExerciseToFirestore(copyExercise).then((docRef) => {
        history.push(`/exercises/${docRef.id}`)
      })
      toast.success('Exercise successfully copied.')
    } catch (error) {
      toast.error("Sorry we're unable to copy this exercise, please try again.")
    }
  }

  const deleteExercise = (exerciseToDelete) => {
    const deleteData = {
      title: 'Are you sure?',
      description:
        'Are you sure you want to delete this exercise? this is a destructive action and this ingredient cannot be recovered',
      onClick: () => {
        deleteExerciseFromFireStore(exerciseToDelete.firebase_id)
        dispatch(closeAlert())
      },
    }
    dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))
  }

  const columns = [
    {
      Header: 'Image',
      accessor: (data) => (data.image ? 'Yes' : 'No'),
      id: 'image', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilter,
      Cell: ({ row }) =>
        row.original.image ? (
          <Link to={`/exercises/${row.original.firebase_id}`}>
            <Image
              src={row.original.image}
              height="65px"
              width="125px"
              alt={row.original.title}
              style={{ objectFit: 'cover', cursor: 'pointer' }}
            />
          </Link>
        ) : null,
    },
    {
      Header: 'Actions',
      disableFilters: true,
      Filter: () => null,
      Cell: ({ row }) => (
        <div style={{ minWidth: 160 }}>
          <CopyButtons
            onClick={() => {
              copy(row.original)
            }}
          />
          <EditButtons link={`/exercises/${row.original.firebase_id}`} />
          <DeleteButton onClick={() => deleteExercise(row.original)} />
        </div>
      ),
    },

    {
      Header: '#ID',
      accessor: 'firebase_id', // accessor is the "key" in the data,
      //disableGlobalFilter: true,
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Exercise Title',
      accessor: 'title', // accessor is the "key" in the data
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Equipment',
      id: 'used_equipment', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterArray,
      accessor: (data) => {
        let used_equipment = []
        if (data.used_equipment) {
          map(data.used_equipment, (equipment) => {
            used_equipment.push(equipment.label)
          })
          return used_equipment.join(', ')
        }
      },
    },
    {
      Header: 'Muscle Groups',
      id: 'muscle_groups', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterArray,
      accessor: (data) => {
        let muscle_groups = []
        if (data.muscle_groups) {
          map(data.muscle_groups, (muscle_group) => {
            muscle_groups.push(muscle_group.label)
          })
          return muscle_groups.join(', ')
        }
      },
    },
    {
      Header: 'Exercise Type',
      id: 'type', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterArray,
      accessor: (data) => {
        let types = []
        if (data.type) {
          types.push(data.type)
          return types
        }
      },
    },
    {
      Header: 'Exercise Type',
      id: 'trainer', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterArray,
      accessor: (data) => {
        let trainers = []
        if (data.trainer) {
          trainers.push(data.trainer)
          return trainers
        }
      },
    },
    {
      Header: 'Video',
      accessor: (data) => (data.vimeo_video_id ? 'Yes' : 'No'),
      id: 'vimeo_video_id', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilter,
      Cell: ({ row }) =>
        row.original.vimeo_video_id ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SlowMotionVideoIcon color="primary" style={{ fontSize: '28px', display: 'flex' }} />
          </div>
        ) : null,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'equals',
      Cell: ({ row }) => {
        const status = row.values.status
        return status === 'Published' ? (
          <TableChip label="Published" color={theme.palette.published.main} />
        ) : status === 'Draft' ? (
          <TableChip label="Draft" color={theme.palette.draft.main} />
        ) : status === 'Pending' ? (
          <TableChip label="Pending" color={theme.palette.pending.main} />
        ) : null
      },
    },
  ]

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Exercises"
        caption="Manage your exercises, you can add, edit  and delete exercises…"
        buttonTitle="Add Exercise"
        onClick={() => history.push('/exercises/create-exercise')}
      />
      <Container maxWidth="xl">
        {exerciseError && <Typography variant="h1">No Firebase Connection </Typography>}
        {!exerciseError && (
          <DocumentTable
            data={exercises}
            columns={columns}
            isLoading={exercisesLoading}
            placeHolderText="Search for exercise"
          />
        )}
      </Container>
    </Navigation>
  )
}

export default Exercises
