import React from 'react'
import Navigation from '../../components/layout/navigation/Navigation'
import TitleBar from '../../components/Titlebar'
import ErrorIcon from '@material-ui/icons/Error'
import { useHistory } from 'react-router-dom'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: '60vh',
    textAlign: 'center',
    '& > h3': {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    marginTop: '20px',
    alignSelf: 'center',
  },
}))

function NotFound() {
  const styles = useStyles()
  const history = useHistory()
  return (
    <Navigation>
      <TitleBar
        icon={<ErrorIcon fontSize="large" />}
        title="Not Found"
        caption="Sorry the page your looking for doesn't exist..."
        buttonTitle="Go To Plans"
        onClick={() => history.push('dashboard')}
      />
      <Grid container direction="column" align="center" justify="center" className={styles.gridContainer}>
        <Typography variant="h1">
          Whoops
        </Typography>
        <Typography variant="h3">We couldn't find the page you were looking for!</Typography>
        <Button href="/plans" variant="contained" className={styles.button}>
          Go To Plans
        </Button>
      </Grid>
    </Navigation>
  )
}

export default NotFound
