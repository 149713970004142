import { Container, Typography } from '@material-ui/core'
import React from 'react'
import ListIcon from '@material-ui/icons/List'
import TitleBar from '../../../components/Titlebar'
import Navigation from '../../../components/layout/navigation/Navigation'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import WorkoutDetailSkeleton from '../components/skeletonLoaders/WorkoutDetailSkeleton'
import WorkoutForm from '../components/forms/WorkoutForm'
import moment from 'moment'

function CreateWorkout() {
  const transformTaxonomy = (data) => {
    const methods = []
    const tags = []
    if (data.workout_method) {
      Object.keys(data.workout_method).forEach((key, index) => {
        methods.push({
          label: key.split('_').join(' '),
        })
      })
    }
    if (data.workout_tags) {
        Object.keys(data.workout_tags).forEach((key, index) => {
          tags.push({
            label: key.split('_').join(' '),
          })
        })
  
        tags.sort((a, b) => Number(a.label.match(/(\d+)/g)[0]) - Number(b.label.match(/(\d+)/g)[0]))
      }
    return { ...data, originalTaxonomies: data, workout_method: methods, workout_tags: tags}
  }
  const [taxonomy, taxonomyLoading, taxonomyError] = useDocumentData(db.collection('taxonomy').doc('workout'), {
    transform: transformTaxonomy,
  })

  const transformWorkouts = (data) => {
    return { ...data, label: data.title.toLowerCase() }
  }
  const [workouts, workoutsLoading, workoutsError] = useCollectionData(
    db.collection('weeks_workouts').orderBy('title', 'asc'),
    {
      idField: 'firebase_id',
      transform: transformWorkouts,
    }
  )

  // Get plans from firebase
  const transformPlans = (data) => {
    let label
    if (data.plan_type === 'Nutrition Plan') {
      label = data.title
    } else {
      data.subtitle ? label = data.title + ' ' + data.subtitle : label = data.title + ' ' + moment(data.start_date.toDate()).format('MMMM YYYY')
    }
    return { title: data.title.toLowerCase(), label: label.toLowerCase(), firebase_id: data.firebase_id }
  }
  const [plans, plansLoading, plansError] = useCollectionData(db.collection('plans').orderBy('title', 'asc'), {
    idField: 'firebase_id',
    transform: transformPlans,
  })

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Create Workout"
        caption="Add a new Workout, make sure you assign the workout to a plan…"
        buttonTitle="Add New Workout"
      />
      <Container maxWidth="xl">
        {taxonomyError || workoutsError || plansError ? (
          <Typography variant="h1">No Firebase Connection </Typography>
        ) : null}
        {workoutsLoading || taxonomyLoading || plansLoading ? (
          <WorkoutDetailSkeleton />
        ) : (
          <WorkoutForm taxonomy={taxonomy} alternativeWorkoutOptions={workouts} plans={plans} />
        )}
      </Container>
    </Navigation>
  )
}

export default CreateWorkout
