import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import React from 'react'
import { NavLink } from 'react-router-dom'
//import PieChartIcon from '@material-ui/icons/PieChart'
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
// import ReplayIcon from '@material-ui/icons/Replay'
// import GroupIcon from '@material-ui/icons/Group'
import ListIcon from '@material-ui/icons/List'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
//import FolderIcon from '@material-ui/icons/Folder'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'

function SidebarNavigationItems() {
  return (
    <>
      <List>
        {/* <ListItem button component={NavLink} to="/dashboard">
          <ListItemIcon>
            <PieChartIcon htmlColor="#fff" />
          </ListItemIcon>
          <ListItemText primary={'Dashboard'} />
        </ListItem> */}
        <ListItem button component={NavLink} to="/classes">
          <ListItemIcon>
            <DirectionsRunIcon htmlColor="#fff" />
          </ListItemIcon>
          <ListItemText primary={'Classes'} />
        </ListItem>
        {/* <ListItem button exact component={NavLink} to="/sandbox">
        <ListItemIcon>
          <PieChartIcon htmlColor="#fff" />
        </ListItemIcon>
        <ListItemText primary={'Sandbox'} />
      </ListItem> */}
        {/* <ListItem button component={NavLink} to="/orders">
        <ListItemIcon>
          <ShoppingCartIcon htmlColor="#fff" />
        </ListItemIcon>
        <ListItemText primary={'Orders'} />
      </ListItem>
      <ListItem button component={NavLink} to="/subscriptions">
        <ListItemIcon>
          <ReplayIcon htmlColor="#fff" />
        </ListItemIcon>
        <ListItemText primary={'Subscriptions'} />
      </ListItem>
      <ListItem button component={NavLink} to="/users">
        <ListItemIcon>
          <GroupIcon htmlColor="#fff" />
        </ListItemIcon>
        <ListItemText primary={'Users'} />
      </ListItem> */}
        <ListItem button component={NavLink} to="/ingredients">
          <ListItemIcon>
            <ListIcon htmlColor="#fff" />
          </ListItemIcon>
          <ListItemText primary={'Ingredients'} />
        </ListItem>
        <ListItem button component={NavLink} to="/meals">
          <ListItemIcon>
            <RestaurantIcon htmlColor="#fff" />
          </ListItemIcon>
          <ListItemText primary={'Meals'} />
        </ListItem>
        <ListItem button component={NavLink} to="/exercises">
          <ListItemIcon>
            <DirectionsBikeIcon htmlColor="#fff" />
          </ListItemIcon>
          <ListItemText primary={'Exercises'} />
        </ListItem>
        <ListItem button component={NavLink} to="/workouts">
          <ListItemIcon>
            <FitnessCenterIcon htmlColor="#fff" />
          </ListItemIcon>
          <ListItemText primary={'Workouts'} />
        </ListItem>
        <ListItem button component={NavLink} to="/plans">
          <ListItemIcon>
            <AccessibilityIcon htmlColor="#fff" />
          </ListItemIcon>
          <ListItemText primary={'Plans'} />
        </ListItem>
        {/* <ListItem button component={NavLink} to="/media">
        <ListItemIcon>
          <FolderIcon htmlColor="#fff" />
        </ListItemIcon>
        <ListItemText primary={'Media'} />
      </ListItem> */}
      </List>
      <Typography
        variant="caption"
        style={{
          fontSize: '12px',
          display: 'flex',
          flex: '1',
          alignItems: 'flex-end',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
       Version {process.env.REACT_APP_VERSION} {process.env.REACT_APP_ENV === 'production' ? 'Prod' : process.env.REACT_APP_ENV === 'development' ? 'Dev' : ''} 
      </Typography>
    </>
  )
}

export default SidebarNavigationItems
