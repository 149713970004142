import React, { useMemo, useCallback, useRef } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useTable } from 'react-table'
import { Skeleton } from '@material-ui/lab'
import update from 'immutability-helper'
import { AddIcon } from '@material-ui/data-grid'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 1fr)',
    borderRadius: 15,
    border: '1px solid #BFBFBF',
    width: '100%',
  },
  tableContainer: {
    overflowY: 'hidden',
  },
  table: {
    margin: '0 auto',
    borderCollapse: 'separate',
    borderSpacing: '0',
    '& th': {
      borderBottom: '1px solid #BFBFBF',
      textTransform: 'uppercase',
      paddingTop: '26px',
      paddingBottom: '15px',
    },
    '& td': {
      borderBottom: '1px solid #BFBFBF',
      minHeight: '75px',
    },
    '& tr:hover': {
      cursor: 'move',
    },
    '& tr': {
        cursor: 'move'
    },
    '& td:active': {
      borderBottom: '1px solid #BFBFBF',
      minHeight: '75px',
    },
  },
  tableFooter: {
    backgroundColor: '#EEF1F1',
    padding: theme.spacing(3),
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
}))

const Row = ({ row, index, moveRow }) => {
  const elementRef = useRef(null)

  const [, drop] = useDrop({
    accept: 'row',
    hover(item, monitor) {
      if (!elementRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = elementRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'row',
    item: { index },
    options: {
      dropEffect: 'move',
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  
  const opacity = isDragging ? 0.2 : 1;

  preview(drop(elementRef))
  drag(elementRef)

  return (
    <TableRow {...row.getRowProps()} ref={elementRef} style={{ opacity }}>
      {row.cells.map((cell) => {
        return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
      })}
    </TableRow>
  )
}

function RepeaterList({ columns, records, setRecords, isLoading, buttonClick, buttonTitle }) {
  const classes = useStyles()
  const tableData = useMemo(() => (isLoading ? Array(5).fill({}) : records), [isLoading, records])

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton variant="text" />,
          }))
        : columns,
    [isLoading, columns]
  )

  const getRowId = useCallback((row) => {
    return row.id
  }, [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: tableColumns,
    data: tableData,
    getRowId,
  })

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]
    const newrecords = update(records, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    }).map((v, i) => {
      v.order = i + 1
      return v
    })
    setRecords(newrecords)
  }

  return (
    <Grid component={Paper} elevation={3} className={classes.grid} style={{ }}>
      <div className={classes.tableContainer}>
        <DndProvider backend={HTML5Backend}>
          <Table {...getTableProps()} className={classes.table}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row)
                return <Row index={index} row={row} moveRow={moveRow} {...row.getRowProps()} />
              })}
            </TableBody>
          </Table>
        </DndProvider>
      </div>
      {buttonClick && buttonTitle ? (
        <Grid container item justify="flex-end" alignItems="center" className={classes.tableFooter}>
          <Button variant="contained" onClick={buttonClick} startIcon={<AddIcon />}>
            <Typography variant="button">{buttonTitle}</Typography>
          </Button>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default RepeaterList
