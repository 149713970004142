import React from 'react'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import Navigation from '../../../components/layout/navigation/Navigation'
import { deleteWorkoutFromFireStore, addWorkoutToFirestore } from '../../../api/FirestoreServices'
import { Container, Typography, useTheme, capitalize } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import DocumentTable from '../../../components/layout/table/DocumentTable'
import { CopyButtons, EditButtons, DeleteButton } from '../../../components/layout/table/TableButtons'
import TableChip from '../../../components/layout/table/TableChip'
import { useHistory } from 'react-router'
import { SelectColumnFilter } from '../../../components/layout/table/SelectFilter'
import { db } from '../../../api/firebase'
import { toast } from 'react-toastify'
import { closeAlert, openAlert } from '../../../components/alert/AlertReducer'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app'
//import Image from 'material-ui-image'
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo'
//import map from 'lodash/map'
import { SelectColumnFilterObject } from '../../../components/layout/table/SelectFilterObject'

function Workouts() {
  const theme = useTheme()

  const history = useHistory()
  const dispatch = useDispatch()

  const [workouts, workoutsLoading, workoutsError] = useCollectionData(
    db.collection('weeks_workouts').orderBy('title', 'asc'),
    {
      idField: 'firebase_id',
    }
  )

  async function copy(workoutToCopy) {
    const newWorkout = {
      title: workoutToCopy.title + ' - COPY',
      publish_date: firebase.firestore.Timestamp.now(),
      status: 'Draft',
      firebase_id: '',
    }
    const copyWorkout = { ...workoutToCopy, ...newWorkout }
    try {
      await addWorkoutToFirestore(copyWorkout).then((docRef) => {
        history.push(`/workouts/${docRef.id}`)
      })
      toast.success('Workout successfully copied.')
    } catch (error) {
      toast.error("Sorry we're unable to copy this workout, please try again.")
    }
  }

  const deleteWorkout = (workoutToDelete) => {
    const deleteData = {
      title: 'Are you sure?',
      description:
        'Are you sure you want to delete this Workout? this is a destructive action and this workout cannot be recovered',
      onClick: () => {
        deleteWorkoutFromFireStore(workoutToDelete.firebase_id)
        dispatch(closeAlert())
      },
    }
    dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))
  }
  const columns = [
    {
      Header: 'Actions',
      disableFilters: true,
      Filter: () => null,
      Cell: ({ row }) => (
        <div style={{ minWidth: 160 }}>
          <CopyButtons
            onClick={() => {
              copy(row.original)
            }}
          />
          <EditButtons link={`/workouts/${row.original.firebase_id}`} />
          <DeleteButton onClick={() => deleteWorkout(row.original)} />
        </div>
      ),
    },
    {
      Header: '#ID',
      accessor: 'firebase_id', // accessor is the "key" in the data,
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Workout Title',
      accessor: 'title', // accessor is the "key" in the data,
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Type',
      accessor: 'workout_type', // accessor is the "key" in the data,
      //disableGlobalFilter: true,
      Filter: SelectColumnFilter,
      filter: 'equals',
    },
    {
      Header: 'Tags',
      id: 'workout_tags', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterObject,
      accessor: (data) => {
        if (data.workout_tags) {
          const workoutTags = Object.keys(data.workout_tags)
          const workoutTag = workoutTags.map((name) => capitalize(name.replace(/[^A-Za-z0-9]/g, ' ')))
          return workoutTag.join(', ')
        }
      },
    },
    {
        Header: 'Workout Plan',
        id: 'plans', // accessor is the "key" in the data
        disableGlobalFilter: true,
        Filter: SelectColumnFilterObject,
        accessor: (data) => {
          if (data.plans) {
            const mealTypes = Object.keys(data.plans)
            const mealType = mealTypes.map((name) => capitalize(name.replace(/[^A-Za-z0-9]/g, ' ')))
            return mealType.join(', ')
          }
        },
      },
    {
      Header: 'Duration',
      accessor: 'duration', // accessor is the "key" in the data,
      //disableGlobalFilter: true,
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Video',
      accessor: (data) => (data.vimeo_video_id ? 'Yes' : 'No'),
      id: 'vimeo_video_id', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilter,
      Cell: ({ row }) =>
        row.original.vimeo_video_id ? <SlowMotionVideoIcon color="primary" style={{ fontSize: '28px' }} /> : null,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'equals',
      Cell: ({ row }) => {
        const status = row.values.status
        return status === 'Published' ? (
          <TableChip label="Published" color={theme.palette.published.main} />
        ) : status === 'Draft' ? (
          <TableChip label="Draft" color={theme.palette.draft.main} />
        ) : status === 'Pending' ? (
          <TableChip label="Pending" color={theme.palette.pending.main} />
        ) : null
      },
    },
  ]

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Workouts"
        caption="Manage your workouts, you can add, edit  and delete workouts..."
        buttonTitle="Add Workout"
        onClick={() => history.push('/workouts/create-workout')}
      />
      <Container maxWidth="xl">
        {workoutsError && <Typography variant="h1">No Firebase Connection </Typography>}
        {!workoutsError && (
          <DocumentTable
            data={workouts}
            columns={columns}
            isLoading={workoutsLoading}
            placeHolderText="Search for workout"
          />
        )}
      </Container>
    </Navigation>
  )
}

export default Workouts
