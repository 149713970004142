import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import CategorySkeleton from '../../../../components/category/CategorySkeleton'
import ImageUploadSkeleton from '../../../../components/Image/ImageUploadSkeleton'
import StatusSkeleton from '../../../../components/status/StatusSkeleton'
import MealInfoFormSkeleton from './MealInfoFormSkeleton'

const useStyles = makeStyles((theme) => ({
  statusComponent: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      order: 1,
      width: '100%',
    },
  },
  formComponent: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
}))

function MealDetailSkeleton() {
  const classes = useStyles()

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid flexdirection="row" container justify="space-between" spacing={isSmall ? 0 : 5}>
      <Grid item xs={12} md={8} xl={9} className={classes.formComponent}>
        <MealInfoFormSkeleton />
      </Grid>
      <Grid item xs={12} md={4} xl={3} className={classes.statusComponent}>
        <StatusSkeleton />
        <CategorySkeleton title="Allergens"/>
        <ImageUploadSkeleton />
      </Grid>
    </Grid>
  )
}

export default MealDetailSkeleton
