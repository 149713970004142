import React from 'react'
import TableOrder from '../../../../components/layout/table/TableOrder'
import { DeleteButton, EditButtons } from '../../../../components/layout/table/TableButtons'
import StyledGrid from '../../../../components/layout/Grid'
import RepeaterList from '../../../../components/layout/table/ReorderTable'
import { openAlert } from '../../../../components/alert/AlertReducer'
import { useDispatch } from 'react-redux'
import { FormHelperText, Typography } from '@material-ui/core'

export default function MainIngredients({ ingredients, setIngredients, nutritionalInfo, ingredientsError }) {
  const dispatch = useDispatch()

  const columns = [
    {
      Header: 'Order',
      accessor: 'order',
      Cell: ({ row }) => <TableOrder order={row.values.order} />,
    },
    {
      Header: 'Ingredient',
      accessor: 'title',
    },
    {
      Header: 'Unit of Measure',
      accessor: 'unit',
    },
    {
      Header: 'Min',
      accessor: 'min_amount',
    },
    {
      Header: 'Max',
      accessor: 'max_amount',
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <>
          <EditButtons
            onClick={() =>
              dispatch(openAlert({ alertType: 'MainIngredientAlert', alertProps: { upDateIngredient, row } }))
            }
          />
          <DeleteButton onClick={() => setIngredients([...ingredients.filter((ing) => ing.id !== row.original.id)])} />
        </>
      ),
    },
  ]

  const upDateIngredient = {
    title: 'Update ingredient.',
    description: 'Update ingredient amounts',
    ingredients: ingredients,
    setIngredients: setIngredients,
  }

  const addIngredientData = {
    title: 'Add an ingredient.',
    description: 'Type to search for an ingredient, and enter the min and max values.',
    ingredients: ingredients,
    setIngredients: setIngredients,
  }

  return (
    <StyledGrid title="Main Ingredients">
      {ingredientsError && (
        <FormHelperText error style={{ marginBottom: '10px' }}>
          {ingredientsError}
        </FormHelperText>
      )}
      <div style={ingredientsError && { border: 'solid 2px red', padding: 0, borderRadius: 15 }}>
        <RepeaterList
          columns={columns}
          records={ingredients}
          setRecords={setIngredients}
          buttonClick={() =>
            dispatch(openAlert({ alertType: 'MainIngredientAlert', alertProps: { addIngredientData } }))
          }
          buttonTitle="Add Ingredient"
        ></RepeaterList>
      </div>
      <br />
      {/* //TODO add nutritional info table */}
      <Typography variant="body2">{nutritionalInfo.minCalories}</Typography>
      <Typography variant="body2">{nutritionalInfo.maxCalories}</Typography>
    </StyledGrid>
  )
}
