import { Container, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import Navigation from '../../../components/layout/navigation/Navigation'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import WorkoutDetailSkeleton from '../components/skeletonLoaders/WorkoutDetailSkeleton'
import WorkoutForm from '../components/forms/WorkoutForm'
import { findSelected } from '../../../util/util'
import moment from 'moment'
//import { findSelected } from '../../../util/util'

function WorkoutDetail({ match, history }) {
  const transformTaxonomy = data => {
    const methods = []
    const tags = []

    if (data.workout_method) {
      Object.keys(data.workout_method).forEach((key, index) => {
        methods.push({
          label: key.split('_').join(' '),
        })
      })
    }

    if (data.workout_tags) {
      Object.keys(data.workout_tags).forEach((key, index) => {
        tags.push({
          label: key.split('_').join(' '),
        })
      })

      tags.sort((a, b) => Number(a.label.match(/(\d+)/g)[0]) - Number(b.label.match(/(\d+)/g)[0]))
    }
    return { ...data, originalTaxonomies: data, workout_method: methods, workout_tags: tags }
  }
  const [taxonomy, taxonomyLoading, taxonomyError] = useDocumentData(db.collection('taxonomy').doc('workout'), {
    transform: transformTaxonomy,
  })

  // Format workout data
  const transformWorkout = (data) => {
    const alternative_workouts = []
    const exercises = []
    const workout_tags = []

    if (data?.alternative_workouts) {
      Object.keys(data?.alternative_workouts).forEach((key, index) => {
        alternative_workouts.push({
          label: key,
          ...data?.alternative_workouts,
        })
      })
    }

    if (data?.exercises) {
      Object.keys(data?.exercises).forEach((key, index) => {
        exercises.push({
          label: key, // firebase_id
          ...data.exercises[key],
          // Because we ... after, it should override order if it is set in firebase
        })
      })
    }

    if (data.workout_tags) {
        Object.keys(data.workout_tags).forEach((key, index) => {
            workout_tags.push({
            label: key.split('_').join(' '),
            ...data.workout_tags,
          })
        })
      }

    return {
      ...data,
      alternative_workouts: alternative_workouts,
      exercises: exercises,
      workout_tags: workout_tags
    }
  }

  //Get the meal from firebase
  const [workout, workoutLoading, workoutError] = useDocumentData(
    db.collection('weeks_workouts').doc(match.params.id),
    {
      transform: transformWorkout,
      idField: 'firebase_id',
    }
  )

  const transformWorkouts = (data) => {
    return { ...data, label: data.title.toLowerCase() }
  }
  const [workouts, workoutsLoading, workoutsError] = useCollectionData(
    db.collection('weeks_workouts').orderBy('title', 'asc'),
    {
      idField: 'firebase_id',
      transform: transformWorkouts,
    }
  )

  // Get & transform plans from firebase
  const transformPlans = (data) => {
    let label
    if (data.plan_type === 'Nutrition Plan') {
      label = data.title
    } else {
      //label = data.title + ' ' + moment(data.start_date.toDate()).format('MMMM YYYY')

      //Formatting label to combine together title and subtitle, if subtitle is not specified moment is used to add date to ensure backward compatibility.
      data.subtitle ? label = data.title + ' ' + data.subtitle : label = data.title + ' ' + moment(data.start_date.toDate()).format('MMMM YYYY')
    }
    return { title: data.title.toLowerCase(), label: label.toLowerCase(), firebase_id: data.firebase_id }
  }
  const [plans, plansLoading, plansError] = useCollectionData(db.collection('plans').orderBy('title', 'asc'), {
    idField: 'firebase_id',
    transform: transformPlans,
  })

  useEffect(() => {
    if (!workoutLoading && workout === undefined) {
      history.push('/404')
    }
  }, [history, workout, workoutLoading])

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Workout"
        caption="Manage your Workout, you can edit delete the workout, don’t forget to add to a plan…"
        buttonTitle="Add New Workout"
        onClick={() => history.push('/workouts/create-workout')}
      />
      <Container maxWidth="xl">
        {taxonomyError || workoutError || workoutsError || plansError ? (
          <Typography variant="h1">No Firebase Connection </Typography>
        ) : null}
        {plansLoading || workoutsLoading || taxonomyLoading || workoutLoading ? <WorkoutDetailSkeleton /> : null}
        {workout && workouts && plans && taxonomy && (
          <WorkoutForm
            data={workout}
            alternativeWorkoutOptions={workouts.filter((workOut) => workOut.firebase_id !== workout.firebase_id)}
            selectedAlternativeWorkouts={
              workouts && workout.alternative_workouts_id
                ? findSelected(workouts, workout.alternative_workouts_id, 'title')
                : []
            }
            taxonomy={taxonomy}
            plans={plans}
            plansSelected={plans && workout.plans_id ? findSelected(plans, workout.plans_id, 'label') : []}
          />
        )}
      </Container>
    </Navigation>
  )
}

export default WorkoutDetail
