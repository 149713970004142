import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import StyledGrid from '../../../../components/layout/Grid'

function NutritionInfoFormSkeleton() {
  return (
    <StyledGrid title="Nutritional Info">
      <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: '15px' }} />
      <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: '15px' }} />
      <Grid container justify="space-between">
        <Skeleton variant="rect" width={'48%'} height={50} style={{ marginBottom: '15px' }} />
        <Skeleton variant="rect" width={'48%'} height={50} style={{ marginBottom: '15px' }} />
      </Grid>
      <Grid container justify="space-between">
        <Skeleton variant="rect" width={'48%'} height={50} />
        <Skeleton variant="rect" width={'48%'} height={50} />
      </Grid>
    </StyledGrid>
  )
}

export default NutritionInfoFormSkeleton
