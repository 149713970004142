import React from 'react'
import TitleBar from '../../../components/Titlebar'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import Navigation from '../../../components/layout/navigation/Navigation'
import { CopyButtons, DeleteButton, EditButtons } from '../../../components/layout/table/TableButtons'
import TableChip from '../../../components/layout/table/TableChip'
import { SelectColumnFilter } from '../../../components/layout/table/SelectFilter'
import { SelectColumnFilterArray } from '../../../components/layout/table/SelectFilterArray'
import { closeAlert, openAlert } from '../../../components/alert/AlertReducer'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { capitalize, Container, Typography, useTheme } from '@material-ui/core'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import firebase from 'firebase/app'
import { addVideoToFirestore, deleteVideoInFireStore } from '../../../api/FirestoreServices'
import DocumentTable from '../../../components/layout/table/DocumentTable'
import map from 'lodash/map'
import { SelectColumnFilterObject } from '../../../components/layout/table/SelectFilterObject'

function Classes() {
  const theme = useTheme()

  const history = useHistory()
  const dispatch = useDispatch()

  const [videos, videosLoading, videosError] = useCollectionData(db.collection('videos').orderBy('title', 'asc'), {
    idField: 'firebase_id',
  })

  async function copy(planToCopy) {
    const newIngredient = {
      name: planToCopy.name + ' COPY',
      publish_date: firebase.firestore.Timestamp.now(),
      status: 'Draft',
      firebase_id: '',
    }
    const newPlan = { ...planToCopy, ...newIngredient }
    try {
      await addVideoToFirestore(newPlan).then((docRef) => {
        history.push(`/class/${docRef.id}`)
      })
      toast.success('Video successfully copied.')
    } catch (error) {
      toast.error("Sorry we're unable to copy this Video, please try again.")
    }
  }

  const deletePlan = (planToDelete) => {
    const deleteData = {
      title: 'Are you sure?',
      description:
        'Are you sure you want to delete this Video? this is a destructive action and the Video cannot be recovered.',
      onClick: () => {
        deleteVideoInFireStore(planToDelete.firebase_id)
        dispatch(closeAlert())
      },
    }
    dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))
  }

  const columns = [
    {
      Header: 'Actions',
      disableFilters: true,
      Filter: () => null,
      Cell: ({ row }) => (
        <div style={{ minWidth: 160 }}>
          <CopyButtons
            onClick={() => {
              copy(row.original)
            }}
          />
          <EditButtons link={`/class/${row.original.firebase_id}`} />
          <DeleteButton onClick={() => deletePlan(row.original)} />
        </div>
      ),
    },
    {
      Header: '#ID',
      accessor: 'firebase_id', // accessor is the "key" in the data,
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Class Title',
      accessor: 'title', // accessor is the "key" in the data
      disableFilters: true,
      Filter: () => null,
    },
    {
        Header: 'Class Subtitle',
        accessor: 'subtitle', // accessor is the "key" in the data
        disableFilters: true,
        Filter: () => null,
      },
    {
      Header: 'Vimeo Video ID',
      accessor: 'vimeo_video_id', // accessor is the "key" in the data
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Workout Plan',
      id: 'plans', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterObject,
      accessor: (data) => {
        if (data.plans) {
          const mealTypes = Object.keys(data.plans)
          const mealType = mealTypes.map((name) => capitalize(name.replace(/[^A-Za-z0-9]/g, ' ')))
          return mealType.join(', ')
        }
      },
    },
    {
      Header: 'Intensity',
      accessor: 'intensity',
      Filter: SelectColumnFilter,
      filter: 'equals',
    },
    {
      Header: 'Topics',
      id: 'topics',
      Filter: SelectColumnFilterArray,
      accessor: (data) => {
        let topics = []
        if (data.topics) {
          map(data.topics, (topic) => {
            topics.push(topic.label)
          })
          return topics.join(', ')
        }
      },
    },
    {
      Header: 'Trainers',
      id: 'trainers',
      Filter: SelectColumnFilterArray,
      accessor: (data) => {
        let trainers = []
        if (data.trainers) {
          map(data.trainers, (trainer) => {
            trainers.push(trainer.label)
          })
          return trainers.join(', ')
        }
      },
    },
    {
      Header: 'Equipment',
      id: 'class_equipment',
      Filter: SelectColumnFilterArray,
      accessor: (data) => {
        let class_equipment = []
        if (data.class_equipment) {
          map(data.class_equipment, (equipment) => {
            class_equipment.push(equipment.label)
          })
          return class_equipment.join(', ')
        }
      },
    },
    {
      Header: 'Duration',
      accessor: 'duration', // accessor is the "key" in the data
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'equals',
      Cell: ({ row }) => {
        const status = row.values.status
        return status === 'Published' ? (
          <TableChip label="Published" color={theme.palette.published.main} />
        ) : status === 'Draft' ? (
          <TableChip label="Draft" color={theme.palette.draft.main} />
        ) : status === 'Pending' ? (
          <TableChip label="Pending" color={theme.palette.pending.main} />
        ) : null
      },
    },
  ]

  return (
    <>
      <Navigation>
        <TitleBar
          icon={<DirectionsRunIcon fontSize="large" />}
          title="Classes"
          caption="Manage your classes, you can add, edit and delete classes..."
          onClick={() => history.push('/class/create-class/')}
          buttonTitle="Add New Class"
        />
        <Container maxWidth="xl">
          {videosError && <Typography variant="h1">No Firebase Connection </Typography>}
          {!videosError && (
            <DocumentTable
              data={videos}
              columns={columns}
              isLoading={videosLoading}
              placeHolderText="Search for video"
            />
          )}
        </Container>
      </Navigation>
    </>
  )
}

export default Classes
