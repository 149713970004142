import * as yup from 'yup'

export const Schema = yup.object().shape({
  title: yup.string().required('Meal title is a required field'),
  description: yup.string(),
  tips: yup.string(),
  cooking_time: yup.string().when('status', (value) => {
    if (value === 'Published') {
      return yup
        .number()
        .typeError('You must specify a number, please use 0 if no value.')
        .required()
        .test(
          'Is positive?',
          'Cook Time requires a positive number, please remove any negative values.',
          (value) => value >= 0
        )
    }
  }),
  prep_time: yup.string().when('status', (value) => {
    if (value === 'Published') {
      return yup
        .number()
        .typeError('You must specify a number, please use 0 if no value.')
        .required()
        .test(
          'Is positive?',
          'Cook Time requires a positive number, please remove any negative values.',
          (value) => value >= 0
        )
    }
  }),
  dietary_types: yup.array().when('status', (value) => {
    if (value === 'Published') {
      return yup.array().min(1, 'You must select at least 1 dietary requirement').required()
    }
  }),
  meal_types: yup.array().when('status', (value) => {
    if (value === 'Published') {
      return yup.array().min(1, 'You must select at least 1 meal type').required()
    }
  }),
  allergens: yup.array(),
  plans: yup.array().when('status', (value) => {
    if (value === 'Published') {
      return yup.array().min(1, 'You must select at least 1 plan').required()
    }
  }),
  status: yup.string().required('Status is a required field'),
})
