import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

function TableOrder({order}) {

    const useStyles = makeStyles((theme) => ({
        order: {
            textAlign: 'center',
            color: '#000',
            fontWeight: '600',
            width: '39px',
            height: '39px',
            lineHeight: '39px',
            borderRadius: '39px',
            border: '1px solid #94A5A6',
        },
      }))
      
    const classes = useStyles()

    return (
        <Typography  className={classes.order}>
            {order}
        </Typography>
    )
}

export default TableOrder
