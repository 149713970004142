import { FormHelperText, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import Status from '../../../../components/status/Status'
import StyledGrid from '../../../../components/layout/Grid'
import { Schema } from '../../schema/MealSchema'
import { MuiInput } from '../../../../components/form/MuiInput'
import { MuiForm } from '../../../../components/form/MuiForm'
import { MuiSelect } from '../../../../components/form/MuiSelect'
import { addMealToFirestore, deleteMealInFireStore, updateMealToFirestore } from '../../../../api/FirestoreServices'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import firebase from 'firebase/app'
import { useHistory } from 'react-router'
import { closeAlert, openAlert } from '../../../../components/alert/AlertReducer'
import ImageUploadWidget from '../../../../components/Image/ImageUploadWidget'
import MainIngredients from '../repeaters/MainIngredients'
import ExtraIngredients from '../repeaters/ExtraIngredients'
import Category from '../../../../components/category/category'
import { MuiAutoComplete } from '../../../../components/form/MuiAutoComplete'
import { removeEmptyFields } from '../../../../util/util'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  statusComponent: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
  formComponent: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
}))

function MealDetailForm({
  data,
  dietaryTypes,
  allergens,
  plans,
  mealTypes,
  taxonomy,
  dietaryTypesSelected,
  mealTypesSelected,
  allergensSelected,
  plansSelected,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [files, setFiles] = useState([])
  const [image, setImage] = useState(data?.image)
  const [ingredients, setIngredients] = useState(data?.ingredients)
  const [ingredientsError, setIngredientsError] = useState()
  const [extraIngredients, setExtraIngredients] = useState(data?.extra_ingredients)

  const history = useHistory()

  const initialValues = { 
    title: data?.title,
    description: data?.description,
    tips: data?.tips,
    calories: data?.calories,
    status: data?.status || '',
    cooking_time: parseFloat(data?.cooking_time),
    prep_time: parseFloat(data?.prep_time),
    plans: plansSelected || [],
    dietary_types: dietaryTypesSelected || [],
    meal_types: mealTypesSelected || [],
    meal_tags: data?.meal_tags || [],
    allergens: allergensSelected || [],
  }

  const additionalFormData = {
    publish_date: firebase.firestore.Timestamp.now(),
    image: image ? image : undefined,
    ingredients: ingredients,
    extra_ingredients: extraIngredients ? extraIngredients : undefined,
  }

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const methods = useForm({
    resolver: yupResolver(Schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  async function deleteMealFromFirebase() {
    try {
      await deleteMealInFireStore(data?.firebase_id)
      dispatch(closeAlert())
      toast.success('Meal successfully deleted.')
      history.push(`/meals`)
    } catch (error) {
      toast.error("Sorry we're unable to delete this meal, please try again.")
      dispatch(closeAlert())
    }
  }

  const deleteData = {
    title: 'Are you sure?',
    description:
      'Are you sure you want to delete this ingredient? this is a destructive action and once delete this ingredient cannot be recovered.',
    onClick: () => {
      deleteMealFromFirebase()
    },
  }

  async function submit(meal) {
    let plans
    if (meal.plans) {
      plans = meal.plans.reduce((obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }), {})
    }

    let plans_id
    if (meal.plans) {
      plans_id = meal.plans.reduce((obj, cur) => ({ ...obj, [cur.firebase_id]: true }), {})
    }

    //Dietary Requirement e.g Vegan
    let dietary_types
    if (meal.dietary_types) {
      dietary_types = meal.dietary_types.reduce((obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }), {})
    }
    let dietary_types_id
    if (meal.dietary_types) {
      dietary_types_id = meal.dietary_types.reduce((obj, cur) => ({ ...obj, [cur.firebase_id]: true }), {})
    }

    //Meal type e.g Lunch
    let meal_types
    if (meal.meal_types) {
      meal_types = meal.meal_types.reduce((obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }), {})
    }

    let meal_types_id
    if (meal.meal_types) {
      meal_types_id = meal.meal_types.reduce((obj, cur) => ({ ...obj, [cur.firebase_id]: true }), {})
    }

    //Allergen type e.g Gluten
    let allergens
    if (meal.allergens) {
      allergens = meal.allergens.reduce((obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }), {})
    }

    let allergens_id
    if (meal.allergens) {
      allergens_id = meal.allergens.reduce((obj, cur) => ({ ...obj, [cur.firebase_id]: true }), {})
    }

    //console.log(meal_tags)
    let tags
    if (meal.meal_tags) {
      tags = meal.meal_tags.reduce((obj, cur) => ({ ...obj, [cur.label.split(' ').join('_')]: true }), {})
    }

    const formattedData = {
      plans,
      plans_id,
      meal_types,
      meal_types_id,
      dietary_types,
      dietary_types_id,
      allergens,
      allergens_id,
      meal_tags: tags,
    }

    meal.portion_factor = 0.25
    meal.min_calories = 0
    meal.max_calories = 0
    const newIngredients = {}
    if (additionalFormData.ingredients) {
      additionalFormData.ingredients.forEach((ingredient) => {
        // this is what we do to build the ingredient data

        // check if we have the new ingredient structure from firebase else its old import data
        if (ingredient.min_calories && ingredient.max_calories) {
          meal.min_calories += ingredient.min_calories
          meal.max_calories += ingredient.max_calories
        } else {
          // its some old shit thats proper wank so calculate it
          const min_amount = parseInt(ingredient.min_amount) / parseInt(ingredient.base_amount)
          const max_amount = parseInt(ingredient.max_amount) / parseInt(ingredient.base_amount)

          meal.min_calories +=
            Number(ingredient.proteins) * min_amount * 4 +
            Number(ingredient.carbs) * min_amount * 4 +
            Number(ingredient.fats) * min_amount * 9
          meal.max_calories +=
            Number(ingredient.proteins) * max_amount * 4 +
            Number(ingredient.carbs) * max_amount * 4 +
            Number(ingredient.fats) * max_amount * 9
        }
        if (ingredient.unit !== 'ml' && ingredient.unit !== 'gram') meal.portion_factor = 1
        // end ingredient details calculation
        if(ingredient.firebase_id) {
            newIngredients[ingredient.firebase_id] = ingredient
        }
        else {
            newIngredients[ingredient.id] = ingredient
        }
        
      })
    }

    additionalFormData.ingredients = newIngredients

    meal.min_calories = meal.min_calories.toFixed(0)
    meal.max_calories = meal.max_calories.toFixed(0)

    if (meal.max_calories === 'NaN' || undefined) {
      setIngredientsError('Ingredient(S) are missing max value.')
      toast.error("Sorry we're unable to delete this meal, please update ingredients max values and try again.")

      const titleElement = document.getElementById('ingError')
      titleElement.scrollIntoView({ behavior: 'smooth' })
      return
    }

    const merged = { ...data, ...meal, ...additionalFormData, ...formattedData }

    removeEmptyFields(merged)

    if (data) {
      try {
        await updateMealToFirestore(data?.firebase_id, merged)
        toast.success("Congratulations! we've successfully updated your meal.")
      } catch (error) {
        //console.log(error)
        toast.error("Sorry we're unable to save your meal, please try again.")
      }
    } else {
      try {
        await addMealToFirestore(merged).then((docRef) => {
          history.push(`/meals/${docRef.id}`)
        })
        toast.success("Congratulations! you've added a new meal.")
      } catch (error) {
        //console.log(error)
        toast.error("Sorry we're unable to save your meal, please try again.")
      }
    }
  }

  const clearImage = () => {
    setImage(null)
    setFiles([])
  }

  const statusOptions = ['Published', 'Pending', 'Draft']

  const alertData = {
    title: 'Add New Allergen',
    description: 'Add new allergen, your allergen will be available from the allergens menu.',
  }

  const mealTypeData = {
    title: 'Add New Meal Type',
    description: 'Add new meal type, your meal type will be available from the meal type menu.',
  }

  const mealTagData = {
    title: 'Add New Meal Tag',
    description: 'Add new meal tag, your meal tag will be available from the meal tag menu.',
    taxonomy: taxonomy,
  }

  const nutritionalInfo = {
    minCalories: data?.min_calories
      ? 'Min Calories: ' + data?.min_calories
      : data?.calories
      ? 'Min Calories: ' + data?.calories
      : null,
    maxCalories: data?.max_calories ? 'Max Calories: ' + data?.max_calories : null,
  }

  return (
    <>
      <FormProvider {...methods}>
        <MuiForm className={classes.form} onSubmit={methods.handleSubmit(submit)}>
          <Grid flexdirection="row" container justify="space-between" spacing={isSmall ? 0 : 5}>
            <Grid item sm={12} md={8} xl={9} className={classes.formComponent}>
              <StyledGrid title="Nutritional Info">
                <Grid container>
                  <MuiInput
                    ref={methods.register}
                    id="title"
                    label="Meal Title"
                    name="title"
                    required
                    error={!!methods.errors?.title}
                    helperText={methods.errors?.title?.message}
                  />
                  <MuiInput
                    ref={methods.register}
                    name="description"
                    label="Description"
                    id="description"
                    multiline
                    rows={5}
                    error={!!methods.errors?.description}
                    helperText={methods.errors?.description?.message}
                  />
                  <MuiInput
                    ref={methods.register}
                    name="tips"
                    label="Tips"
                    id="tips"
                    multiline
                    rows={5}
                    error={!!methods.errors?.tips}
                    helperText={methods.errors?.tips?.message}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MuiInput
                      ref={methods.register}
                      type="number"
                      name="prep_time"
                      label="Prep Time (Mins)"
                      id="prep_time"
                      placeholder="Enter value in minutes"
                      error={!!methods.errors?.prep_time}
                      helperText={methods.errors?.prep_time?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MuiInput
                      ref={methods.register}
                      type="number"
                      name="cooking_time"
                      label="Cooking Time (Mins)"
                      id="cooking_time"
                      placeholder="Enter value in minutes"
                      error={!!methods.errors?.cooking_time}
                      helperText={methods.errors?.cooking_time?.message}
                    />
                  </Grid>
                </Grid>
              </StyledGrid>
              <div id="ingError" />
              <MainIngredients
                ingredients={ingredients?.length > 0 ? ingredients : []}
                setIngredients={setIngredients}
                nutritionalInfo={nutritionalInfo}
                ingredientsError={ingredientsError}
              />
              <ExtraIngredients
                ingredients={extraIngredients?.length > 0 ? extraIngredients : []}
                setIngredients={setExtraIngredients}
              />
            </Grid>

            <Grid item xs={12} md={4} xl={3} className={classes.statusComponent}>
              <Status
                status={data?.status}
                date={data?.publish_date}
                onDelete={() => dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))}
              >
                <MuiSelect
                  control={methods.control}
                  name="status"
                  error={!!methods.errors.status}
                  options={statusOptions}
                  label="Status"
                />
                {methods.errors?.status && <FormHelperText error>{methods.errors?.status?.message}</FormHelperText>}
              </Status>
              <Category title="Add to plan">
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Plan"
                  name="plans"
                  options={plans}
                  error={!!methods.errors?.plans}
                  helperText={methods.errors?.plans?.message}
                />
              </Category>
              <Category title="Dietary Requirement">
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Dietary Requirements"
                  name="dietary_types"
                  options={dietaryTypes}
                  error={!!methods.errors?.dietary_types}
                  helperText={methods.errors?.dietary_types?.message}
                />
              </Category>
              <Category
                title="Meal Type"
                buttonText="Add New Meal Type"
                onClick={() => dispatch(openAlert({ alertType: 'MealTypeAlert', alertProps: { mealTypeData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Meal Types"
                  name="meal_types"
                  options={mealTypes}
                  error={!!methods.errors?.meal_types}
                  helperText={methods.errors?.meal_types?.message}
                />
              </Category>
              <Category
                title="Meal Tags"
                buttonText="Add New Meal Tag"
                onClick={() => dispatch(openAlert({ alertType: 'MealTagsAlert', alertProps: { mealTagData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Meal Tags"
                  name="meal_tags"
                  options={taxonomy.tags}
                  error={!!methods.errors?.meal_tags}
                  helperText={methods.errors?.meal_tags?.message}
                />
              </Category>
              <Category
                title="Allergens"
                buttonText="Add New Allergen"
                onClick={() => dispatch(openAlert({ alertType: 'AllergenAlert', alertProps: { alertData } }))}
              >
                <MuiAutoComplete
                  multiple={true}
                  control={methods.control}
                  inputLabel="Allergens"
                  name="allergens"
                  options={allergens}
                />
              </Category>
              <ImageUploadWidget
                setFiles={setFiles}
                files={files}
                image={image}
                clearImage={clearImage}
                setImage={setImage}
              />
            </Grid>
          </Grid>
        </MuiForm>
      </FormProvider>
    </>
  )
}

export default MealDetailForm
