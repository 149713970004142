import { Container } from '@material-ui/core'
import React from 'react'
import Navigation from '../../../components/layout/navigation/Navigation'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import PlanForm from '../components/forms/PlanForm'
//import sizeof from 'object-sizeof'

function CreatePlan({ match, history }) {


  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Plans"
        caption="Manage your Plan, you can edit and delete the plan from here, don’t forget to add start and end dates…"
        buttonTitle="Add New Plan"
        onClick={() => history.push('/plans/create-plan')}
      />
      <Container maxWidth="xl">
         <PlanForm />
      </Container>
    </Navigation>
  )
}

export default CreatePlan
