import React from 'react'
import { Controller } from 'react-hook-form'
import { KeyboardDatePicker } from '@material-ui/pickers'


export const MuiDatePicker = ({ control, name, id, disabled, inputLabel, error, helperText }) => {
  return (
    <Controller
      render={({ onChange, value }) => {
        return (
          <KeyboardDatePicker
            autoOk
            id={id}
            inputVariant="outlined"
            label={inputLabel}
            format="dd/MM/yyyy"
            value={value}
            InputAdornmentProps={{ position: 'end' }}
            onChange={onChange}
            style={{ marginTop: '15px', marginBottom: '5px', width: '100%' }}
          />
        )
      }}
      name={name}
      control={control}
    />
  )
}
