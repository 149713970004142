import * as yup from 'yup'

export const Schema = yup.object().shape({
  title: yup.string().min(3).required(),
  base_amount: yup
    .number()
    .typeError('You must specify a number, please use 0 if no value.')
    .test(
      'Is positive?',
      'Amount requires a positive number, please remove any negative values.',
      (value) => value >= 0
    )
    .required(),
  display_unit: yup
    .string()
    .matches(/^([^0-9]*)$/, { message: 'Text only no numbers allowed.', excludeEmptyString: true }),
  unit: yup.string().required(),
  carbs: yup
    .number()
    .typeError('You must specify a number, please use 0 if no value.')
    .test('Is positive?', 'Carbs requires a positive number, please remove any negative values.', (value) => value >= 0)
    .required(),
  fats: yup
    .number()
    .typeError('You must specify a number, please use 0 if no value.')
    .test('Is positive?', 'Fats requires a positive number, please remove any negative values.', (value) => value >= 0)
    .required(),
  proteins: yup
    .number()
    .typeError('You must specify a number, please use 0 if no value.')
    .test(
      'Is positive?',
      'Protein requires a positive number, please remove any negative values.',
      (value) => value >= 0
    )
    .required(),
  status: yup.string().required(),
})
