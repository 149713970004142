import * as yup from 'yup'

export const Schema = yup.object().shape({
  status: yup.string().required('Status is required'),
  title: yup.string().when('status', (value) => {
    if (value === 'Published') {
      return yup.string().required('Video Title is required')
    }
  }),
  //trainer: yup.string().required('Trainer name is required'),
  vimeo_video_id: yup.string().when('status', (value) => {
    if (value === 'Published') {
      return yup.string().required('Vimeo ID is required')
    }
  }),
})
