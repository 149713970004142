import { capitalize, FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
  formControlResults: {
    padding: '25px',
  },
  formControlFilters: {
    width: '250px',
    marginTop: '20px',
    marginBottom: '10px',
  },
  filtersContainer: {
    padding: '25px',
    paddingTop: '10px',
    paddingBottom: '15px',
  },
})

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilterObject({ column: { filterValue, setFilter, preFilteredRows, id, render } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      if (row.original[id] instanceof Array) {
        row.original[id].forEach((value) => {
          options.add(value.label)
        })
      } else {
        if (row.original[id]) options.add(row.original[id])
      }
    })

    const merged = Object.assign({}, ...options)
    return Object.keys(merged)
  }, [id, preFilteredRows])

  const classes = useStyles()

  const capitalizedOptions = options.map((name) => capitalize(name.replace(/[^A-Za-z0-9]/g, ' ')))

  //Render a select box
  return (
    <>
      {capitalizedOptions.length > 0 ? (
        <FormControl variant="outlined" className={classes.formControlFilters}>
          <InputLabel id="unit-select-outlined-label" className={classes.inputLabelFilters}>
            {render('Header')}
          </InputLabel>
          <Select
            labelId={id + '-outlined-label'}
            id={id + '-outlined'}
            value={filterValue || ''}
            label={render('Header')}
            onChange={(e) => setFilter(e.target.value || '')}
          >
            {/* <MenuItem key={id + '-all'} value={undefined}>
              All
            </MenuItem> */}
            {capitalizedOptions.map((option, i) => (
              <MenuItem key={option + i} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </>
  )
}
