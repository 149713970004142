import * as yup from 'yup'

export const Schema = yup
  .object()
  .shape({
    title: yup.string().required('Week title is a required field'),
    week_number: yup.number().typeError('You must specify a number.').test(
        'noNegativeWeekNumber',
        'Week number requires a positive number, please remove any negative values.',
        (value) => value >= 1
      ).required('Week number is a required field'),
    week_start_date: yup.date().required('Start date is a required field'),
    welcome_text: yup.string().required('Week welcome message is a required field'),
    goal: yup.string().required('Week welcome message is a required field'),
    description: yup.string().required('Week description is a required field'),
    breakfast: yup.array().min(1, 'You must select at least 1 breakfast requirement').required(),
    breakfast_percent: yup
      .number()
      .typeError('You must specify a number, please use 0 if no value.')
      .test(
        'noNegativeValue',
        'Breakfast requires a positive number, please remove any negative values.',
        (value) => value >= 0
      )
      .required('Breakfast percentage is a required field'),
    mid_morning_snack: yup.array(),
    morning_snack_percent: yup
      .number()
      .typeError('You must specify a number, please use 0 if no value.')
      .test(
        'noNegativeValue',
        'Mid morning snack requires a positive number, please remove any negative values.',
        (value) => value >= 0
      )
      .required('Mid morning snack percentage is a required field'),
    lunch: yup.array().min(1, 'You must select at least 1 lunch requirement').required(),
    lunch_calorie_percent: yup
      .number()
      .typeError('You must specify a number, please use 0 if no value.')
      .test(
        'noNegativeValue',
        'Lunch requires a positive number, please remove any negative values.',
        (value) => value >= 0
      )
      .required('Lunch percentage is a required field'),
    afternoon_snack: yup.array(),
    afternoon_snack_percent: yup
      .number()
      .typeError('You must specify a number, please use 0 if no value.')
      .test(
        'noNegativeValue',
        'Afternoon snack requires a positive number, please remove any negative values.',
        (value) => value >= 0
      )
      .required('Afternoon snack percentage is a required field'),
    dinner: yup.array().min(1, 'You must select at least 1 dinner requirement').required(),
    dinner_calorie_percent: yup
      .number()
      .typeError('You must specify a number, please use 0 if no value.')
      .test(
        'noNegativeValue',
        'Dinner requires a positive number, please remove any negative values.',
        (value) => value >= 0
      )
      .required('Dinner percentage is a required field'),
    before_bed: yup.array(),
    before_bed_percent: yup
      .number()
      .typeError('You must specify a number, please use 0 if no value.')
      .test(
        'noNegativeValue',
        'Before bed meal requires a positive number, please remove any negative values.',
        (value) => value >= 0
      ),
    week_decrease_percentage: yup.number().typeError('You must specify a number, please use 0 if no value.')
    .test(
      'noNegativeCaloricValue',
      'Week caloric decrease requires a positive number, please remove any negative values.',
      (value) => value >= 0
    ).required('Week decrease percentage is a required field'),
    home_workouts: yup.array().min(1, 'You must select at least 1 home workout requirement').required(),
    gym_workouts: yup.array().min(1, 'You must select at least 1 gym workout requirement').required(),
    weeks_classes: yup.array().min(1, 'You must select at least 1 class requirement').required(),
    nutrition_vimeo_video_id: yup.string().required('Nutrition video is a required field'),
    workout_vimeo_video_id: yup.string().required('Workout video is a required field'),
  })
  .test('checkPercentages', 'Your weeks percentages are less than or greater than 100%', (formValues) => {
    const totalPercentages =
      +formValues.breakfast_percent +
      +formValues.morning_snack_percent +
      +formValues.lunch_calorie_percent +
      +formValues.afternoon_snack_percent +
      +formValues.dinner_calorie_percent +
      +formValues.before_bed_percent

    return totalPercentages === 100 ? true : false
  })
