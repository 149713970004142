import * as yup from 'yup'

export const Schema = yup
  .object()
  .shape({
    title: yup.string().required('Plan title is a required field'),
    subtitle: yup.string(),
    plan_type: yup.string().required('Plan type is a required field'),
    goal: yup.string().when('plan_type', {
      is: (plan_type) => plan_type === 'Workout Plan',
      then: yup.string().required('Goal is a required field'),
    }),
    long: yup.string().when('plan_type', {
      is: (plan_type) => plan_type === 'Workout Plan',
      then: yup.string().required('Full description is a required field'),
    }),
    short: yup.string().when('plan_type', {
      is: (plan_type) => plan_type === 'Workout Plan',
      then: yup.string().required('Short description is a required field'),
    }),
    training_level: yup.string().required('Training level is a required field'),
    gender: yup.array().min(1, 'You must select at least 1 gender requirement').required(),
    vimeo_video_id: yup.string(),
    start_date: yup.date().when('plan_type', {
      is: (plan_type) => plan_type === 'Workout Plan',
      then: yup.date().required('Start date is a required field'),
    }),
    end_date: yup.date().when('plan_type', {
      is: (plan_type) => plan_type === 'Workout Plan',
      then: yup.date().required('End date is a required field'),
    }),
    plan_id: yup.number().typeError('You must specify a numbers only').required(),
    status: yup.string().required('Plan status is a required field'),
    breakfast_percent: yup.number().typeError('You must specify a number, please use 0 if no value.')
    .min(0)
    .max(100)
    .nullable(true),
    mid_morning_snack_percent: yup.number().typeError('You must specify a number, please use 0 if no value.')
    .min(0)
    .max(100)
    .nullable(true),
    lunch_percent: yup.number().typeError('You must specify a number, please use 0 if no value.')
    .min(0)
    .max(100)
    .nullable(true),
    afternoon_snack_percent: yup.number().typeError('You must specify a number, please use 0 if no value.')
    .min(0)
    .max(100)
    .nullable(true),
    dinner_percent: yup.number().typeError('You must specify a number, please use 0 if no value.')
    .min(0)
    .max(100)
    .nullable(true),
    before_bed_percent: yup.number().typeError('You must specify a number, please use 0 if no value.')
    .min(0)
    .max(100)
    .nullable(true)
  })
  
  .test('checkPercentages', 'The meals when added together should have a total value of 100%, please check the values of each meal and try again.', (formValues) => {
    const totalPercentages =
      +formValues.breakfast_percent +
      +formValues.mid_morning_snack_percent +
      +formValues.lunch_percent +
      +formValues.afternoon_snack_percent +
      +formValues.dinner_percent +
      +formValues.before_bed_percent

    return totalPercentages === 100 ? true : formValues.plan_type === 'Workout Plan' ? true : false
  })
