import React, { useEffect, useState } from 'react'
import TitleBar from '../../../components/Titlebar'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import { Container, capitalize, useTheme, Typography } from '@material-ui/core'
import Navigation from '../../../components/layout/navigation/Navigation'
import { useDispatch } from 'react-redux'
import { addMealToFirestore, deleteMealInFireStore } from '../../../api/FirestoreServices'
import { useHistory } from 'react-router'
import DocumentTable from '../../../components/layout/table/DocumentTable'
import { SelectColumnFilter } from '../../../components/layout/table/SelectFilter'
import TableChip from '../../../components/layout/table/TableChip'
import { SelectColumnFilterObject } from '../../../components/layout/table/SelectFilterObject'
//import { SelectColumnFilterArray } from '../../../components/layout/table/SelectFilterArray'
import { CopyButtons, DeleteButton, EditButtons } from '../../../components/layout/table/TableButtons'
import { toast } from 'react-toastify'
import firebase from 'firebase/app'
import { db } from '../../../api/firebase'
import { closeAlert, openAlert } from '../../../components/alert/AlertReducer'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Image from 'material-ui-image'

function Meals() {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  let [values, loading, error] = useCollectionData(db.collection('meals').orderBy('title', 'asc'), {
    idField: 'firebase_id',
  })

//   const [meals, setMeals] = useState(values)


//   useEffect(() => {
//       setMeals(values)
//   }, [values])
//   console.log(meals)

  async function copy(mealToCopy) {
    const copyData = {
      title: mealToCopy.title + ' COPY',
      publish_date: firebase.firestore.Timestamp.now(),
      status: 'Draft',
      firebase_id: '',
    }
    const copyMeal = { ...mealToCopy, ...copyData }
    try {
      await addMealToFirestore(copyMeal).then((docRef) => {
        history.push(`/meals/${docRef.id}`)
      })
      toast.success('Meal successfully copied.')
    } catch (error) {
      toast.error("Sorry we're unable to copy this meal, please try again.")
    }
  }

  const deleteMeal = (mealToDelete) => {
    const deleteData = {
      title: 'Are you sure?',
      description:
        'Are you sure you want to delete this ingredient? this is a destructive action and this ingredient cannot be recovered',
      onClick: () => {
        deleteMealInFireStore(mealToDelete.firebase_id)
        dispatch(closeAlert())
      },
    }
    dispatch(openAlert({ alertType: 'DeleteAlert', alertProps: { deleteData } }))
  }

  const columns = [
    {
      Header: 'Image',
      accessor: (data) => (data.image ? 'Yes' : 'No'),
      id: 'image', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilter,
      Cell: ({ row }) =>
        row.original.image ? (
          <a href={`/meals/${row.original.firebase_id}`}>
            <Image
              src={row.original.image}
              height="65px"
              width="125px"
              alt={row.original.name}
              style={{ objectFit: 'cover', cursor: 'pointer' }}
            />
          </a>
        ) : null,
    },
    {
      Header: 'Actions',
      disableFilters: true,
      Filter: () => null,
      Cell: ({ row }) => (
        <div style={{ minWidth: 160 }}>
          <CopyButtons
            onClick={() => {
              copy(row.original)
            }}
          />
          <EditButtons link={`/meals/${row.original.firebase_id}`} />
          <DeleteButton onClick={() => deleteMeal(row.original)} />
        </div>
      ),
    },
    {
      Header: '#ID',
      accessor: 'firebase_id', // accessor is the "key" in the data,
      //disableGlobalFilter: true,
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Meal Title',
      accessor: 'title', // accessor is the "key" in the data
      disableFilters: true,
      Filter: () => null,
    },
    {
      Header: 'Dietary Requirement',
      id: 'dietary_types', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterObject,
      accessor: (data) => {
        if (data.dietary_types) {
          const dietaryTypes = Object.keys(data.dietary_types)
          const dietaryType = dietaryTypes.map((name) => capitalize(name))
          return dietaryType.join(', ')
        }
      },
    },
    {
      Header: 'Meal Type',
      id: 'meal_types', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterObject,
      accessor: (data) => {
        if (data.meal_types) {
          const mealTypes = Object.keys(data.meal_types)
          const mealType = mealTypes.map((name) => capitalize(name.replace(/[^A-Za-z0-9]/g, ' ')))
          return mealType.join(', ')
        }
      },
    },
    {
      Header: 'Nutrition Type',
      id: 'nutrition_types', // accessor is the "key" in the data
      //disableGlobalFilter: true,
      condition: 'eq',
      Filter: SelectColumnFilterObject,
      accessor: (data) => {
        if (data.nutrition_types) {
          const mealTypes = Object.keys(data.nutrition_types)
          const mealType = mealTypes.map((name) => capitalize(name.replace(/[^A-Za-z0-9]/g, ' ')))
          return mealType.join(', ')
        }
      },
    },
    {
      Header: 'Workout Plan',
      id: 'plans', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterObject,
      accessor: (data) => {
        if (data.plans) {
          const mealTypes = Object.keys(data.plans)
          const mealType = mealTypes.map((name) => capitalize(name.replace(/[^A-Za-z0-9]/g, ' ')))
          return mealType.join(', ')
        }
      },
    },
    {
      Header: 'Meal Tags',
      id: 'meal_tags', // accessor is the "key" in the data
      disableGlobalFilter: true,
      Filter: SelectColumnFilterObject,
      accessor: (data) => {
        if (data.meal_tags) {
          const mealTags = Object.keys(data.meal_tags)
          const mealTag = mealTags.map((name) => capitalize(name.replace(/[^A-Za-z0-9]/g, ' ')))
          return mealTag.join(', ')
        }
      },
    },
    // {
    //   Header: 'Tags',
    //   filter: 'includes',
    //   id: 'tags',
    //   Filter: SelectColumnFilterArray,
    //   accessor: (data) => {
    //     let allergens = []
    //     map(data.allergens, (allergen) => {
    //       allergens.push(allergen.label)
    //     })
    //     return allergens.join(', ')
    //   },
    // },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'equals',
      Cell: ({ row }) => {
        const status = row.values.status
        return status === 'Published' ? (
          <TableChip label="Published" color={theme.palette.published.main} />
        ) : status === 'Draft' ? (
          <TableChip label="Draft" color={theme.palette.draft.main} />
        ) : status === 'Pending' ? (
          <TableChip label="Pending" color={theme.palette.pending.main} />
        ) : null
      },
    },
  ]

  return (
    <Navigation>
      <TitleBar
        icon={<RestaurantIcon fontSize="large" />}
        title="Meals"
        caption="Manage your exercises, you can add, edit  and delete exercises…"
        buttonTitle="Add Meal"
        onClick={() => history.push('/meals/create-meal')}
      />
      <Container maxWidth="xl">
        {error && <Typography variant="h1">No Firebase Connection </Typography>}
        {!error && (
          <DocumentTable data={values} columns={columns} isLoading={loading} placeHolderText="Search for meals" />
        )}
      </Container>
    </Navigation>
  )
}

export default Meals
