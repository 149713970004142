import React from 'react'
import TableOrder from '../../../../components/layout/table/TableOrder'
import { CopyButtons, DeleteButton, EditButtons } from '../../../../components/layout/table/TableButtons'
import StyledGrid from '../../../../components/layout/Grid'
import RepeaterList from '../../../../components/layout/table/ReorderTable'
import { closeAlert, openAlert } from '../../../../components/alert/AlertReducer'
import { useDispatch } from 'react-redux'
import { Typography } from '@material-ui/core'
import { addPlanWeekToFirestore, deletePlanWeekInFireStore } from '../../../../api/FirestoreServices'
import firebase from 'firebase/app'
import { toast } from 'react-toastify'

export default function Weeks({ weeks, setWeeks, firebase_id, wpPlanID }) {
  const dispatch = useDispatch()

  async function copy(weekToCopy) {
    const newWeek = {
      title: 'Week ' + (weeks?.length +1) ,
      publish_date: firebase.firestore.Timestamp.now(),
      status: 'Draft',
      firebase_id: '',
      order: weeks?.length +1,
      week_number: weeks?.length +1
    }
    const copiedWeek = { ...weekToCopy, ...newWeek }
    try {
      await addPlanWeekToFirestore(copiedWeek).then((docRef) => {
        console.log('NewWeek Added')
      })
      toast.success('Plan successfully copied.')
    } catch (error) {
      toast.error("Sorry we're unable to copy this plan, please try again.")
    }
  }

  const columns = [
    {
      Header: 'Order',
      accessor: 'order',
      Cell: ({ row }) => <TableOrder order={row.values.order} />,
    },
    {
      Header: 'Week',
      accessor: 'title',
    },
    {
      Header: 'Goal',
      accessor: 'goal',
    },
    {
      Header: 'Start Date',
      accessor: 'week_start_date',
      Cell: ({ row }) => {
        if (row.values?.week_start_date) {
          const date =
            row?.values?.week_start_date instanceof Date
              ? row?.values?.week_start_date
              : row?.values?.week_start_date.toDate()
          return (
            date.toLocaleString('en-UK', { day: '2-digit' }) +
            '/' +
            date.toLocaleString('en-UK', { month: '2-digit' }) +
            '/' +
            date.getFullYear()
          )
        } else {
          return null
        }
      },
    },

    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <>
        <CopyButtons
            onClick={() => {
              copy(row.original)
            }}
          />
          <EditButtons
            onClick={() => dispatch(openAlert({ alertType: 'PlanWeekAlert', alertProps: { WeekData, row } }))}
          />
          <DeleteButton
            onClick={() =>
              dispatch(
                openAlert({
                  alertType: 'DeleteAlert',
                  alertProps: {
                    deleteData: {
                      title: 'Are you sure?',
                      description:
                        'Are you sure you want to delete this week? This is a destructive action and once delete this plan cannot be recovered.',
                      onClick: () => {
                        deletePlanWeekInFireStore(row.original.id)
                        dispatch(closeAlert())
                      },
                    },
                  },
                })
              )
            }
          />
        </>
      ),
    },
  ]

  const WeekData = {
    weeks: weeks,
    setWeeks: setWeeks,
    firebase_id: firebase_id,
  }

  return (
    <StyledGrid title="Plan Weeks">
      {firebase_id && wpPlanID !== 129167 ? (
        <RepeaterList
          columns={columns}
          records={weeks}
          setRecords={setWeeks}
          buttonClick={() => dispatch(openAlert({ alertType: 'PlanWeekAlert', alertProps: { WeekData } }))}
          buttonTitle="Add Week"
        ></RepeaterList>
      ) : (
        <Typography variant="body2">{wpPlanID === 129167 ? 'No weeks required for lifestyle plan' : 'You need to save the plan before you can add a week.'} </Typography>
      )}
    </StyledGrid>
  )
}
