import React from 'react'
//import { Button } from '@material-ui/core'
import { Container } from '@material-ui/core'
//import { useSelector, useDispatch } from 'react-redux'
import Navigation from '../../components/layout/navigation/Navigation'
import ReorderTable from '../../components/layout/table/ReorderTable'
import { EditButtons, DeleteButton } from '../../components/layout/table/TableButtons'
import TitleBar from '../../components/Titlebar'
import TableOrder from '../../components/layout/table/TableOrder'
import StyledGrid from '../../components/layout/Grid'
//import { increment, decrement } from './sandboxActions'

export default function Sandbox() {
  //const dispatch = useDispatch()
  //const data = useSelector((state) => state.FP_TEST.data)

  const data = [
    { id: 1, order: 1, title: 'Ingredient 1', min: '100g', max: '200g' },
    { id: 2, order: 2, title: 'Ingredient 2', min: '100g', max: '200g' },
    { id: 3, order: 3, title: 'Ingredient 3', min: '100g', max: '200g' },
    { id: 4, order: 4, title: 'Ingredient 4', min: '100g', max: '200g' },
    { id: 5, order: 5, title: 'Ingredient 5', min: '100g', max: '200g' },
    { id: 6, order: 6, title: 'Ingredient 6', min: '100g', max: '200g' },
  ]
  const columns = [
    {
      Header: 'Order',
      accessor: 'order',
      Cell: ({ row }) => <TableOrder order={row.values.order} />,
    },
    {
      Header: 'Ingredient',
      accessor: 'title',
    },
    {
      Header: 'Min',
      accessor: 'min',
    },
    {
      Header: 'Max',
      accessor: 'max',
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <>
          <EditButtons onClick={() => console.log('EDIT CLICKED', row)} />
          <DeleteButton onClick={() => console.log('DELETE CLICKED', row)} />
        </>
      ),
    },
  ]

  return (
    <Navigation>
      <TitleBar title="Sandbox" />
      <Container maxWidth="xl">
        <StyledGrid title="Main Ingredients">
            <ReorderTable
              columns={columns}
              data={data}
              buttonClick={() => console.log('ADD CLICKED')}
              buttonTitle="ADD SOMETHING"
            ></ReorderTable>
        </StyledGrid>
        {/* <h1>Testing 123</h1>
    <h3>The Data is: {data}</h3>
    <Button variant="contained" onClick={() => dispatch(increment(20))}> Increment </Button>
    <Button variant="contained" onClick={() => dispatch(decrement(10))}> Decrement </Button> */}
      </Container>
    </Navigation>
  )
}
