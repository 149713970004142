import { Typography } from '@material-ui/core'
import { Link } from '@material-ui/core'
import React from 'react'

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.ultimateshred.co.uk">
        Ultimate Shred Limited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default Copyright
