import { Container, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import Navigation from '../../../components/layout/navigation/Navigation'
import TitleBar from '../../../components/Titlebar'
import ListIcon from '@material-ui/icons/List'
import IngredientsNutritionalInfoForm from '../components/forms/IngredientsNutritionalInfoForm'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../api/firebase'
import IngredientDetailSkeleton from '../components/SkeletonLoaders/IngredientDetailSkeleton'

function IngredientDetail({ match, history }) {
  const [ingredient, ingredientLoading, ingredientError] = useDocumentData(
    db.collection('ingredients').doc(match.params.id),
    {
      idField: 'firebase_id',
    }
  )
  //Redirect if error
  useEffect(() => {
    if (!ingredientLoading && ingredient === undefined) {
      history.push('/404')
    }
  }, [history, ingredientLoading, ingredient])

  return (
    <Navigation>
      <TitleBar
        icon={<ListIcon fontSize="large" />}
        title="Ingredient"
        caption="Manage your Ingredients, you can add edit delete, don’t forget to add allergen info…"
        buttonTitle="Add New Ingredient"
        onClick={() => history.push('/ingredients/create-ingredient')}
      />
      <Container maxWidth="xl">
        {ingredientError && <Typography variant="h1">No Firebase Connection </Typography>}
        {!ingredientError && ingredientLoading ? (
          <IngredientDetailSkeleton />
        ) : (
          <IngredientsNutritionalInfoForm data={ingredient} />
        )}
      </Container>
    </Navigation>
  )
}

export default IngredientDetail
