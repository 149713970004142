import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { lightTheme } from './app/theme/lightTheme'
//import { darkTheme } from './app/theme/darkTheme'
import { ToastContainer } from 'react-toastify'
import Routes from './app/routes/routes'
import AlertManager from './app/components/alert/AlertManager'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
//import MomentUtils from '@date-io/moment'
import DateFnsUtils from '@date-io/date-fns'
//import LuxonUtils from '@date-io/luxon'

function App() {
  //   const [darkMode, setDarkmode] = useState(false)

  return (
    // <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
    <ThemeProvider theme={lightTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        <AlertManager />
        <ToastContainer position="bottom-center" />
        <Routes />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
